import type { ActionInput } from "../types/workflow";

export const availableActions: Array<{
  kind: string;
  name: string;
  description: string;
  inputs: Record<string, ActionInput>;
  outputs: Record<
    string,
    {
      type: "string" | "array" | "object" | "boolean" | "number";
      description: string;
    }
  >;
  disabled?: boolean;
}> = [
  {
    kind: "send_slack_message",
    name: "Send Message to Slack",
    description: "Generate and send a message on Slack based on your prompt",
    inputs: {
      channel: {
        type: "string" as const,
        label: "Channel",
        description:
          "The channel to send the message to. Leave empty to send to all connected customer Slack channels, or specify a channel name to send only to matching channel in this organization.",
        required: true,
        disabledForCustomer: true,
        disabledPlaceholder: "#{{channel_name}}",
      },
      messagePrompt: {
        type: "text" as const,
        label: "Message Prompt",
        description: "Describe what kind of message you want me to write",
        required: true,
        supportsStepOutputs: true,
      },
      taskListId: {
        type: "select" as const,
        label: "Task List",
        description:
          "Select a task list if you want to reference it in the message prompt",
        required: false,
        dataSource: "task_lists",
        valueField: "id",
        labelField: "name",
      },
      tone: {
        type: "enum" as const,
        label: "Tone",
        description: "Desired tone of the message",
        options: ["professional", "casual", "friendly", "urgent"],
        default: "professional",
        required: false,
      },
    },
    outputs: {
      messageId: {
        type: "string",
        description: "ID of the sent Slack message",
      },
      channelId: {
        type: "string",
        description: "ID of the channel message was sent to",
      },
    },
  },
  {
    kind: "send_slack_direct_message",
    name: "Send Direct Message to Slack",
    description:
      "Generate and send a direct message on Slack based on your prompt",
    inputs: {
      recipientRole: {
        type: "enum" as const,
        label: "Recipient",
        description: "The recipient to send the message to",
        options: ["account_owner"],
        default: "account_owner",
        required: true,
      },
      messagePrompt: {
        type: "text" as const,
        label: "Message Prompt",
        description: "Describe what kind of message you want me to write",
        required: true,
        supportsStepOutputs: true,
      },
      tone: {
        type: "enum" as const,
        label: "Tone",
        description: "Desired tone of the message",
        options: ["professional", "casual", "friendly", "urgent"],
        default: "professional",
        required: false,
      },
    },
    outputs: {
      messageId: {
        type: "string",
        description: "ID of the sent Slack message",
      },
      channelId: {
        type: "string",
        description: "ID of the IM channel message was sent to",
      },
    },
  },
  {
    kind: "browser_automation",
    name: "Browser Automation",
    description:
      "Automatically perform actions on a website using simple instructions",
    inputs: {
      task: {
        type: "text" as const,
        label: "What should I do?",
        description:
          "Describe what you want me to on a browser such as navigate to a website, take some action, or extract data",
        required: true,
        supportsStepOutputs: true,
      },
    },
    outputs: {
      success: {
        type: "boolean",
        description: "Whether the task was completed successfully",
      },
      result: {
        type: "object",
        description: "What happened during the task",
      },
      screenshots: {
        type: "array",
        description:
          "Screenshots taken during the task (if any errors occurred)",
      },
    },
  },
  {
    kind: "send_slack_task_list_reminder",
    name: "Send Slack Task List Reminder",
    description:
      "Send a task list reminder message through Slack to a specified channel",
    inputs: {
      channel: {
        type: "string" as const,
        label: "Channel",
        description:
          "The channel to send the reminder to. Leave empty to send to all connected customer Slack channels, or specify a channel name to send only to matching channel in this organization.",
        required: true,
        disabledForCustomer: true,
        disabledPlaceholder: "#{{channel_name}}",
      },
      taskListId: {
        type: "select" as const,
        label: "Task List",
        description: "The task list to reference in the reminder",
        required: true,
        dataSource: "task_lists",
        valueField: "id",
        labelField: "name",
      },
    },
    outputs: {
      messageId: {
        type: "string",
        description: "ID of the sent Slack message",
      },
      channelId: {
        type: "string",
        description: "ID of the channel message was sent to",
      },
    },
  },
];
