import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Box,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Collapse,
  IconButton,
  alpha,
  useTheme,
  Button,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Pending as PendingIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  History as HistoryIcon,
  MoreHoriz as MoreHorizIcon,
} from "@mui/icons-material";
import { useDataProvider } from "../dataProviders/dataProvider";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
dayjs.extend(timezone);

interface WorkflowRun {
  id: string;
  workflow_id: string;
  status: string;
  started_at: string | null;
  completed_at: string | null;
  result: any | null;
  error: string | null;
  created_at: string;
  updated_at: string;
}

interface WorkflowRunLogsProps {
  organizationId: string;
  workflowId: string;
  limit?: number;
  compact?: boolean;
  displayTimezone?: string;
}

// Define a ref interface
export interface WorkflowRunLogsRef {
  fetchRuns: () => void;
}

export const WorkflowRunLogs = forwardRef<
  WorkflowRunLogsRef,
  WorkflowRunLogsProps
>(
  (
    {
      organizationId,
      workflowId,
      limit = 10,
      compact = false,
      displayTimezone = "UTC",
    },
    ref
  ) => {
    const theme = useTheme();
    const [runs, setRuns] = useState<WorkflowRun[]>([]);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false);
    const [hasMore, setHasMore] = useState(false);
    const [page, setPage] = useState(1);
    const [expandedRun, setExpandedRun] = useState<string | null>(null);
    const dataProvider = useDataProvider();

    const fetchRuns = async (
      pageNum: number,
      append = false,
      isPolling = false
    ) => {
      try {
        // Only show loading state if it's not a polling update
        if (!isPolling) {
          if (append) {
            setLoadingMore(true);
          } else {
            setLoading(true);
          }
        }

        const { data, total = 0 } = await dataProvider.getList(
          `organizations/${organizationId}/workflows/${workflowId}/runs`,
          {
            pagination: { page: pageNum, perPage: limit },
            sort: { field: "created_at", order: "DESC" as const },
            filter: {},
          }
        );

        setRuns((prev) => (append ? [...prev, ...data] : data));
        setHasMore(total > pageNum * limit);
      } catch (error) {
        console.error("Error fetching workflow runs:", error);
      } finally {
        // Only update loading state if it's not a polling update
        if (!isPolling) {
          if (append) {
            setLoadingMore(false);
          } else {
            setLoading(false);
          }
        }
      }
    };

    // Set up polling for run updates
    useEffect(() => {
      // Check if any runs are in progress
      const hasRunningWorkflow = runs.some(
        (run) => run.status.toLowerCase() === "running"
      );

      if (hasRunningWorkflow) {
        const interval = setInterval(() => {
          fetchRuns(1, false, true); // Pass isPolling=true for poll updates
        }, 3000);

        return () => clearInterval(interval);
      }
    }, [runs]);

    // Expose the fetchRuns method via ref
    useImperativeHandle(ref, () => ({
      fetchRuns: () => fetchRuns(1, false, true),
    }));

    // Initial fetch and expose fetch method to parent
    useEffect(() => {
      setPage(1);
      fetchRuns(1, false, false); // Initial fetch is not polling
    }, [organizationId, workflowId]);

    const handleLoadMore = () => {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchRuns(nextPage, true, false);
    };

    const getStatusIcon = (status: string) => {
      switch (status.toLowerCase()) {
        case "completed":
          return <CheckCircleIcon sx={{ color: theme.palette.success.main }} />;
        case "failed":
          return <ErrorIcon sx={{ color: theme.palette.error.main }} />;
        case "running":
          return <CircularProgress size={20} />;
        default:
          return <PendingIcon sx={{ color: theme.palette.warning.main }} />;
      }
    };

    const formatDate = (dateString: string | null) => {
      if (!dateString) return "N/A";
      try {
        return dayjs
          .utc(dateString)
          .tz(displayTimezone)
          .format("MMM D, YYYY [at] hh:mm A (z)");
      } catch (error) {
        console.error(
          `Error formatting date '${dateString}' with timezone '${displayTimezone}':`,
          error
        );
        return "N/A";
      }
    };

    const formatDuration = (start: string | null, end: string | null) => {
      if (!start || !end) return "N/A";
      const duration = new Date(end).getTime() - new Date(start).getTime();
      return `${(duration / 1000).toFixed(2)}s`;
    };

    if (loading) {
      return (
        <Box display="flex" justifyContent="center" p={3}>
          <CircularProgress />
        </Box>
      );
    }

    if (runs.length === 0) {
      return (
        <Box
          sx={{
            py: 3,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 1,
          }}
        >
          <HistoryIcon
            sx={{ color: "text.secondary", fontSize: 40, opacity: 0.5 }}
          />
          <Typography color="text.secondary" align="center">
            No runs found for this workflow
          </Typography>
          <Typography variant="caption" color="text.secondary" align="center">
            Run the workflow to see execution history
          </Typography>
        </Box>
      );
    }

    return (
      <Box>
        <TableContainer
          component={Paper}
          sx={{
            backgroundColor: "transparent",
            ...(!compact && { maxHeight: 600, overflow: "auto" }),
          }}
        >
          <Table size={compact ? "small" : "medium"}>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Status</TableCell>
                <TableCell>Started</TableCell>
                <TableCell>Duration</TableCell>
                <TableCell>Result</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {runs.map((run) => (
                <React.Fragment key={run.id}>
                  <TableRow
                    sx={{
                      "& > *": { borderBottom: "unset" },
                      cursor: "pointer",
                      "&:hover": {
                        backgroundColor: alpha(theme.palette.primary.main, 0.1),
                      },
                    }}
                    onClick={() =>
                      setExpandedRun(expandedRun === run.id ? null : run.id)
                    }
                  >
                    <TableCell>
                      <IconButton size="small">
                        {expandedRun === run.id ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )}
                      </IconButton>
                    </TableCell>
                    <TableCell>
                      <Stack direction="row" spacing={1} alignItems="center">
                        {getStatusIcon(run.status)}
                        <Typography>{run.status}</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>{formatDate(run.started_at)}</TableCell>
                    <TableCell>
                      {formatDuration(run.started_at, run.completed_at)}
                    </TableCell>
                    <TableCell>
                      {run.error ? (
                        <Typography color="error">{run.error}</Typography>
                      ) : (
                        "Success"
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={6}
                    >
                      <Collapse
                        in={expandedRun === run.id}
                        timeout="auto"
                        unmountOnExit
                      >
                        <Box sx={{ margin: 1 }}>
                          <Typography variant="h6" gutterBottom component="div">
                            Run Details
                          </Typography>
                          <pre
                            style={{
                              backgroundColor: alpha(
                                theme.palette.background.paper,
                                0.1
                              ),
                              padding: theme.spacing(2),
                              borderRadius: theme.shape.borderRadius,
                              overflow: "auto",
                              maxWidth: "100%",
                              whiteSpace: "pre-wrap",
                              wordWrap: "break-word",
                            }}
                          >
                            {JSON.stringify(run.result, null, 2)}
                          </pre>
                        </Box>
                      </Collapse>
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {hasMore && (
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
            <Button
              startIcon={
                loadingMore ? <CircularProgress size={20} /> : <MoreHorizIcon />
              }
              onClick={handleLoadMore}
              disabled={loadingMore}
              sx={(theme) => ({
                color: "text.secondary",
                "&:hover": {
                  backgroundColor: alpha(theme.palette.primary.main, 0.1),
                },
              })}
            >
              {loadingMore ? "Loading..." : "Load More"}
            </Button>
          </Box>
        )}
      </Box>
    );
  }
);
