import { v4 as uuidv4 } from "uuid";
import type { WorkflowAction, EditorWorkflow } from "../types/workflow";

export interface Workflow {
  id: string;
  organization_id: string;
  name: string;
  description: string;
  workflow: {
    actions: WorkflowAction[];
  };
  enabled: boolean;
  trigger_event: {
    type: "schedule" | "custom_event";
    schedule?: string;
    timezone?: string;
    event?: { name: string };
  };
  created_at: string;
  updated_at: string;
}

// Mock data
const mockWorkflows: Workflow[] = [
  {
    id: uuidv4(),
    organization_id: "00000000-0000-0000-0000-000000000001",
    name: "New Customer Onboarding",
    description: "Automated onboarding sequence for new customers",
    workflow: {
      actions: [
        {
          id: "welcome_email",
          kind: "send_email",
          name: "Send Welcome Email",
          description: "Send a welcome email to new customers",
          sequence: 0,
          inputs: {},
        },
        {
          id: "schedule_call",
          kind: "schedule_call",
          name: "Schedule Onboarding Call",
          description: "Schedule an onboarding call with the customer",
          sequence: 1,
          inputs: {},
        },
      ],
    },
    enabled: true,
    trigger_event: {
      type: "custom_event",
      event: { name: "customer.created" },
    },
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  },
  {
    id: uuidv4(),
    organization_id: "00000000-0000-0000-0000-000000000002",
    name: "Usage Check-in",
    description: "Monitor customer usage and engage if activity drops",
    workflow: {
      actions: [
        {
          id: "analyze_usage",
          kind: "analyze_usage",
          name: "Analyze Usage Patterns",
          description: "Analyze customer usage patterns",
          sequence: 2,
          inputs: {},
        },
        {
          id: "slack_alert",
          kind: "slack_notification",
          name: "Send Slack Alert",
          description: "Send alert to Slack channel",
          sequence: 3,
          inputs: {},
        },
      ],
    },
    enabled: true,
    trigger_event: {
      type: "custom_event",
      event: { name: "usage.weekly.computed" },
    },
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  },
  {
    id: uuidv4(),
    organization_id: "00000000-0000-0000-0000-000000000001",
    name: "Monthly Customer Health Report",
    description: "Generate and send monthly health reports to customers",
    workflow: {
      actions: [
        {
          id: "generate_report",
          kind: "analyze_usage",
          name: "Generate Health Report",
          description:
            "Analyze monthly usage patterns and generate health report",
          sequence: 0,
          inputs: {},
        },
        {
          id: "send_report",
          kind: "send_email",
          name: "Send Health Report",
          description: "Email the health report to the customer",
          sequence: 1,
          inputs: {},
        },
      ],
    },
    enabled: true,
    trigger_event: {
      type: "schedule",
      schedule: "0 9 1 * *",
      timezone: "UTC",
    },
    created_at: new Date().toISOString(),
    updated_at: new Date().toISOString(),
  },
];

// Mock API functions
export const mockWorkflowApi = {
  listWorkflows: async (orgId: string): Promise<Workflow[]> => {
    // Simulate API delay
    await new Promise((resolve) => setTimeout(resolve, 500));
    return mockWorkflows.filter((w) => w.organization_id === orgId);
  },

  createWorkflow: async (
    orgId: string,
    workflow: Omit<Workflow, "id" | "created_at" | "updated_at">
  ): Promise<Workflow> => {
    await new Promise((resolve) => setTimeout(resolve, 500));
    if (workflow.organization_id !== orgId) {
      throw new Error("Organization ID mismatch");
    }
    const newWorkflow: Workflow = {
      ...workflow,
      id: uuidv4(),
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
    };
    mockWorkflows.push(newWorkflow);
    return newWorkflow;
  },

  updateWorkflow: async (
    orgId: string,
    workflowId: string,
    workflow: Omit<Workflow, "id" | "created_at" | "updated_at">
  ): Promise<Workflow> => {
    await new Promise((resolve) => setTimeout(resolve, 500));
    const index = mockWorkflows.findIndex(
      (w) => w.id === workflowId && w.organization_id === orgId
    );
    if (index === -1) {
      throw new Error("Workflow not found");
    }
    const updatedWorkflow: Workflow = {
      ...workflow,
      id: workflowId,
      created_at: mockWorkflows[index].created_at,
      updated_at: new Date().toISOString(),
    };
    mockWorkflows[index] = updatedWorkflow;
    return updatedWorkflow;
  },

  toggleWorkflow: async (
    orgId: string,
    workflowId: string
  ): Promise<{ enabled: boolean }> => {
    await new Promise((resolve) => setTimeout(resolve, 300));
    const workflow = mockWorkflows.find(
      (w) => w.id === workflowId && w.organization_id === orgId
    );
    if (!workflow) {
      throw new Error("Workflow not found");
    }
    workflow.enabled = !workflow.enabled;
    return { enabled: workflow.enabled };
  },

  runWorkflow: async (
    orgId: string,
    workflowId: string
  ): Promise<{ status: string }> => {
    await new Promise((resolve) => setTimeout(resolve, 300));
    const workflow = mockWorkflows.find(
      (w) => w.id === workflowId && w.organization_id === orgId
    );
    if (!workflow) {
      throw new Error("Workflow not found");
    }
    return { status: "triggered" };
  },

  deleteWorkflow: async (orgId: string, workflowId: string): Promise<void> => {
    await new Promise((resolve) => setTimeout(resolve, 300));
    const index = mockWorkflows.findIndex(
      (w) => w.id === workflowId && w.organization_id === orgId
    );
    if (index === -1) {
      throw new Error("Workflow not found");
    }
    mockWorkflows.splice(index, 1);
  },
};

// Template workflow definitions
export const workflowTemplates = [
  {
    id: "monthly-checkin",
    name: "Monthly Account Check-in",
    description: "Automated monthly check-in process with customer accounts",
    prompt:
      "Create a workflow that runs monthly to check in with customer accounts, analyze their usage, and schedule follow-up calls if needed.",
    icon: "calendar_month",
  },
  {
    id: "weekly-onboarding",
    name: "Weekly Onboarding Reminder",
    description: "Send weekly reminders to new customers in onboarding",
    prompt:
      "Create a workflow that sends weekly reminders to customers who are still in onboarding, with progress updates and next steps.",
    icon: "schedule",
  },
];

// Mock workflow generation based on templates
export const generateWorkflow = async (
  templateId: string
): Promise<EditorWorkflow> => {
  const template = workflowTemplates.find((t) => t.id === templateId);
  if (!template) {
    throw new Error("Template not found");
  }

  switch (templateId) {
    case "monthly-checkin":
      return {
        name: "Monthly Account Check-in",
        description: "Automated monthly check-in with customer accounts",
        trigger_event: {
          type: "schedule",
          schedule: "0 9 1 * *", // 9 AM on the 1st of every month
          timezone: "America/Los_Angeles",
        },
        actions: [
          {
            id: "notify-team",
            kind: "send_slack_message",
            name: "Notify Account Team",
            description: "Send report to account management team",
            sequence: 0,
            inputs: {
              channel: undefined, // will use the default organization channel
              messagePrompt:
                "Check in with the team on their usage, engagement, and any issues they're facing.",
              tone: "professional",
            },
          },
        ],
      };

    case "weekly-onboarding":
      return {
        name: "Weekly Onboarding Reminder",
        description: "Send weekly reminders to new customers in onboarding",
        trigger_event: {
          type: "schedule",
          schedule: "0 10 * * 1", // 10 AM every Monday
          timezone: "America/Los_Angeles",
        },
        actions: [
          {
            id: "notify-cs",
            kind: "send_slack_message",
            name: "Update CS Team",
            description: "Notify CS team about sent reminders",
            sequence: 0,
            inputs: {
              channel: undefined, // will use the default organization channel
              messagePrompt:
                "Friendly reminder to complete their onboarding tasks.",
              tone: "friendly",
            },
          },
        ],
      };

    default:
      throw new Error("Invalid template ID");
  }
};
