import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Typography,
  Container,
  Paper,
  Stack,
  Snackbar,
  Alert,
} from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import { LoadingScreen } from "../components/LoadingScreen";
import { useUser } from "../hooks/useUser";
import { keyframes } from "@mui/system";

export const LoginPage = () => {
  const {
    isAuthenticated,
    isLoading: auth0Loading,
    loginWithRedirect,
  } = useAuth0();
  const navigate = useNavigate();
  const { userId, isAdmin, associations, error, isSyncing } = useUser();
  const theme = useTheme();
  const location = useLocation();
  const [alertMessage, setAlertMessage] = useState<string | null>(null);
  const pulse = keyframes`
    0% { box-shadow: 0 0 40px ${alpha(theme.palette.primary.main, 0.5)}, inset 0 0 20px ${alpha(theme.palette.primary.main, 0.3)}; }
    50% { box-shadow: 0 0 50px ${alpha(theme.palette.primary.main, 0.7)}, inset 0 0 30px ${alpha(theme.palette.primary.main, 0.5)}; }
    100% { box-shadow: 0 0 40px ${alpha(theme.palette.primary.main, 0.5)}, inset 0 0 20px ${alpha(theme.palette.primary.main, 0.3)}; }
  `;

  // Add this useEffect to handle URL parameters
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const alert = params.get("alert");
    if (alert) {
      setAlertMessage(decodeURIComponent(alert));
      // Clean up the URL
      navigate(location.pathname, { replace: true });
    }
  }, [location, navigate]);

  // After login, redirect to appropriate dashboard based on role
  useEffect(() => {
    if (!isAuthenticated || !userId) return;

    try {
      if (isAdmin) {
        navigate("/admin");
        return;
      }

      const isManager = associations.some((assoc) => assoc.role === "manager");

      const isAccountOwner = associations.some(
        (assoc) => assoc.role === "account_owner"
      );

      if (isManager || isAccountOwner) {
        navigate("/manager");
        return;
      }

      navigate("/unauthorized");
    } catch (err) {
      console.error("Failed to process user data:", err);
    }
  }, [isAuthenticated, userId, isAdmin, associations, navigate]);

  if (auth0Loading || (isAuthenticated && isSyncing)) {
    return <LoadingScreen />;
  }

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        background: theme.palette.background.default,
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* Background gradient effect */}
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          background: `radial-gradient(circle at center, ${alpha(
            theme.palette.primary.main,
            0.15
          )} 0%, transparent 70%)`,
          filter: "blur(60px)",
        }}
      />

      <Container maxWidth="sm">
        <Paper
          elevation={0}
          sx={{
            p: 6,
            textAlign: "center",
            backgroundColor: alpha(theme.palette.background.paper, 0.8),
            backdropFilter: "blur(10px)",
            border: `1px solid ${alpha("#fff", 0.05)}`,
            borderRadius: 4,
            position: "relative",
          }}
        >
          <Stack spacing={4} alignItems="center">
            {/* Cora AI Logo */}
            <Stack spacing={2} alignItems="center">
              <Box
                sx={{
                  width: 48,
                  height: 48,
                  borderRadius: "50%",
                  background: `radial-gradient(circle at center, ${alpha(
                    theme.palette.primary.main,
                    0.9
                  )} 0%, ${alpha(
                    theme.palette.primary.main,
                    0.3
                  )} 60%, transparent 70%)`,
                  boxShadow: `0 0 40px ${alpha(
                    theme.palette.primary.main,
                    0.5
                  )},
                             inset 0 0 20px ${alpha(
                               theme.palette.primary.main,
                               0.3
                             )}`,
                  animation: `${pulse} 2s infinite`,
                }}
              />
              <Typography variant="h3" fontWeight="bold">
                <Box component="span" sx={{ color: "#fff" }}>
                  Cora
                </Box>{" "}
                <Box
                  component="span"
                  sx={{ color: theme.palette.primary.main }}
                >
                  AI
                </Box>
              </Typography>
            </Stack>

            <Typography
              variant="h6"
              color="text.secondary"
              sx={{ maxWidth: 400 }}
            >
              Your always-on AI customer success partner
            </Typography>

            <Button
              variant="contained"
              size="large"
              onClick={() =>
                loginWithRedirect({
                  appState: { returnTo: location.state?.returnTo },
                })
              }
              sx={{
                px: 6,
                py: 1.5,
                borderRadius: 2,
                fontSize: "1.1rem",
                textTransform: "none",
                background: `linear-gradient(45deg, ${
                  theme.palette.primary.main
                }, ${alpha(theme.palette.primary.light, 0.8)})`,
                boxShadow: `0 4px 20px ${alpha(
                  theme.palette.primary.main,
                  0.4
                )}`,
                "&:hover": {
                  background: `linear-gradient(45deg, ${theme.palette.primary.dark}, ${theme.palette.primary.main})`,
                },
              }}
            >
              Sign In to Get Started
            </Button>
          </Stack>
        </Paper>
      </Container>

      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => {}}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => {}}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {error}
        </Alert>
      </Snackbar>

      {/* Add new Snackbar for alert messages */}
      <Snackbar
        open={!!alertMessage}
        autoHideDuration={6000}
        onClose={() => setAlertMessage(null)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setAlertMessage(null)}
          severity="warning"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default LoginPage;
