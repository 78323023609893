import { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Paper,
  Typography,
  Tabs,
  Tab,
  Stack,
  IconButton,
  Alert,
  Snackbar,
  Button,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  TextField,
} from "@mui/material";
import {
  ArrowBack as ArrowBackIcon,
  Info as InfoIcon,
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  CalendarToday as CalendarTodayIcon,
} from "@mui/icons-material";
import { alpha, useTheme } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { KnowledgeSourceList } from "../components/KnowledgeSourceList";
import { AddKnowledgeSource } from "../components/AddKnowledgeSource";
import { useUser } from "../hooks/useUser";
import { WorkflowList } from "../components/WorkflowList";
import type { Workflow } from "../types/workflow";
import { CreateWorkflow } from "../components/CreateWorkflow";
import { useDataProvider } from "../dataProviders/dataProvider";
import { useWorkflowActions } from "../dataProviders/workflowApi";
import { getButtonStyles, getGlassStyles } from "../theme/shared";
import { EmptyState, ActionButton } from "../components/shared";

// Define the same task status type as in TaskListEditor
const TASK_STATUSES = ["not-started", "in-progress", "completed"] as const;
type TaskStatus = (typeof TASK_STATUSES)[number];

interface Organization {
  id: string;
  name: string;
  platform: string;
  email_domain?: string;
  created_at: string;
  updated_at: string;
  last_activity: string | null;
  total_users: number;
  total_conversations: number;
  custom_prompt?: string;
  customer_of_id?: string;
  slack_app_id?: string;
  slack_channel_id?: string;
}

interface UserAssociation {
  organization_id: string;
  organization_name: string;
  role: string;
}

interface NotificationState {
  message: string;
  severity: "success" | "error";
  open: boolean;
}

interface TabPanelProps {
  children: React.ReactNode;
  value: number;
  index: number;
}

interface TaskList {
  id?: string;
  organization_id: string;
  name: string;
  launch_details?: string;
  target_launch_date?: string;
  timezone?: string;
}

interface Task {
  id: string;
  task_list_id: string;
  name: string;
  description?: string;
  status: TaskStatus;
  depends_on?: string;
  due_date?: string;
  timezone?: string;
  completed_at?: string;
}

const TabPanel = ({ children, value, index }: TabPanelProps) => (
  <Box
    role="tabpanel"
    hidden={value !== index}
    sx={{
      transition: "opacity 0.3s ease-in-out",
      opacity: value === index ? 1 : 0,
    }}
  >
    {value === index && children}
  </Box>
);

const LoadingState = () => {
  const theme = useTheme();
  return (
    <Box data-testid="loading-state">
      {/* Header Skeleton */}
      <Stack direction="row" spacing={2} alignItems="center" mb={4}>
        <Box
          sx={{
            width: 40,
            height: 40,
            borderRadius: "50%",
            bgcolor: "grey.300",
          }}
        />
        <Box
          sx={{ width: 200, height: 32, borderRadius: 1, bgcolor: "grey.300" }}
        />
      </Stack>

      {/* Content Skeleton */}
      <Paper
        sx={{
          backgroundColor: alpha(theme.palette.background.paper, 0.8),
          backdropFilter: "blur(10px)",
          border: `1px solid ${alpha("#fff", 0.05)}`,
          borderRadius: 3,
        }}
      >
        <Box sx={{ borderBottom: 1, borderColor: "divider", p: 2 }}>
          <Stack direction="row" spacing={3}>
            <Box
              sx={{
                width: 140,
                height: 32,
                borderRadius: 1,
                bgcolor: "grey.300",
              }}
            />
            <Box
              sx={{
                width: 140,
                height: 32,
                borderRadius: 1,
                bgcolor: "grey.300",
              }}
            />
          </Stack>
        </Box>
        <Box sx={{ p: 3 }}>
          <Stack spacing={3}>
            {[1, 2, 3].map((i) => (
              <Box
                key={i}
                data-testid="workflow-loading"
                sx={{
                  p: 2,
                  borderRadius: 2,
                  bgcolor: alpha(theme.palette.background.paper, 0.5),
                  border: `1px solid ${alpha("#fff", 0.05)}`,
                }}
              >
                <Stack spacing={1}>
                  <Box
                    sx={{
                      width: "40%",
                      height: 24,
                      borderRadius: 1,
                      bgcolor: "grey.300",
                    }}
                  />
                  <Box
                    sx={{
                      width: "70%",
                      height: 16,
                      borderRadius: 1,
                      bgcolor: "grey.300",
                    }}
                  />
                </Stack>
              </Box>
            ))}
          </Stack>
        </Box>
      </Paper>
    </Box>
  );
};

export const AIConfigurationTab = ({
  organization,
  onUpdate,
}: {
  organization: Organization;
  onUpdate: () => void;
}) => {
  const theme = useTheme();
  const dataProvider = useDataProvider();
  const [isEditing, setIsEditing] = useState(false);
  const [prompt, setPrompt] = useState(organization.custom_prompt || "");
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [notification, setNotification] = useState<NotificationState>({
    message: "",
    severity: "success",
    open: false,
  });

  const isTenantOrg = !organization.customer_of_id;

  const handleSave = async () => {
    if (!organization.id) return;
    setIsSaving(true);
    setError(null);

    try {
      const updateData = {
        name: organization.name,
        custom_prompt: prompt,
        email_domain: organization.email_domain,
        customer_of_id: organization.customer_of_id,
        slack_app_id: organization.slack_app_id,
        slack_channel_id: organization.slack_channel_id,
      };

      await dataProvider.update(`organizations/${organization.id}`, {
        id: organization.id,
        data: updateData,
        previousData: organization,
      });
      setIsEditing(false);
      onUpdate();
      setNotification({
        message: "Custom prompt saved successfully",
        severity: "success",
        open: true,
      });
    } catch (err) {
      console.error("Failed to update custom prompt:", err);
      setError("Failed to save changes. Please try again.");
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Stack spacing={3}>
      <Paper
        elevation={0}
        sx={{
          px: 2.5,
          py: 2,
          backgroundColor: alpha(theme.palette.primary.main, 0.03),
          borderRadius: 2,
          border: `1px solid ${alpha(theme.palette.primary.main, 0.08)}`,
          display: "flex",
          gap: 2,
          alignItems: "flex-start",
        }}
      >
        <InfoIcon
          sx={{
            color: theme.palette.primary.main,
            opacity: 0.7,
            fontSize: 20,
            mt: 0.3,
          }}
        />
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ opacity: 0.85, lineHeight: 1.5 }}
        >
          Optionally customize how Cora behaves when interacting with this
          organization. The default behavior works well for most cases - only
          add a custom prompt if you need specific adjustments to the AI's
          responses.
          {isTenantOrg &&
            " If set, this prompt will apply to all customer organizations. To add customer-specific customization, edit the prompts in individual customer organizations."}
        </Typography>
      </Paper>

      <Box>
        <Typography variant="h6" gutterBottom>
          Custom AI Prompt
        </Typography>

        <TextField
          multiline
          rows={8}
          fullWidth
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          disabled={!isEditing || isSaving}
          sx={{
            "& .MuiInputBase-input": {
              fontFamily: "monospace",
              fontSize: "0.9rem",
            },
          }}
        />

        {error && (
          <Alert severity="error" sx={{ mt: 2 }}>
            {error}
          </Alert>
        )}

        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          sx={{ mt: 2 }}
        >
          {isEditing ? (
            <>
              <Button
                onClick={() => {
                  setIsEditing(false);
                  setPrompt(organization.custom_prompt || "");
                  setError(null);
                }}
                disabled={isSaving}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSave}
                disabled={isSaving}
                sx={getButtonStyles(theme)}
              >
                {isSaving ? "Saving..." : "Save Changes"}
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              startIcon={<EditIcon />}
              onClick={() => setIsEditing(true)}
              sx={getButtonStyles(theme)}
            >
              Edit Prompt
            </Button>
          )}
        </Stack>
      </Box>

      <Snackbar
        open={notification.open}
        autoHideDuration={4000}
        onClose={() => {
          setNotification((prev) => ({ ...prev, open: false }));
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{
          "& .MuiAlert-root": {
            width: "100%",
            maxWidth: 400,
            boxShadow: `0 8px 24px ${alpha("#000", 0.15)}`,
          },
        }}
      >
        <Alert
          onClose={() => {
            setNotification((prev) => ({ ...prev, open: false }));
          }}
          severity={notification.severity}
          variant="filled"
          sx={{
            width: "100%",
            alignItems: "center",
            "& .MuiAlert-icon": {
              fontSize: 24,
            },
          }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export const OrganizationDetails = () => {
  const navigate = useNavigate();
  const { orgId } = useParams<{ orgId: string }>();
  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const { associations: userAssociations } = useUser();
  const [organization, setOrganization] = useState<Organization | null>(null);
  const [tabValue, setTabValue] = useState(() => {
    const params = new URLSearchParams(window.location.search);
    return parseInt(params.get("tab") || "0");
  });
  const [isLoading, setIsLoading] = useState(true);
  const [refreshKey, setRefreshKey] = useState(0);
  const [workflows, setWorkflows] = useState<Workflow[]>([]);
  const [isCreateWorkflowOpen, setIsCreateWorkflowOpen] = useState(false);
  const [notification, setNotification] = useState<NotificationState>({
    message: "",
    severity: "success",
    open: false,
  });
  const dataProvider = useDataProvider();
  const {} = useWorkflowActions();
  const [taskLists, setTaskLists] = useState<TaskList[]>([]);
  const [tasksByListId, setTasksByListId] = useState<Record<string, Task[]>>(
    {}
  );
  const [taskListToDelete, setTaskListToDelete] = useState<string | null>(null);

  const isUserOrg = userAssociations.some(
    (association: UserAssociation) => association.organization_id === orgId
  );

  const loadWorkflows = useCallback(async () => {
    if (!orgId) return;
    try {
      const { data } = await dataProvider.getList(
        `organizations/${orgId}/workflows`,
        {
          pagination: { page: 1, perPage: 100 },
          sort: { field: "created_at", order: "DESC" as const },
          filter: {},
        }
      );
      setWorkflows(data);
    } catch (err) {
      console.error("Failed to load workflows:", err);
      setNotification({
        message: "Failed to load workflows",
        severity: "error",
        open: true,
      });
    }
  }, [orgId, dataProvider]);

  useEffect(() => {
    loadWorkflows();
  }, [loadWorkflows]);

  const handleToggleWorkflow = async (workflowId: string) => {
    if (!orgId) return;
    try {
      await dataProvider.update(
        `organizations/${orgId}/workflows/${workflowId}/toggle`,
        {
          id: workflowId,
          data: {},
          previousData: workflows.find((w) => w.id === workflowId) || {},
        }
      );
      loadWorkflows();
    } catch (err) {
      console.error("Failed to toggle workflow:", err);
    }
  };

  const handleRunWorkflow = async (workflowId: string) => {
    if (!orgId) return;
    try {
      // Trigger the workflow run
      await dataProvider.create(
        `organizations/${orgId}/workflows/${workflowId}/run`,
        {
          data: {},
        }
      );

      // Show success notification
      setNotification({
        message: "Workflow started successfully",
        severity: "success",
        open: true,
      });

      // Immediately load workflows to get initial state
      await loadWorkflows();
    } catch (err) {
      console.error("Failed to run workflow:", err);
      setNotification({
        message: "Failed to run workflow",
        severity: "error",
        open: true,
      });
    }
  };

  const handleDeleteWorkflow = async (workflowId: string) => {
    if (!orgId) return;
    try {
      await dataProvider.delete(
        `organizations/${orgId}/workflows/${workflowId}`,
        {
          id: workflowId,
        }
      );
      loadWorkflows();
    } catch (err) {
      console.error("Failed to delete workflow:", err);
    }
  };

  const handleCreateWorkflow = () => {
    loadWorkflows();
  };

  const handleEditWorkflow = (workflowId: string) => {
    navigate(`/manager/organizations/${orgId}/workflows/${workflowId}/edit`);
  };

  const fetchOrganization = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/api/organizations/${orgId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) throw new Error("Failed to fetch organization");
      const data = await response.json();
      setOrganization(data);
    } catch (error) {
      console.error("Error fetching organization:", error);
    } finally {
      setIsLoading(false);
    }
  }, [orgId, getAccessTokenSilently]);

  useEffect(() => {
    if (orgId) {
      fetchOrganization();
    }
  }, [orgId, fetchOrganization]);

  // Load task lists and their tasks
  const loadTaskLists = useCallback(async () => {
    if (!orgId) return;
    try {
      // Load task lists
      const { data } = await dataProvider.getList(
        `organizations/${orgId}/task-lists`,
        {
          pagination: { page: 1, perPage: 100 },
          sort: { field: "created_at", order: "DESC" as const },
          filter: {},
        }
      );
      console.log("Loaded task lists:", data);
      setTaskLists(data);

      // Load tasks for each task list
      const tasksMap: Record<string, Task[]> = {};
      await Promise.all(
        data.map(async (taskList: TaskList) => {
          if (taskList.id) {
            try {
              const { data: tasksData } = await dataProvider.getList(
                `organizations/${orgId}/task-lists/${taskList.id}/tasks`,
                {
                  pagination: { page: 1, perPage: 100 },
                  sort: { field: "created_at", order: "ASC" as const },
                  filter: {},
                }
              );
              tasksMap[taskList.id] = tasksData;
            } catch (err) {
              console.error(
                `Failed to load tasks for task list ${taskList.id}:`,
                err
              );
              tasksMap[taskList.id] = [];
            }
          }
        })
      );

      setTasksByListId(tasksMap);
    } catch (err) {
      console.error("Failed to load task lists:", err);
      setNotification({
        message: "Failed to load task lists",
        severity: "error",
        open: true,
      });
    }
  }, [orgId, dataProvider]);

  useEffect(() => {
    if (tabValue === 2) {
      loadTaskLists();
    }
  }, [tabValue, loadTaskLists]);

  if (isLoading) {
    return <LoadingState />;
  }

  if (!organization) {
    return (
      <Box
        sx={{
          py: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
        }}
      >
        <InfoIcon
          sx={{ fontSize: 48, color: "text.secondary", opacity: 0.5 }}
        />
        <Typography variant="h5" color="text.secondary">
          Organization Not Found
        </Typography>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => {
            navigate("/manager");
          }}
        >
          Back to Dashboard
        </Button>
      </Box>
    );
  }

  return (
    <Box>
      {/* Header */}
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        mb={4}
        sx={{
          transition: "opacity 0.2s ease-in-out",
          "&:hover button": { opacity: 1 },
        }}
      >
        <IconButton
          onClick={() => {
            navigate("/manager");
          }}
          color="inherit"
          sx={{
            opacity: 0.7,
            transition: "opacity 0.2s ease-in-out",
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Stack>
          <Typography variant="h4" fontWeight="bold">
            {organization.name}
          </Typography>
        </Stack>
      </Stack>

      {/* Main Content */}
      <Paper
        sx={{
          backgroundColor: alpha(theme.palette.background.paper, 0.8),
          backdropFilter: "blur(10px)",
          border: `1px solid ${alpha("#fff", 0.05)}`,
          borderRadius: 3,
          overflow: "hidden",
        }}
      >
        <Tabs
          value={tabValue}
          onChange={(_: React.SyntheticEvent, newValue: number) => {
            setTabValue(newValue);
            navigate(`/manager/organizations/${orgId}?tab=${newValue}`, {
              replace: true,
            });
          }}
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            "& .MuiTabs-indicator": {
              transition: "all 0.3s ease-in-out",
            },
          }}
        >
          <Tab
            label="Knowledge Sources"
            sx={{
              transition: "all 0.2s ease-in-out",
              "&.Mui-selected": {
                fontWeight: "bold",
              },
            }}
          />
          <Tab
            label="Workflows"
            sx={{
              transition: "all 0.2s ease-in-out",
              "&.Mui-selected": {
                fontWeight: "bold",
              },
            }}
          />
          <Tab
            label="Task Lists"
            sx={{
              transition: "all 0.2s ease-in-out",
              "&.Mui-selected": {
                fontWeight: "bold",
              },
            }}
          />
          <Tab
            label="Configuration"
            sx={{
              transition: "all 0.2s ease-in-out",
              "&.Mui-selected": {
                fontWeight: "bold",
              },
            }}
          />
        </Tabs>

        <Box sx={{ p: 3 }}>
          <TabPanel value={tabValue} index={0}>
            <Stack spacing={2}>
              {!organization.customer_of_id && (
                <Paper
                  elevation={0}
                  sx={{
                    px: 2.5,
                    py: 2,
                    backgroundColor: alpha(theme.palette.primary.main, 0.03),
                    borderRadius: 2,
                    border: `1px solid ${alpha(
                      theme.palette.primary.main,
                      0.08
                    )}`,
                    display: "flex",
                    gap: 2,
                    alignItems: "flex-start",
                  }}
                >
                  <InfoIcon
                    sx={{
                      color: theme.palette.primary.main,
                      opacity: 0.7,
                      fontSize: 20,
                      mt: 0.3,
                    }}
                  />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ opacity: 0.85, lineHeight: 1.5 }}
                  >
                    Knowledge sources added here will be available to all
                    customer organizations. For customer-specific documentation,
                    add knowledge sources directly in the customer organizations
                    and they will only be used for that specific organization.
                  </Typography>
                </Paper>
              )}
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 2 }}
              >
                <Typography variant="h6" fontWeight="bold">
                  Knowledge Sources
                </Typography>
                {orgId && (
                  <AddKnowledgeSource
                    orgId={orgId}
                    onSourceAdded={() => {
                      setRefreshKey((prev: number) => prev + 1);
                    }}
                  />
                )}
              </Stack>
              {orgId && (
                <KnowledgeSourceList
                  key={refreshKey}
                  orgId={orgId}
                  onCreateSource={() => {}}
                />
              )}
            </Stack>
          </TabPanel>

          <TabPanel value={tabValue} index={1}>
            <Stack spacing={3}>
              {isUserOrg && (
                <Paper
                  elevation={0}
                  sx={{
                    px: 2.5,
                    py: 2,
                    backgroundColor: alpha(theme.palette.primary.main, 0.03),
                    borderRadius: 2,
                    border: `1px solid ${alpha(theme.palette.primary.main, 0.08)}`,
                    display: "flex",
                    gap: 2,
                    alignItems: "flex-start",
                  }}
                >
                  <InfoIcon
                    sx={{
                      color: theme.palette.primary.main,
                      opacity: 0.7,
                      fontSize: 20,
                      mt: 0.3,
                    }}
                  />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ opacity: 0.85, lineHeight: 1.5 }}
                  >
                    Workflows added here will be applied to all customer
                    organizations.
                  </Typography>
                </Paper>
              )}
              <WorkflowList
                workflows={workflows}
                onToggleWorkflow={handleToggleWorkflow}
                onRunWorkflow={handleRunWorkflow}
                onCreateWorkflow={handleCreateWorkflow}
                onEditWorkflow={handleEditWorkflow}
                onDeleteWorkflow={handleDeleteWorkflow}
                orgId={orgId || ""}
              />
            </Stack>
          </TabPanel>

          <TabPanel value={tabValue} index={2}>
            <Stack spacing={3}>
              <Stack
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  startIcon={<AddIcon />}
                  onClick={() =>
                    navigate(
                      `/manager/organizations/${orgId}/task-lists/create`
                    )
                  }
                  sx={{
                    ...getButtonStyles(theme),
                    borderRadius: 8,
                    px: 2.5,
                    py: 1,
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
                  }}
                >
                  Create Task List
                </Button>
              </Stack>

              <Stack spacing={2}>
                {taskLists.length === 0 ? (
                  <EmptyState
                    icon={<InfoIcon />}
                    title="No Task Lists Yet"
                    description="Create your first task list to organize and track tasks for your organization. Task lists help manage work items efficiently."
                    action={
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() =>
                          navigate(
                            `/manager/organizations/${orgId}/task-lists/create`
                          )
                        }
                        startIcon={<AddIcon />}
                        sx={{
                          ...getButtonStyles(theme),
                          borderRadius: 8,
                          px: 2.5,
                          py: 1,
                          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
                        }}
                      >
                        Create Your First Task List
                      </Button>
                    }
                  />
                ) : (
                  taskLists.map((taskList) => (
                    <Card
                      key={taskList.id}
                      sx={{
                        ...getGlassStyles(theme, { opacity: 0.1 }),
                        p: 0,
                        overflow: "hidden",
                        borderRadius: 2,
                        border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                        transition: "all 0.2s ease-in-out",
                        "&:hover": {
                          transform: "translateY(-2px)",
                          boxShadow: `0 8px 24px ${alpha("#000", 0.15)}`,
                        },
                      }}
                    >
                      <Box
                        sx={{
                          p: 2.5,
                          pb: 2,
                          position: "relative",
                          "&::after": taskList.target_launch_date
                            ? {
                                content: '""',
                                position: "absolute",
                                top: 0,
                                left: 0,
                                width: "1px",
                                height: "100%",
                                background: `linear-gradient(to bottom, ${alpha(theme.palette.primary.main, 0.7)}, ${alpha(theme.palette.primary.main, 0.3)})`,
                              }
                            : {},
                        }}
                      >
                        <Stack spacing={1.5}>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="flex-start"
                          >
                            <Box>
                              <Typography
                                variant="h6"
                                fontWeight="600"
                                sx={{ mb: 0.5 }}
                              >
                                {taskList.name}
                              </Typography>
                              {taskList.target_launch_date && (
                                <Typography
                                  variant="body2"
                                  color="text.secondary"
                                  sx={{
                                    opacity: 0.9,
                                    display: "flex",
                                    alignItems: "center",
                                    gap: 0.5,
                                  }}
                                >
                                  <CalendarTodayIcon
                                    fontSize="small"
                                    sx={{
                                      color: theme.palette.primary.main,
                                      opacity: 0.8,
                                      fontSize: "1rem",
                                      marginRight: 0.3,
                                    }}
                                  />
                                  <span style={{ fontWeight: 500 }}>
                                    Launch:
                                  </span>{" "}
                                  {new Date(
                                    taskList.target_launch_date
                                  ).toLocaleDateString(undefined, {
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  })}
                                  {taskList.timezone
                                    ? ` (${taskList.timezone.replace(/^.*\//, "").replace("_", " ")})`
                                    : ""}
                                </Typography>
                              )}
                            </Box>
                            <Stack
                              direction="row"
                              spacing={0.5}
                              sx={{ mt: 0.5 }}
                            >
                              <ActionButton
                                icon={<EditIcon />}
                                onClick={() => {
                                  if (taskList.id) {
                                    navigate(
                                      `/manager/organizations/${orgId}/task-lists/${taskList.id}/edit`
                                    );
                                  }
                                }}
                                tooltip="Edit task list"
                              />
                              <ActionButton
                                icon={<DeleteIcon />}
                                onClick={() => {
                                  if (taskList.id) {
                                    setTaskListToDelete(taskList.id);
                                  }
                                }}
                                tooltip="Delete task list"
                                color={theme.palette.error.main}
                              />
                            </Stack>
                          </Stack>

                          {/* Display Task Statistics */}
                          {taskList.id &&
                          tasksByListId[taskList.id]?.length > 0 ? (
                            <Stack
                              direction="row"
                              spacing={1}
                              sx={{
                                flexWrap: "wrap",
                                gap: 1,
                              }}
                            >
                              {(() => {
                                const tasks = tasksByListId[taskList.id];
                                const completedCount = tasks.filter(
                                  (task) => task.status === "completed"
                                ).length;
                                const inProgressCount = tasks.filter(
                                  (task) => task.status === "in-progress"
                                ).length;
                                const notStartedCount = tasks.filter(
                                  (task) => task.status === "not-started"
                                ).length;
                                const totalTasks = tasks.length;
                                const completionPercentage = Math.round(
                                  (completedCount / totalTasks) * 100
                                );

                                return (
                                  <>
                                    <Box
                                      sx={{
                                        width: "100%",
                                        height: 2,
                                        backgroundColor: alpha(
                                          theme.palette.divider,
                                          0.15
                                        ),
                                        borderRadius: 2,
                                        overflow: "hidden",
                                        mt: 0.5,
                                        mb: 0.5,
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          width: `${completionPercentage}%`,
                                          height: "100%",
                                          background:
                                            completionPercentage === 100
                                              ? theme.palette.success.main
                                              : `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.info.main})`,
                                          transition: "width 0.3s ease-in-out",
                                        }}
                                      />
                                    </Box>
                                    <Chip
                                      size="small"
                                      label={`${completedCount} Completed`}
                                      color="success"
                                      variant="outlined"
                                      sx={{
                                        borderRadius: 1.5,
                                        height: 28,
                                        fontSize: "0.75rem",
                                      }}
                                    />
                                    <Chip
                                      size="small"
                                      label={`${inProgressCount} In Progress`}
                                      color="info"
                                      variant="outlined"
                                      sx={{
                                        borderRadius: 1.5,
                                        height: 28,
                                        fontSize: "0.75rem",
                                      }}
                                    />
                                    <Chip
                                      size="small"
                                      label={`${notStartedCount} Not Started`}
                                      color="default"
                                      variant="outlined"
                                      sx={{
                                        borderRadius: 1.5,
                                        height: 28,
                                        fontSize: "0.75rem",
                                      }}
                                    />
                                  </>
                                );
                              })()}
                            </Stack>
                          ) : taskList.id ? (
                            <Typography
                              variant="body2"
                              color="text.secondary"
                              sx={{ fontStyle: "italic", mt: 0.5 }}
                            >
                              No tasks in this list
                            </Typography>
                          ) : null}
                        </Stack>
                      </Box>
                    </Card>
                  ))
                )}
              </Stack>
            </Stack>
          </TabPanel>

          <TabPanel value={tabValue} index={3}>
            <AIConfigurationTab
              organization={organization}
              onUpdate={fetchOrganization}
            />
          </TabPanel>
        </Box>
      </Paper>

      <Snackbar
        open={notification.open}
        autoHideDuration={4000}
        onClose={() => {
          setNotification((prev: NotificationState) => ({
            ...prev,
            open: false,
          }));
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        sx={{
          "& .MuiAlert-root": {
            width: "100%",
            maxWidth: 400,
            boxShadow: `0 8px 24px ${alpha("#000", 0.15)}`,
          },
        }}
      >
        <Alert
          onClose={() => {
            setNotification((prev: NotificationState) => ({
              ...prev,
              open: false,
            }));
          }}
          severity={notification.severity}
          variant="filled"
          sx={{
            width: "100%",
            alignItems: "center",
            "& .MuiAlert-icon": {
              fontSize: 24,
            },
          }}
        >
          {notification.message}
        </Alert>
      </Snackbar>

      {isCreateWorkflowOpen && (
        <CreateWorkflow
          open={isCreateWorkflowOpen}
          onClose={() => setIsCreateWorkflowOpen(false)}
          orgId={orgId || ""}
        />
      )}

      {/* Delete Task List Confirmation Dialog */}
      <Dialog
        open={!!taskListToDelete}
        onClose={() => setTaskListToDelete(null)}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
            backgroundImage: "none",
          },
        }}
      >
        <DialogTitle>Delete Task List</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this task list? This action cannot
            be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setTaskListToDelete(null)}>Cancel</Button>
          <Button
            onClick={async () => {
              if (taskListToDelete && orgId) {
                try {
                  await dataProvider.delete(
                    `organizations/${orgId}/task-lists/${taskListToDelete}`,
                    {
                      id: taskListToDelete,
                    }
                  );
                  loadTaskLists();
                  setTaskListToDelete(null);
                  setNotification({
                    message: "Task list deleted successfully",
                    severity: "success",
                    open: true,
                  });
                } catch (err) {
                  console.error("Failed to delete task list:", err);
                  setNotification({
                    message: "Failed to delete task list",
                    severity: "error",
                    open: true,
                  });
                }
              }
            }}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
