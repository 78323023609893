import { Outlet, useNavigate } from "react-router-dom";
import { ThemeProvider, CssBaseline } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Auth0Provider } from "@auth0/auth0-react";
import { UserProvider } from "../contexts/UserContext";
import { theme } from "../theme";

export function RootLayout() {
  const navigate = useNavigate();

  return (
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_SPA_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_SPA_CLIENT_ID}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/login`,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE,
        scope: "openid profile email",
      }}
      cacheLocation="localstorage"
      onRedirectCallback={(appState) => {
        if (appState?.returnTo) {
          navigate(appState.returnTo);
        }
      }}
    >
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <UserProvider>
            <Outlet />
          </UserProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Auth0Provider>
  );
}
