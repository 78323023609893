import type { Theme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";

// Common card/paper styles
export const getGlassStyles = (
  theme: Theme,
  options?: {
    hoverEffect?: boolean;
    opacity?: number;
    blur?: number;
    borderRadius?: number;
  }
) => ({
  backgroundColor: alpha(
    theme.palette.background.paper,
    options?.opacity || 0.8
  ),
  backdropFilter: `blur(${options?.blur || 10}px)`,
  border: `1px solid ${alpha("#fff", 0.05)}`,
  borderRadius: options?.borderRadius || 3,
  ...(options?.hoverEffect && {
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      transform: "translateY(-2px)",
      boxShadow: `0 8px 24px ${alpha("#000", 0.15)}`,
    },
  }),
});

// Common coming soon overlay styles
export const getComingSoonOverlay = (theme: Theme) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: alpha(theme.palette.background.paper, 0.6),
  borderRadius: 3,
  zIndex: 1,
});

// Common chip styles
export const getChipStyles = (color: string) => ({
  backgroundColor: alpha(color, 0.1),
  color: color,
  borderRadius: 1,
  "& .MuiChip-label": {
    fontWeight: "bold",
  },
});

// Common icon button styles
export const getIconButtonStyles = (color: string) => ({
  color: color,
  "&:hover": {
    backgroundColor: alpha(color, 0.1),
  },
});

// Common loading skeleton styles
export const getSkeletonStyles = () => ({
  bgcolor: "grey.300",
  borderRadius: 1,
});

// Common tab styles
export const getTabStyles = () => ({
  transition: "all 0.2s ease-in-out",
  "&.Mui-selected": {
    fontWeight: "bold",
  },
});

// Common button styles
export const getButtonStyles = (theme: Theme) => ({
  borderRadius: 2,
  px: 3,
  py: 1,
  boxShadow: `0 8px 24px ${alpha(theme.palette.primary.main, 0.25)}`,
});

// Common notification styles
export const getNotificationStyles = () => ({
  "& .MuiAlert-root": {
    width: "100%",
    maxWidth: 400,
    boxShadow: `0 8px 24px ${alpha("#000", 0.15)}`,
  },
});

// Common info box styles
export const getInfoBoxStyles = (theme: Theme) => ({
  px: 2.5,
  py: 2,
  backgroundColor: alpha(theme.palette.primary.main, 0.03),
  borderRadius: 2,
  border: `1px solid ${alpha(theme.palette.primary.main, 0.08)}`,
  display: "flex",
  gap: 2,
  alignItems: "flex-start",
});

// Common avatar styles
export const getAvatarStyles = (theme: Theme, name: string) => {
  const colors = [
    theme.palette.primary.main,
    theme.palette.secondary.main,
    theme.palette.success.main,
    theme.palette.info.main,
  ];
  const index =
    name.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0) %
    colors.length;
  return {
    bgcolor: colors[index],
  };
};
