import {
  List,
  Datagrid,
  TextField,
  Edit,
  Create,
  DateField,
  useRecordContext,
  SimpleForm,
  TextInput,
  required,
  Show,
  TabbedShowLayout,
  NumberField,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
} from "react-admin";
import ReactJson from "@microlink/react-json-view";
import { Box, Typography } from "@mui/material";
import { alpha } from "@mui/material/styles";

const JsonView = ({ source }: { source: string }) => {
  const record = useRecordContext();
  if (!record || !record[source]) return null;

  return (
    <div style={{ maxHeight: "400px", overflow: "auto" }}>
      <ReactJson
        src={record[source]}
        theme="twilight"
        displayDataTypes={false}
        enableClipboard={false}
        collapsed={1}
        style={{
          backgroundColor: "#1E293B",
          padding: "16px",
          borderRadius: "4px",
          fontSize: "14px",
        }}
      />
    </div>
  );
};

const FormattedTextField = ({
  source,
  label,
}: {
  source: string;
  label?: string;
}) => {
  const record = useRecordContext();
  if (!record) return null;

  return (
    <Box sx={{ mb: 2 }}>
      {label && (
        <Typography variant="body2" color="textSecondary" gutterBottom>
          {label}
        </Typography>
      )}
      <Box
        sx={{
          p: 2,
          backgroundColor: (theme) =>
            alpha(theme.palette.background.default, 0.6),
          borderRadius: 1,
          fontFamily: "monospace",
          whiteSpace: "pre-wrap",
          maxHeight: "400px",
          overflowY: "auto",
        }}
      >
        {record[source]}
      </Box>
    </Box>
  );
};

// Title component for the edit view
const OrganizationTitle = () => {
  const record = useRecordContext();
  return record ? <span>Organization: {record.name}</span> : null;
};

interface ResourceProps {
  resource?: string;
}

export const OrganizationList = ({
  resource = "organizations",
}: ResourceProps) => (
  <List resource={resource}>
    <Datagrid rowClick="show">
      <TextField source="name" />
      <TextField source="email_domain" />
      <ReferenceField
        source="customer_of_id"
        reference="organizations"
        link="show"
      >
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField source="slack_app_id" reference="slack_apps" link="show">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="slack_channel_id" />
      <NumberField source="total_users" />
      <NumberField source="total_conversations" />
      <DateField source="last_activity" showTime />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
    </Datagrid>
  </List>
);

export const OrganizationEdit = ({
  resource = "organizations",
}: ResourceProps) => (
  <Edit title={<OrganizationTitle />} resource={resource}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} fullWidth />
      <TextInput
        source="email_domain"
        fullWidth
        helperText="Domain for organization emails (e.g. company.com)"
      />
      <ReferenceInput source="customer_of_id" reference="organizations">
        <AutocompleteInput
          optionText="name"
          fullWidth
          label="Customer Of"
          helperText="Select the parent organization"
        />
      </ReferenceInput>
      <ReferenceInput source="slack_app_id" reference="slack_apps">
        <AutocompleteInput
          optionText="name"
          fullWidth
          label="Slack App"
          helperText="Select the Slack app to use for this organization"
        />
      </ReferenceInput>
      <TextInput
        source="slack_channel_id"
        fullWidth
        helperText="Slack channel ID used to identify and lookup this organization. You can find a Slack channel ID in the URL of the channel page or in the channel details window."
      />
      <TextInput
        source="custom_prompt"
        fullWidth
        multiline
        rows={4}
        helperText="Custom prompt to be used for this organization's AI interactions"
      />
    </SimpleForm>
  </Edit>
);

export const OrganizationCreate = ({
  resource = "organizations",
}: ResourceProps) => (
  <Create resource={resource}>
    <SimpleForm>
      <TextInput source="name" validate={[required()]} fullWidth />
      <TextInput
        source="email_domain"
        fullWidth
        helperText="Domain for organization emails (e.g. company.com)"
      />
      <ReferenceInput source="customer_of_id" reference="organizations">
        <AutocompleteInput
          optionText="name"
          fullWidth
          label="Customer Of"
          helperText="Select the parent organization"
        />
      </ReferenceInput>
      <ReferenceInput source="slack_app_id" reference="slack_apps">
        <AutocompleteInput
          optionText="name"
          fullWidth
          label="Slack App"
          helperText="Select the Slack app to use for this organization"
        />
      </ReferenceInput>
      <TextInput
        source="slack_channel_id"
        fullWidth
        helperText="Slack channel ID used to identify and lookup customer organizations. You can find a Slack channel ID in the URL of the channel page or in the channel details window."
      />
      <TextInput
        source="custom_prompt"
        fullWidth
        multiline
        rows={4}
        helperText="Custom prompt to be used for this organization's AI interactions"
      />
    </SimpleForm>
  </Create>
);

export const OrganizationShow = ({
  resource = "organizations",
}: ResourceProps) => {
  return (
    <Show title={<OrganizationTitle />} resource={resource}>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Organization Details">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="slug" />
          <TextField source="email_domain" label="Email Domain" />
          <ReferenceField
            source="customer_of_id"
            reference="organizations"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <ReferenceField
            source="slack_app_id"
            reference="slack_apps"
            link="show"
          >
            <TextField source="name" />
          </ReferenceField>
          <NumberField source="total_users" label="Total Users" />
          <NumberField
            source="total_conversations"
            label="Total Conversations"
          />
          <DateField source="last_activity" showTime label="Last Activity" />
          <TextField source="slack_team_id" label="Slack Team ID" />
          <TextField source="slack_channel_id" label="Slack Channel ID" />
          <TextField source="ms_teams_tenant_id" label="MS Teams Tenant ID" />
          <FormattedTextField source="custom_prompt" label="Custom Prompt" />
          <JsonView source="platform_data" />
          <DateField source="created_at" showTime label="Created At" />
          <DateField source="updated_at" showTime label="Updated At" />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  );
};
