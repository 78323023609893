import { useState } from "react";
import {
  Box,
  Button,
  Card,
  Stack,
  TextField,
  Typography,
  alpha,
  useTheme,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Tooltip,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormHelperText,
  OutlinedInput,
  InputAdornment,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  Save as SaveIcon,
  ExpandMore as ExpandMoreIcon,
  CalendarMonth as CalendarIcon,
  CompareArrows as CompareArrowsIcon,
  CalendarToday as CalendarTodayIcon,
  InsertDriveFile as FileIcon,
  Image as ImageIcon,
  PictureAsPdf as PdfIcon,
  Description as TextIcon,
  Download as DownloadIcon,
  AttachFile as AttachmentIcon,
} from "@mui/icons-material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { getGlassStyles, getButtonStyles } from "../theme/shared";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { timezones } from "../constants/timezones";
import { useAuth0 } from "@auth0/auth0-react";

// Initialize dayjs plugins
dayjs.extend(utc);
dayjs.extend(timezone);

// Helper function to get default timezone
const getDefaultTimezone = (): string => {
  try {
    // Try to get the browser's timezone
    const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // Verify the timezone is in our supported list
    if (timezones.some((tz) => tz.value === browserTz)) {
      return browserTz;
    }
  } catch (e) {
    console.warn("Could not detect browser timezone:", e);
  }
  // Fall back to PT
  return "America/Los_Angeles";
};

/**
 * Task Due Date Handling:
 *
 * We have two modes for due dates:
 * 1. Absolute (Pinned) Dates: Use due_date field, clear relative_due_date
 * 2. Relative Dates: Use both due_date and relative_due_date fields
 *
 * The is_due_date_pinned flag is only used for UI state and is not sent to the backend.
 * When saving to the backend:
 * - For absolute dates: We clear relative_due_date (undefined)
 * - For relative dates: We keep both due_date (calculated) and relative_due_date
 *
 * The backend uses the presence/absence of relative_due_date to determine if a date is pinned.
 */

const TASK_STATUSES = ["not-started", "in-progress", "completed"] as const;

// Helper functions for relative due date calculations
const isValidRelativeDateFormat = (value: string): boolean => {
  // Validates format: +N or -N (where N is any number of digits)
  return /^[+-]\d+$/.test(value);
};

const getRelativeDateLabel = (value: string): string => {
  if (!value || !isValidRelativeDateFormat(value)) return "";

  const match = value.match(/^([+-])(\d+)$/);
  if (!match) return "";

  const [, sign, days] = match;
  const daysNum = parseInt(days, 10);

  // Special case for 0 days
  if (daysNum === 0) {
    return "on launch day";
  }

  if (sign === "+") {
    return `${days} day${daysNum !== 1 ? "s" : ""} after launch`;
  } else {
    return `${days} day${daysNum !== 1 ? "s" : ""} before launch`;
  }
};

const calculateDueDateFromRelative = (
  launchDate: string | undefined,
  relativeOffset: string | undefined,
  timezone: string = "UTC"
): string | undefined => {
  if (!launchDate || !relativeOffset) return undefined;

  // Parse the offset value (e.g. "+3", "-2")
  // will tolerate positive without a sign
  const match = relativeOffset.match(/^([+-]{0,1})(\d+)$/);
  if (!match) return undefined;

  const [, sign, days] = match;
  const daysNum = parseInt(days, 10);

  // Calculate the new date
  const baseDate = dayjs(launchDate).tz(timezone);
  const newDate =
    sign === "-"
      ? baseDate.subtract(daysNum, "day")
      : baseDate.add(daysNum, "day");

  return newDate.format();
};

type TaskStatus = (typeof TASK_STATUSES)[number];

// Helper function to format file size
const formatFileSize = (bytes: number): string => {
  if (bytes < 1024) return bytes + " B";
  else if (bytes < 1024 * 1024) return (bytes / 1024).toFixed(1) + " KB";
  else if (bytes < 1024 * 1024 * 1024)
    return (bytes / (1024 * 1024)).toFixed(1) + " MB";
  else return (bytes / (1024 * 1024 * 1024)).toFixed(1) + " GB";
};

// Helper function to get icon based on content type
const getFileIcon = (contentType: string) => {
  if (contentType.startsWith("image/")) return <ImageIcon />;
  if (contentType === "application/pdf") return <PdfIcon />;
  if (contentType.startsWith("text/")) return <TextIcon />;
  return <FileIcon />;
};

interface StatusUpdateAttachment {
  id: string;
  task_id: string;
  object_key: string;
  bucket: string;
  content_type: string;
  size: number;
  filename: string;
  source: string;
  source_url: string;
  created_at: string;
  created_by: string | null;
  download_url: string | null;
}

interface Task {
  id: string;
  task_list_id?: string;
  name: string;
  description?: string;
  resource_link?: string;
  status: TaskStatus;
  status_update?: string;
  due_date?: string;
  relative_due_date?: string;
  is_due_date_pinned?: boolean;
  timezone?: string;
  completed_at?: string;
  status_update_attachments?: StatusUpdateAttachment[];
}

interface TaskList {
  id?: string;
  organization_id: string;
  name: string;
  launch_details?: string;
  target_launch_date?: string;
  timezone?: string;
}

interface TaskListEditorProps {
  initialTaskList?: TaskList;
  initialTasks?: Task[];
  onSave: (taskList: TaskList, tasks: Task[]) => void;
  onDelete: () => void;
  isCustomerView?: boolean;
}

interface TaskItemProps {
  task: Task;
  taskIndex: number;
  onUpdate: (index: number, updates: Partial<Task>) => void;
  onRemove: (index: number) => void;
  isNew?: boolean;
  taskList: TaskList;
  isCustomerView?: boolean;
}

// Custom hook for attachment download functionality
function useStatusUpdateAttachmentDownload() {
  const { getAccessTokenSilently } = useAuth0();

  const downloadStatusUpdateAttachment = async (
    organizationId: string,
    taskListId: string,
    taskId: string,
    attachmentId: string,
    filename: string
  ) => {
    try {
      const baseUrl = import.meta.env.VITE_API_URL.replace(/\/$/, "");
      const url = `${baseUrl}/api/organizations/${organizationId}/task-lists/${taskListId}/tasks/${taskId}/status-update-attachments/${attachmentId}/download`;
      const token = await getAccessTokenSilently();
      // Fetch the file with authorization headers
      const response = await fetch(url, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!response.ok) throw new Error(`Download failed: ${response.status}`);
      // Get the blob from the response
      const blob = await response.blob();
      // Create a download link and trigger download
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = filename;
      document.body.appendChild(link);
      link.click();
      // Clean up
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error("Error downloading attachment:", error);
    }
  };

  return { downloadStatusUpdateAttachment };
}

function DueDateCalendarIcon({
  due_date,
  timezone,
  relative_due_date,
  launch_date,
}: {
  due_date?: string;
  timezone?: string;
  relative_due_date?: string;
  launch_date?: string;
}) {
  const theme = useTheme();

  // Format the due date for display
  const formatDueDateForDisplay = (dueDate?: string, timezone?: string) => {
    if (!dueDate) return null;
    return dayjs(dueDate)
      .tz(timezone || "UTC")
      .format("MMM D");
  };

  // Calculate the due date if not provided but we have relative date and launch date
  const effectiveDueDate =
    due_date ||
    (relative_due_date && launch_date
      ? calculateDueDateFromRelative(
          launch_date,
          relative_due_date,
          timezone || "UTC"
        )
      : undefined);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minWidth: 70,
        border: effectiveDueDate
          ? `1px solid ${alpha(theme.palette.primary.main, 0.5)}`
          : `1px dashed ${alpha(theme.palette.text.disabled, 0.3)}`,
        borderRadius: 1,
        py: 0.5,
        px: 1,
        backgroundColor: effectiveDueDate
          ? alpha(theme.palette.primary.main, 0.05)
          : "transparent",
      }}
      onClick={(e) => e.stopPropagation()}
    >
      <CalendarTodayIcon
        fontSize="small"
        color={effectiveDueDate ? "primary" : "disabled"}
      />
      <Typography
        variant="caption"
        color={effectiveDueDate ? "primary" : "text.disabled"}
        fontWeight={effectiveDueDate ? "medium" : "normal"}
      >
        {effectiveDueDate
          ? formatDueDateForDisplay(effectiveDueDate, timezone)
          : "No date"}
      </Typography>
    </Box>
  );
}

interface StatusUpdateAttachmentsProps {
  taskList: TaskList;
  task: Task;
}

function StatusUpdateAttachments({
  taskList,
  task,
}: StatusUpdateAttachmentsProps) {
  const theme = useTheme();
  const { downloadStatusUpdateAttachment } =
    useStatusUpdateAttachmentDownload();

  if (
    !task.status_update_attachments ||
    task.status_update_attachments.length === 0
  ) {
    return null;
  }

  return (
    <Box
      sx={{
        border: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
        borderRadius: 1,
        overflow: "hidden",
        mt: 2,
      }}
    >
      <Typography
        variant="subtitle2"
        sx={{
          p: 1,
          pl: 2,
          backgroundColor: alpha(theme.palette.background.paper, 0.1),
          borderBottom: `1px solid ${alpha(theme.palette.divider, 0.2)}`,
        }}
      >
        Status Update Attachments ({task.status_update_attachments.length})
      </Typography>
      <List dense sx={{ p: 0 }}>
        {task.status_update_attachments.map((attachment) => (
          <ListItem
            key={attachment.id}
            sx={{
              borderBottom: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
              "&:last-child": {
                borderBottom: "none",
              },
            }}
          >
            <ListItemIcon>{getFileIcon(attachment.content_type)}</ListItemIcon>
            <ListItemText
              primary={
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  <Typography variant="body2" sx={{ mr: 0.5 }}>
                    {attachment.filename}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    ({formatFileSize(attachment.size)})
                  </Typography>
                </Box>
              }
            />
            <ListItemSecondaryAction>
              <Tooltip title="Download">
                <IconButton
                  edge="end"
                  size="small"
                  onClick={() =>
                    downloadStatusUpdateAttachment(
                      taskList.organization_id,
                      task.task_list_id || taskList.id || "",
                      task.id,
                      attachment.id,
                      attachment.filename
                    )
                  }
                >
                  <DownloadIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

interface StatusUpdateAttachmentsCountChipProps {
  attachments?: StatusUpdateAttachment[];
}

function StatusUpdateAttachmentsCountChip({
  attachments,
}: StatusUpdateAttachmentsCountChipProps) {
  if (!attachments || attachments.length === 0) {
    return null;
  }

  return (
    <Tooltip
      title={`${attachments.length} attachment${attachments.length > 1 ? "s" : ""}`}
    >
      <Chip
        size="small"
        icon={<AttachmentIcon fontSize="small" />}
        label={attachments.length}
        variant="outlined"
        onClick={(e) => e.stopPropagation()}
      />
    </Tooltip>
  );
}

function TaskItem({
  task,
  taskIndex,
  onUpdate,
  onRemove,
  isNew,
  taskList,
  isCustomerView = false,
}: TaskItemProps) {
  const theme = useTheme();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [expanded, setExpanded] = useState(isNew);
  const [urlError, setUrlError] = useState("");

  const getStatusStyles = () => {
    switch (task.status) {
      case "completed":
        return {
          borderColor: alpha("#fff", 0.1),
          backgroundColor: alpha(theme.palette.success.main, 0.03),
        };
      case "in-progress":
        return {
          borderColor: alpha("#fff", 0.1),
          backgroundColor: alpha(theme.palette.info.main, 0.06),
        };
      case "not-started":
        return {
          borderColor: alpha("#fff", 0.1),
          backgroundColor: "transparent",
        };
      default:
        return {
          borderColor: alpha("#fff", 0.1),
          backgroundColor: "transparent",
        };
    }
  };

  const getStatusColor = (status: TaskStatus) => {
    switch (status) {
      case "completed":
        return "success";
      case "in-progress":
        return "info";
      case "not-started":
        return "default";
      default:
        return "default";
    }
  };

  const formatStatus = (status: string) => {
    return status
      .split("-")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const validateUrl = (url: string) => {
    if (!url) return true; // Optional field
    try {
      new URL(url);
      return true;
    } catch (e) {
      return false;
    }
  };

  return (
    <>
      <Card
        sx={{
          ...getGlassStyles(theme, { opacity: 0.1 }),
          borderWidth: 2,
          borderStyle: "solid",
          ...getStatusStyles(),
        }}
      >
        <Accordion
          expanded={expanded}
          onChange={() => setExpanded(!expanded)}
          disableGutters
          elevation={0}
          sx={{
            backgroundColor: "transparent",
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              padding: 2,
              "& .MuiAccordionSummary-content": {
                margin: 0,
              },
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center" width="100%">
              <DueDateCalendarIcon
                due_date={task.due_date}
                timezone={task.timezone}
                relative_due_date={task.relative_due_date}
                launch_date={taskList.target_launch_date}
              />

              <Box flex={1}>
                <TextField
                  fullWidth
                  size="small"
                  label="Task Name"
                  value={task.name}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => {
                    onUpdate(taskIndex, {
                      name: e.target.value,
                    });
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: alpha(
                        theme.palette.background.paper,
                        0.1
                      ),
                    },
                  }}
                />
              </Box>

              <Chip
                size="small"
                label={formatStatus(task.status)}
                color={getStatusColor(task.status)}
                variant="outlined"
                onClick={(e) => e.stopPropagation()}
              />

              <StatusUpdateAttachmentsCountChip
                attachments={task.status_update_attachments}
              />

              {!isCustomerView && (
                <Tooltip title="Delete task">
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDeleteConfirm(true);
                    }}
                    sx={{
                      color: theme.palette.error.main,
                      "&:hover": {
                        backgroundColor: alpha(theme.palette.error.main, 0.1),
                      },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Stack>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 2, pt: 0 }}>
            <Stack spacing={2}>
              <TextField
                fullWidth
                multiline
                rows={2}
                size="small"
                label="Description"
                value={task.description || ""}
                onChange={(e) => {
                  onUpdate(taskIndex, {
                    description: e.target.value,
                  });
                }}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: alpha(theme.palette.background.paper, 0.1),
                  },
                }}
              />

              <TextField
                fullWidth
                size="small"
                type="url"
                label="Resource Link"
                value={task.resource_link || ""}
                onChange={(e) => {
                  const value = e.target.value;
                  const isValid = validateUrl(value);
                  setUrlError(isValid ? "" : "Please enter a valid URL");
                  onUpdate(taskIndex, {
                    resource_link: value,
                  });
                }}
                error={!!urlError}
                helperText={urlError}
                placeholder="Add a link to relevant resources"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: alpha(theme.palette.background.paper, 0.1),
                  },
                }}
              />

              <Stack direction="row" spacing={2}>
                <FormControl fullWidth size="small">
                  <InputLabel>Status</InputLabel>
                  <Select
                    value={task.status}
                    label="Status"
                    onChange={(e) => {
                      onUpdate(taskIndex, {
                        status: e.target.value as TaskStatus,
                        completed_at:
                          e.target.value === "completed"
                            ? new Date().toISOString()
                            : undefined,
                      });
                    }}
                    sx={{
                      backgroundColor: alpha(
                        theme.palette.background.paper,
                        0.1
                      ),
                    }}
                  >
                    {TASK_STATUSES.map((status) => (
                      <MenuItem key={status} value={status}>
                        {formatStatus(status)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>

              <TextField
                fullWidth
                multiline
                rows={2}
                size="small"
                label="Status Update"
                value={task.status_update || ""}
                onChange={(e) => {
                  onUpdate(taskIndex, {
                    status_update: e.target.value || undefined,
                  });
                }}
                placeholder="Add details about the current status"
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: alpha(theme.palette.background.paper, 0.1),
                  },
                }}
              />

              <Stack direction="row" spacing={2} alignItems="center">
                {/* Show unpinned mode when is_due_date_pinned is explicitly false, otherwise show pinned mode */}
                {task.is_due_date_pinned === false ? (
                  // Relative due date interface (unpinned)
                  <>
                    <FormControl fullWidth size="small">
                      <InputLabel>Relative Due Date</InputLabel>
                      <Stack direction="row" spacing={0} alignItems="center">
                        <OutlinedInput
                          value={task.relative_due_date || ""}
                          onChange={(e) => {
                            // Allow typing negative numbers
                            const value = e.target.value;

                            // Allow empty input or just minus sign during typing
                            if (
                              !value ||
                              value === "-" ||
                              value === "+" ||
                              isValidRelativeDateFormat(value)
                            ) {
                              // Only calculate actual due date if in final valid format
                              const calculatedDueDate =
                                isValidRelativeDateFormat(value)
                                  ? calculateDueDateFromRelative(
                                      taskList.target_launch_date,
                                      value,
                                      task.timezone || "UTC"
                                    )
                                  : undefined;

                              onUpdate(taskIndex, {
                                relative_due_date: value || undefined,
                                due_date: calculatedDueDate,
                                is_due_date_pinned: false,
                              });
                            }
                          }}
                          placeholder="e.g. -3, +2"
                          label="Relative Due Date"
                          endAdornment={
                            task.relative_due_date &&
                            isValidRelativeDateFormat(
                              task.relative_due_date
                            ) ? (
                              <InputAdornment position="end">
                                <Typography
                                  variant="caption"
                                  color="text.secondary"
                                >
                                  {getRelativeDateLabel(task.relative_due_date)}
                                </Typography>
                              </InputAdornment>
                            ) : null
                          }
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: alpha(
                                theme.palette.background.paper,
                                0.1
                              ),
                            },
                            flexGrow: 1,
                          }}
                        />
                        <Tooltip title="Pin to specific date">
                          <IconButton
                            onClick={() => {
                              onUpdate(taskIndex, {
                                is_due_date_pinned: true,
                                relative_due_date: undefined,
                              });
                            }}
                            size="small"
                            sx={{
                              ml: 1,
                            }}
                          >
                            <CalendarIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                      <FormHelperText>
                        {task.due_date && taskList.target_launch_date
                          ? `Due on: ${dayjs(task.due_date)
                              .tz(task.timezone || "UTC")
                              .format("MMM D, YYYY h:mm A")}`
                          : "Enter +/- days from launch date"}
                      </FormHelperText>
                    </FormControl>
                  </>
                ) : (
                  // Pinned (absolute) due date interface
                  <>
                    <FormControl fullWidth size="small">
                      <Stack direction="row" spacing={0} alignItems="center">
                        <DateTimePicker
                          label="Due Date (Pinned)"
                          value={
                            task.due_date
                              ? dayjs(task.due_date).tz(task.timezone || "UTC")
                              : null
                          }
                          onChange={(date: Dayjs | null) => {
                            onUpdate(taskIndex, {
                              due_date: date
                                ? date.tz(task.timezone || "UTC").format()
                                : undefined,
                              is_due_date_pinned: date ? true : undefined,
                              relative_due_date: undefined, // Clear relative_due_date for backend compatibility
                            });
                          }}
                          timezone={task.timezone || "UTC"}
                          slotProps={{
                            textField: {
                              fullWidth: true,
                              size: "small",
                              sx: {
                                "& .MuiOutlinedInput-root": {
                                  backgroundColor: alpha(
                                    theme.palette.background.paper,
                                    0.1
                                  ),
                                },
                                flexGrow: 1,
                              },
                            },
                          }}
                        />
                        <Tooltip title="Switch to relative date">
                          <IconButton
                            onClick={() => {
                              // Set a relative offset based on current date if possible
                              let relativeOffset = task.relative_due_date;

                              // If no existing relative date and we have both a due date and launch date,
                              // calculate the relative offset
                              if (
                                !relativeOffset &&
                                task.due_date &&
                                taskList.target_launch_date
                              ) {
                                try {
                                  const dueDate = dayjs(task.due_date).tz(
                                    task.timezone || "UTC"
                                  );
                                  const launchDate = dayjs(
                                    taskList.target_launch_date
                                  ).tz(task.timezone || "UTC");
                                  const diffDays = dueDate.diff(
                                    launchDate,
                                    "day"
                                  );

                                  // Format as +X or -X
                                  relativeOffset =
                                    diffDays >= 0
                                      ? `+${diffDays}`
                                      : `${diffDays}`;
                                } catch (e) {
                                  // If calculation fails, use default
                                  relativeOffset = "+0";
                                }
                              } else if (!relativeOffset) {
                                // Default value if we can't calculate
                                relativeOffset = "+0";
                              }

                              onUpdate(taskIndex, {
                                is_due_date_pinned: false,
                                relative_due_date: relativeOffset,
                              });
                            }}
                            size="small"
                            sx={{
                              ml: 1,
                            }}
                          >
                            <CompareArrowsIcon />
                          </IconButton>
                        </Tooltip>
                      </Stack>
                    </FormControl>
                  </>
                )}
              </Stack>

              {task.due_date && (
                <FormControl fullWidth size="small">
                  <InputLabel id={`timezone-select-label-${taskIndex}`}>
                    Timezone
                  </InputLabel>
                  <Select
                    labelId={`timezone-select-label-${taskIndex}`}
                    id={`timezone-select-${taskIndex}`}
                    value={task.timezone || "UTC"}
                    onChange={(e) => {
                      // When changing timezone, we need to update the due date
                      const newTimezone = e.target.value;
                      let updatedDueDate = task.due_date;

                      if (updatedDueDate) {
                        // Convert the existing due date to the new timezone
                        updatedDueDate = dayjs(updatedDueDate)
                          .tz(newTimezone)
                          .format();
                      }

                      // If using relative date, recalculate based on new timezone
                      if (
                        task.relative_due_date &&
                        !task.is_due_date_pinned &&
                        taskList.target_launch_date
                      ) {
                        updatedDueDate = calculateDueDateFromRelative(
                          taskList.target_launch_date,
                          task.relative_due_date,
                          newTimezone
                        );
                      }

                      onUpdate(taskIndex, {
                        timezone: newTimezone,
                        due_date: updatedDueDate,
                      });
                    }}
                    label="Timezone"
                    sx={{
                      backgroundColor: alpha(
                        theme.palette.background.paper,
                        0.1
                      ),
                    }}
                  >
                    {timezones.map((tz) => (
                      <MenuItem key={tz.value} value={tz.value}>
                        {tz.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>
                    Set the timezone for this task's due date
                  </FormHelperText>
                </FormControl>
              )}

              {task.completed_at && (
                <Chip
                  label={`Completed: ${new Date(task.completed_at).toLocaleString()}`}
                  color="success"
                  variant="outlined"
                  size="small"
                />
              )}

              <StatusUpdateAttachments taskList={taskList} task={task} />
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Card>

      <Dialog
        open={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
            backgroundImage: "none",
          },
        }}
      >
        <DialogTitle>Delete Task</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this task? This cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteConfirm(false)}>Cancel</Button>
          <Button
            onClick={() => {
              onRemove(taskIndex);
              setShowDeleteConfirm(false);
            }}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export function TaskListEditor({
  initialTaskList,
  initialTasks = [],
  onSave,
  onDelete,
  isCustomerView = false,
}: TaskListEditorProps) {
  const theme = useTheme();
  const [taskList, setTaskList] = useState<TaskList>({
    id: initialTaskList?.id,
    organization_id: initialTaskList?.organization_id || "",
    name: initialTaskList?.name || "",
    launch_details: initialTaskList?.launch_details || "",
    target_launch_date: initialTaskList?.target_launch_date || undefined,
    timezone: initialTaskList?.timezone || getDefaultTimezone(),
  });

  // Initialize tasks and ensure is_due_date_pinned is properly set
  const [tasks, setTasks] = useState<Task[]>(() => {
    return initialTasks.map((task) => ({
      ...task,
      // If task has a relative_due_date, set is_due_date_pinned to false
      // Otherwise, default to true for existing tasks with due dates
      is_due_date_pinned: task.relative_due_date
        ? false
        : task.is_due_date_pinned === false
          ? false
          : task.due_date
            ? true
            : undefined,
    }));
  });

  const handleAddTask = () => {
    // Default to 0 days relative to launch date (same day as launch)
    const defaultRelativeDueDate = "+0";

    // Calculate the due date if there's a launch date
    const calculatedDueDate = taskList.target_launch_date
      ? calculateDueDateFromRelative(
          taskList.target_launch_date,
          defaultRelativeDueDate,
          taskList.timezone || getDefaultTimezone()
        )
      : undefined;

    const newTask: Task = {
      id: `task-${tasks.length + 1}`,
      task_list_id: taskList.id,
      name: "New Task",
      description: "",
      status: "not-started",
      status_update: "",
      due_date: calculatedDueDate,
      relative_due_date: defaultRelativeDueDate,
      is_due_date_pinned: false, // Default to relative dates
      timezone: taskList.timezone || getDefaultTimezone(),
    };
    setTasks([...tasks, newTask]);
  };

  const handleRemoveTask = (index: number) => {
    const newTasks = tasks.filter((_, i) => i !== index);
    setTasks(newTasks);
  };

  const handleUpdateTask = (index: number, updates: Partial<Task>) => {
    const newTasks = [...tasks];
    newTasks[index] = { ...newTasks[index], ...updates };
    setTasks(newTasks);
  };

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const renderEmptyState = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        py: 8,
        px: 3,
        textAlign: "center",
        backgroundColor: alpha(theme.palette.background.paper, 0.05),
        borderRadius: 2,
      }}
    >
      <Typography variant="h6" color="primary" gutterBottom>
        No Tasks Added Yet
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ mb: 3, maxWidth: 400 }}
      >
        Start by adding tasks to your list. You can organize them by dragging
        and dropping, set dependencies, and track their progress.
      </Typography>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleAddTask}
        sx={getButtonStyles(theme)}
      >
        Add Your First Task
      </Button>
    </Box>
  );

  // Clean up task data before saving to backend
  const handleSave = () => {
    // Create a cleaned copy of tasks to remove UI-only state properties
    const cleanedTasks = tasks.map((task) => {
      // Create a copy of the task so we don't modify the original
      const cleanedTask = { ...task };

      // For pinned (absolute) dates: clear relative_due_date
      if (task.is_due_date_pinned === true) {
        cleanedTask.relative_due_date = undefined;
      }

      // Remove the is_due_date_pinned property as it's only for UI state
      delete cleanedTask.is_due_date_pinned;

      return cleanedTask;
    });

    // Call the original onSave with the cleaned data
    onSave(taskList, cleanedTasks);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        minHeight: 0,
        flex: 1,
        overflow: "auto",
        pb: 2,
      }}
    >
      {/* Task List Details Section */}
      <Card
        sx={{
          ...getGlassStyles(theme),
          display: "flex",
          flexDirection: "column",
          minHeight: 0,
        }}
      >
        <Box sx={{ p: 3, flex: 1, overflow: "auto" }}>
          <Stack spacing={3}>
            <Typography variant="h6" color="primary">
              Task List Details
            </Typography>
            <TextField
              fullWidth
              label="Name"
              value={taskList.name}
              onChange={(e) => {
                setTaskList({ ...taskList, name: e.target.value });
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: alpha(theme.palette.background.paper, 0.1),
                },
              }}
            />
            <TextField
              fullWidth
              multiline
              rows={3}
              label="Launch Details"
              value={taskList.launch_details || ""}
              onChange={(e) => {
                setTaskList({ ...taskList, launch_details: e.target.value });
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: alpha(theme.palette.background.paper, 0.1),
                },
              }}
            />
            <Stack direction="row" spacing={2}>
              <DateTimePicker
                label="Target Launch Date"
                value={
                  taskList.target_launch_date
                    ? dayjs(taskList.target_launch_date).tz(
                        taskList.timezone || "UTC"
                      )
                    : null
                }
                onChange={(date: Dayjs | null) => {
                  // Update the task list's launch date
                  const newLaunchDate = date
                    ? date.tz(taskList.timezone || "UTC").format()
                    : undefined;

                  setTaskList({
                    ...taskList,
                    target_launch_date: newLaunchDate,
                  });

                  // Recalculate due dates for all tasks with relative dates
                  if (newLaunchDate) {
                    const updatedTasks = tasks.map((task) => {
                      // Only update tasks that use relative dates (not pinned)
                      if (
                        task.relative_due_date &&
                        task.is_due_date_pinned !== true
                      ) {
                        return {
                          ...task,
                          due_date: calculateDueDateFromRelative(
                            newLaunchDate,
                            task.relative_due_date,
                            task.timezone || "UTC"
                          ),
                        };
                      }
                      return task;
                    });

                    setTasks(updatedTasks);
                  }
                }}
                timezone={taskList.timezone || "UTC"}
                slotProps={{
                  textField: {
                    fullWidth: true,
                    size: "small",
                    sx: {
                      "& .MuiOutlinedInput-root": {
                        backgroundColor: alpha(
                          theme.palette.background.paper,
                          0.1
                        ),
                      },
                    },
                  },
                }}
              />
            </Stack>

            {taskList.target_launch_date && (
              <FormControl fullWidth size="small">
                <InputLabel id={`timezone-select-label-task-list`}>
                  Launch Date Timezone
                </InputLabel>
                <Select
                  labelId={`timezone-select-label-task-list`}
                  id={`timezone-select-task-list`}
                  value={taskList.timezone || "UTC"}
                  onChange={(e) => {
                    const newTimezone = e.target.value;
                    let updatedLaunchDate = taskList.target_launch_date;

                    if (updatedLaunchDate) {
                      // Convert the existing launch date to the new timezone
                      updatedLaunchDate = dayjs(updatedLaunchDate)
                        .tz(newTimezone)
                        .format();
                    }

                    setTaskList({
                      ...taskList,
                      timezone: newTimezone,
                      target_launch_date: updatedLaunchDate,
                    });

                    // Recalculate due dates for all tasks with relative dates
                    if (updatedLaunchDate) {
                      const updatedTasks = tasks.map((task) => {
                        // Only update tasks that use relative dates (not pinned)
                        if (
                          task.relative_due_date &&
                          task.is_due_date_pinned !== true
                        ) {
                          return {
                            ...task,
                            due_date: calculateDueDateFromRelative(
                              updatedLaunchDate,
                              task.relative_due_date,
                              task.timezone || newTimezone
                            ),
                          };
                        }
                        return task;
                      });

                      setTasks(updatedTasks);
                    }
                  }}
                  label="Timezone"
                  sx={{
                    backgroundColor: alpha(theme.palette.background.paper, 0.1),
                  }}
                >
                  {timezones.map((tz) => (
                    <MenuItem key={tz.value} value={tz.value}>
                      {tz.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  Set the timezone for this task list's target launch date
                </FormHelperText>
              </FormControl>
            )}
          </Stack>
        </Box>
      </Card>

      {/* Tasks Section */}
      <Card
        sx={{
          ...getGlassStyles(theme),
          display: "flex",
          flexDirection: "column",
          minHeight: 0,
          flex: 1,
        }}
      >
        <Box sx={{ p: 3, flex: 1, overflow: "auto" }}>
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" color="primary">
                Tasks
              </Typography>
              {tasks.length > 0 && !isCustomerView && (
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleAddTask}
                  variant="contained"
                  color="primary"
                  sx={getButtonStyles(theme)}
                >
                  Add Task
                </Button>
              )}
            </Stack>

            {tasks.length === 0 ? (
              renderEmptyState()
            ) : (
              <Stack spacing={2}>
                {tasks.map((task, index) => (
                  <TaskItem
                    key={task.id}
                    task={task}
                    taskIndex={index}
                    onUpdate={handleUpdateTask}
                    onRemove={handleRemoveTask}
                    isNew={index === tasks.length - 1}
                    taskList={taskList}
                    isCustomerView={isCustomerView}
                  />
                ))}
              </Stack>
            )}
          </Stack>
        </Box>
      </Card>

      {/* Save and Delete Buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 2,
          mt: "auto",
          pt: 2,
        }}
      >
        {!isCustomerView && (
          <Button
            variant="contained"
            color="error"
            onClick={() => {
              setIsDeleteDialogOpen(true);
            }}
            disabled={!taskList.name}
            startIcon={<DeleteIcon />}
            sx={getButtonStyles(theme)}
          >
            Delete Task List
          </Button>
        )}
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={!taskList.name}
          startIcon={<SaveIcon />}
          sx={getButtonStyles(theme)}
        >
          Save
        </Button>
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => {
          setIsDeleteDialogOpen(false);
        }}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
            backgroundImage: "none",
          },
        }}
      >
        <DialogTitle>Delete Task List</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this task list? This action cannot
            be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsDeleteDialogOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onDelete();
              setIsDeleteDialogOpen(false);
            }}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
