import { createTheme, alpha } from "@mui/material/styles";

// Custom background gradients
const gradients = {
  subtle: `linear-gradient(to bottom right, ${alpha("#7C3AED", 0.05)}, ${alpha(
    "#3B82F6",
    0.05
  )})`,
  primary: `linear-gradient(to right, #7C3AED, #3B82F6)`,
  glow: `radial-gradient(circle at top center, ${alpha(
    "#7C3AED",
    0.15
  )} 0%, transparent 70%)`,
};

export const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#7C3AED", // Vibrant purple
      light: "#9F67FF",
      dark: "#5B21B6",
    },
    secondary: {
      main: "#10B981", // Emerald green
      light: "#34D399",
      dark: "#059669",
    },
    background: {
      default: "#030712", // Updated darker background
      paper: "#111827", // Darker paper
    },
    text: {
      primary: "#F8FAFC", // Slate 50
      secondary: "#94A3B8", // Slate 400
    },
    error: {
      main: "#EF4444",
    },
    warning: {
      main: "#F59E0B",
    },
    info: {
      main: "#3B82F6",
    },
    success: {
      main: "#10B981",
    },
  },
  typography: {
    fontFamily:
      '"Inter var", "Inter", system-ui, -apple-system, BlinkMacSystemFont, sans-serif',
    h1: {
      fontSize: "2.5rem",
      fontWeight: 700,
      letterSpacing: "-0.03em",
      lineHeight: 1.2,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 600,
      letterSpacing: "-0.02em",
      lineHeight: 1.3,
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: 600,
      letterSpacing: "-0.01em",
    },
    h4: {
      fontSize: "1.25rem",
      fontWeight: 600,
      letterSpacing: "-0.01em",
    },
    h5: {
      fontSize: "1.125rem",
      fontWeight: 500,
      letterSpacing: "-0.01em",
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 500,
    },
    body1: {
      fontSize: "1rem",
      lineHeight: 1.6,
      letterSpacing: "-0.01em",
    },
    body2: {
      fontSize: "0.875rem",
      lineHeight: 1.6,
      letterSpacing: "-0.01em",
    },
    button: {
      textTransform: "none",
      fontWeight: 500,
      letterSpacing: "-0.01em",
    },
  },
  shape: {
    borderRadius: 12,
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: gradients.glow,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          padding: "10px 20px",
          fontWeight: 500,
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            transform: "translateY(-1px)",
          },
        },
        contained: {
          background: gradients.primary,
          boxShadow: `0 2px 10px ${alpha("#7C3AED", 0.3)}`,
          "&:hover": {
            boxShadow: `0 4px 20px ${alpha("#7C3AED", 0.4)}`,
          },
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: "16px",
          backdropFilter: "blur(10px)",
          backgroundColor: alpha("#111827", 0.7),
          backgroundImage: gradients.subtle,
          border: `1px solid ${alpha("#fff", 0.05)}`,
          boxShadow: `0 4px 20px ${alpha("#000", 0.2)}`,
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            transform: "translateY(-2px)",
            boxShadow: `0 8px 30px ${alpha("#000", 0.3)}`,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: gradients.subtle,
          backdropFilter: "blur(10px)",
          backgroundColor: alpha("#111827", 0.7),
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${alpha("#fff", 0.05)}`,
        },
        head: {
          fontWeight: 600,
          color: "#F8FAFC",
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          transition: "background-color 0.2s ease-in-out",
          "&:hover": {
            backgroundColor: alpha("#fff", 0.03),
          },
          "&:last-child td": {
            borderBottom: 0,
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: alpha("#111827", 0.7),
          backdropFilter: "blur(10px)",
          borderBottom: `1px solid ${alpha("#fff", 0.05)}`,
          backgroundImage: "none",
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: alpha("#111827", 0.95),
          backdropFilter: "blur(10px)",
          backgroundImage: gradients.subtle,
          borderRight: `1px solid ${alpha("#fff", 0.05)}`,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            transition: "all 0.2s ease-in-out",
            "&:hover": {
              "& fieldset": {
                borderColor: alpha("#7C3AED", 0.4),
              },
            },
            "&.Mui-focused": {
              "& fieldset": {
                borderColor: "#7C3AED",
                boxShadow: `0 0 0 4px ${alpha("#7C3AED", 0.1)}`,
              },
            },
          },
        },
      },
    },
  },
});
