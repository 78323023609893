import React from "react";
import {
  Box,
  Container,
  AppBar,
  Toolbar,
  Typography,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CoraLogo } from "../components/CoraLogo";
import { UserMenu } from "../components/UserMenu";

export const MainLayout = ({ children }: { children: React.ReactNode }) => {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <AppBar
        position="sticky"
        elevation={0}
        sx={{
          bgcolor: "background.paper",
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Toolbar>
          <Stack
            direction="row"
            spacing={2}
            alignItems="center"
            sx={{ flexGrow: 1 }}
          >
            <CoraLogo />
            <Typography
              variant="h6"
              component="div"
              sx={{ fontWeight: "bold", color: "#fff" }}
            >
              Cora{" "}
              <Box component="span" sx={{ color: theme.palette.primary.main }}>
                AI
              </Box>
            </Typography>
          </Stack>
          <UserMenu />
        </Toolbar>
      </AppBar>
      <Box component="main" sx={{ flexGrow: 1, py: 4 }}>
        <Container maxWidth="lg">{children}</Container>
      </Box>
    </Box>
  );
};
