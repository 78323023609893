import { useState, useEffect, useCallback } from "react";
import type { FC } from "react";
import {
  Card,
  CardContent,
  Stack,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import {
  Refresh as RefreshIcon,
  Delete as DeleteIcon,
  Source as SourceIcon,
  Info as InfoIcon,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { EmptyState, ActionButton } from "./shared";
import {
  getGlassStyles,
  getButtonStyles,
  getInfoBoxStyles,
} from "../theme/shared";
import { alpha } from "@mui/material/styles";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

interface KnowledgeSource {
  id: string;
  source: string;
  type: string;
  status: string;
  processing_metadata?: {
    processed_at?: string;
    total_pages?: number;
  };
}

interface KnowledgeSourceListProps {
  orgId: string;
  onDelete?: (sourceId: string) => void;
  onRefresh?: (sourceId: string) => void;
  refreshTrigger?: number;
  onCreateSource: () => void;
}

// Initialize dayjs plugins
dayjs.extend(utc);
dayjs.extend(timezone);

// Helper function to get default timezone (copied from TaskListEditor.tsx)
const getDefaultTimezone = (): string => {
  try {
    // Try to get the browser's timezone
    const browserTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return browserTz;
  } catch (e) {
    console.warn("Could not detect browser timezone:", e);
  }
  // Fall back to UTC
  return "UTC";
};

const LoadingState: FC = () => {
  const theme = useTheme();
  return (
    <Stack spacing={2}>
      {[1, 2, 3].map((i) => (
        <Card
          key={i}
          sx={{
            p: 2,
            ...getGlassStyles(theme, { opacity: 0.5, borderRadius: 2 }),
          }}
        >
          <Stack spacing={1.5}>
            <Box
              sx={{
                width: "60%",
                height: 24,
                bgcolor: "grey.300",
                borderRadius: 1,
              }}
            />
            <Stack direction="row" spacing={1}>
              <Box
                sx={{
                  width: 80,
                  height: 24,
                  bgcolor: "grey.300",
                  borderRadius: 1,
                }}
              />
              <Box
                sx={{
                  width: 80,
                  height: 24,
                  bgcolor: "grey.300",
                  borderRadius: 1,
                }}
              />
            </Stack>
          </Stack>
        </Card>
      ))}
    </Stack>
  );
};

export const ProcessingMetadata: FC<{
  metadata: KnowledgeSource["processing_metadata"] | null;
}> = ({ metadata }) => {
  if (!metadata) return null;

  const hasProcessedDate = !!metadata.processed_at;
  const hasTotalPages = metadata.total_pages !== undefined;

  if (!hasProcessedDate && !hasTotalPages) return null;

  const processedDate = hasProcessedDate
    ? dayjs
        .utc(metadata.processed_at)
        .tz(getDefaultTimezone())
        .format("MMM D, YYYY h:mm A z")
    : null;
  const totalPages = hasTotalPages ? metadata.total_pages : null;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1.5,
        mt: 1,
        opacity: 0.7,
        fontSize: "0.875rem",
        color: "text.secondary",
        borderTop: "1px solid",
        borderColor: "divider",
        pt: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", gap: 1.5 }}>
        {totalPages !== null && (
          <Typography variant="caption">
            {totalPages} {totalPages === 1 ? "page" : "pages"} total
          </Typography>
        )}
        {totalPages !== null && processedDate && (
          <Typography
            variant="caption"
            sx={{ display: "flex", alignItems: "center" }}
          >
            •
          </Typography>
        )}
        {processedDate && (
          <Typography variant="caption">
            Last processed: {processedDate}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export const KnowledgeSourceList: FC<KnowledgeSourceListProps> = ({
  orgId,
  onDelete,
  onRefresh,
  refreshTrigger = 0,
}: KnowledgeSourceListProps) => {
  const theme = useTheme();
  const { getAccessTokenSilently } = useAuth0();
  const [knowledgeSources, setKnowledgeSources] = useState<KnowledgeSource[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [sourceToDelete, setSourceToDelete] = useState<KnowledgeSource | null>(
    null
  );

  const fetchKnowledgeSources = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/api/organizations/${orgId}/knowledge_sources`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) throw new Error("Failed to fetch knowledge sources");
      const data = await response.json();
      setKnowledgeSources(Array.isArray(data) ? data : data.data || []);
    } catch (error) {
      console.error("Error fetching knowledge sources:", error);
      setKnowledgeSources([]);
    } finally {
      setIsLoading(false);
    }
  }, [orgId, getAccessTokenSilently]);

  useEffect(() => {
    if (orgId) {
      setIsLoading(true);
      fetchKnowledgeSources();
    }
  }, [orgId, fetchKnowledgeSources, refreshTrigger]);

  const handleRefreshSource = async (sourceId: string): Promise<void> => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${
          import.meta.env.VITE_API_URL
        }/api/organizations/${orgId}/knowledge_sources/${sourceId}/refresh`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) throw new Error("Failed to refresh knowledge source");
      const updatedSource = await response.json();
      setKnowledgeSources((prevSources) =>
        prevSources.map((source) =>
          source.id === sourceId ? updatedSource : source
        )
      );
      if (onRefresh) {
        onRefresh(sourceId);
      }
    } catch (error) {
      console.error("Error refreshing knowledge source:", error);
    }
  };

  const handleDeleteClick = (source: KnowledgeSource): void => {
    setSourceToDelete(source);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!sourceToDelete) return;

    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${
          import.meta.env.VITE_API_URL
        }/api/organizations/${orgId}/knowledge_sources/${sourceToDelete.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) throw new Error("Failed to delete knowledge source");
      setKnowledgeSources(
        knowledgeSources.filter((source) => source.id !== sourceToDelete.id)
      );
      if (onDelete) {
        onDelete(sourceToDelete.id);
      }
      setDeleteDialogOpen(false);
      setSourceToDelete(null);
    } catch (error) {
      console.error("Error deleting knowledge source:", error);
    }
  };

  if (isLoading) {
    return <LoadingState />;
  }

  if (knowledgeSources.length === 0) {
    return (
      <EmptyState
        icon={<SourceIcon />}
        title="No Knowledge Sources"
        description="Add your first knowledge source using the button above. Knowledge sources help improve responses to customer inquiries."
      />
    );
  }

  return (
    <>
      <Stack spacing={2}>
        {knowledgeSources.map((source) => (
          <Card
            key={source.id}
            sx={getGlassStyles(theme, {
              hoverEffect: true,
              opacity: 0.3,
              borderRadius: 1,
            })}
          >
            <CardContent sx={{ py: 2 }}>
              <Stack spacing={1}>
                <Typography variant="body1" sx={{ wordBreak: "break-all" }}>
                  {source.source}
                </Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Box
                    sx={{
                      px: 1.5,
                      py: 0.5,
                      borderRadius: 1,
                      fontSize: "0.75rem",
                      backgroundColor: alpha(theme.palette.grey[500], 0.1),
                      color: theme.palette.grey[300],
                      border: `1px solid ${alpha(theme.palette.grey[500], 0.2)}`,
                    }}
                  >
                    {source.type}
                  </Box>
                  {source.status === "processing" ? (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                        px: 1.5,
                        py: 0.5,
                        borderRadius: 1,
                        fontSize: "0.75rem",
                        backgroundColor: alpha("#FF9800", 0.15),
                        color: "#FFC107",
                        border: `1px solid ${alpha("#FF9800", 0.3)}`,
                      }}
                    >
                      <Box component="span">Processing</Box>
                      <Box
                        component="span"
                        sx={{
                          width: 6,
                          height: 6,
                          borderRadius: "50%",
                          backgroundColor: "currentColor",
                          animation: "pulse 1.5s infinite",
                          "@keyframes pulse": {
                            "0%": { opacity: 1 },
                            "50%": { opacity: 0.4 },
                            "100%": { opacity: 1 },
                          },
                        }}
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        px: 1.5,
                        py: 0.5,
                        borderRadius: 1,
                        fontSize: "0.75rem",
                        backgroundColor: alpha("#4CAF50", 0.15),
                        color: "#81C784",
                        border: `1px solid ${alpha("#4CAF50", 0.3)}`,
                      }}
                    >
                      Processed
                    </Box>
                  )}
                  <Box flex={1} />
                  <ActionButton
                    icon={<RefreshIcon />}
                    onClick={() => handleRefreshSource(source.id)}
                    tooltip="Refresh source"
                  />
                  <ActionButton
                    icon={<DeleteIcon />}
                    onClick={() => handleDeleteClick(source)}
                    color={theme.palette.error.main}
                    tooltip="Delete source"
                  />
                </Stack>
                <ProcessingMetadata metadata={source.processing_metadata} />
              </Stack>
            </CardContent>
          </Card>
        ))}
      </Stack>

      <Dialog
        open={deleteDialogOpen}
        onClose={() => {
          setDeleteDialogOpen(false);
        }}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: getGlassStyles(theme, { opacity: 0.9, borderRadius: 2 }),
        }}
      >
        <DialogTitle sx={{ pb: 1 }}>
          <Typography variant="h6" fontWeight="bold">
            Confirm Delete
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography variant="body1" paragraph>
            Are you sure you want to delete this knowledge source?
          </Typography>
          <Typography
            variant="subtitle2"
            sx={getGlassStyles(theme, { opacity: 0.5, borderRadius: 1 })}
          >
            {sourceToDelete?.source}
          </Typography>
          <Box sx={getInfoBoxStyles(theme)}>
            <InfoIcon fontSize="small" />
            <Typography variant="body2" color="error">
              This action cannot be undone.
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ px: 3, py: 2 }}>
          <Button
            onClick={() => {
              setDeleteDialogOpen(false);
            }}
            color="inherit"
          >
            Cancel
          </Button>
          <Button
            onClick={handleDeleteConfirm}
            variant="contained"
            color="error"
            sx={getButtonStyles(theme)}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
