import {
  BooleanInput,
  Create,
  Edit,
  SimpleForm,
  TextInput,
  required,
  email,
  useRecordContext,
  useNotify,
  useRedirect,
  useResourceContext,
  useUpdate,
  useCreate,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  ReferenceInput,
  useSimpleFormIterator,
  useSimpleFormIteratorItem,
} from "react-admin";
import { Box, Stack, Typography, Button } from "@mui/material";

interface UserAssociationResponse {
  organization_id: string | null;
  organization_name: string | null;
  role_name: string;
}

interface UserResponse {
  id: string;
  email: string;
  name?: string;
  auth0_user_id?: string;
  slack_user_id?: string;
  ms_teams_user_id?: string;
  created_at: string;
  updated_at: string;
  associations: UserAssociationResponse[];
}

interface UserFormValues {
  id?: string;
  name?: string;
  email: string;
  auth0_user_id?: string;
  slack_user_id?: string;
  ms_teams_user_id?: string;
  is_admin?: boolean;
  associations: Array<{
    organization_id: string | null;
    role_name: string;
  }>;
}

const ROLE_CHOICES = [
  { id: "account_owner", name: "Account Owner" },
  { id: "manager", name: "Manager" },
  { id: "member", name: "Member" },
];

const UserTitle = () => {
  const record = useRecordContext<UserResponse>();
  return record ? <span>User: {record.name || record.email}</span> : null;
};

interface ResourceProps {
  resource?: string;
}

// Add both button components at the top level
const AddRoleButton = () => {
  const { add } = useSimpleFormIterator();
  return (
    <Button size="small" variant="text" onClick={() => add()}>
      Add Role
    </Button>
  );
};

const RemoveRoleButton = () => {
  const { remove } = useSimpleFormIteratorItem();
  return (
    <Button
      size="small"
      color="error"
      variant="text"
      onClick={() => remove()}
      sx={{
        mt: 1,
        ml: 2,
      }}
    >
      Remove
    </Button>
  );
};

export const UserForm = () => {
  const record = useRecordContext<UserResponse>();
  const notify = useNotify();
  const redirect = useRedirect();
  const resource = useResourceContext();
  const [update] = useUpdate();
  const [create] = useCreate();

  // Transform API response to form values
  const transformToFormValues = (record: UserResponse): UserFormValues => {
    const associations = record.associations || [];
    const isAdmin = associations.some(
      (assoc) => assoc.role_name === "admin" && !assoc.organization_id
    );

    return {
      id: record.id,
      email: record.email || "",
      name: record.name || "",
      auth0_user_id: record.auth0_user_id || "",
      slack_user_id: record.slack_user_id || "",
      ms_teams_user_id: record.ms_teams_user_id || "",
      is_admin: isAdmin,
      // Only include non-admin associations in the form
      associations: associations.filter((assoc) => assoc.role_name !== "admin"),
    };
  };

  const defaultValues = record
    ? transformToFormValues(record)
    : {
        email: "",
        name: "",
        auth0_user_id: "",
        slack_user_id: "",
        ms_teams_user_id: "",
        is_admin: false,
        associations: [],
      };

  const handleSubmit = async (values: any) => {
    try {
      // Transform form values back to API format
      const submitData = {
        email: values.email,
        name: values.name,
        auth0_user_id: values.auth0_user_id,
        slack_user_id: values.slack_user_id,
        ms_teams_user_id: values.ms_teams_user_id,
        associations: [
          // Add admin role if is_admin is true
          ...(values.is_admin
            ? [{ role_name: "admin", organization_id: null }]
            : []),
          // Add other roles (ensure it's an array)
          ...(Array.isArray(values.associations) ? values.associations : []),
        ],
      };

      if (record && record.id) {
        await update(
          "users",
          {
            id: record.id,
            data: submitData,
          },
          {
            onSuccess: () => {
              notify("User updated", { type: "success" });
              redirect("list", resource);
            },
            onError: (error: unknown) => {
              const message =
                error instanceof Error ? error.message : "An error occurred";
              notify(message, { type: "error" });
            },
          }
        );
      } else {
        await create(
          "users",
          {
            data: submitData,
          },
          {
            onSuccess: () => {
              notify("User created", { type: "success" });
              redirect("list", resource);
            },
            onError: (error: unknown) => {
              const message =
                error instanceof Error ? error.message : "An error occurred";
              notify(message, { type: "error" });
            },
          }
        );
      }
    } catch (error) {
      notify("Error saving user", { type: "error" });
    }
  };

  return (
    <SimpleForm onSubmit={handleSubmit} defaultValues={defaultValues}>
      <Box>
        <Stack spacing={2}>
          <TextInput source="name" fullWidth />
          <TextInput
            source="email"
            validate={[required(), email()]}
            fullWidth
          />
          <TextInput source="auth0_user_id" fullWidth />
          <TextInput source="slack_user_id" fullWidth />
          <TextInput source="ms_teams_user_id" fullWidth />

          <BooleanInput
            source="is_admin"
            label="Grant Global Admin Access"
            helperText="Global admins have unrestricted access to all organizations and features"
          />

          <Box>
            <Typography variant="h6" gutterBottom>
              Roles
            </Typography>
            <ArrayInput source="associations" label={false}>
              <SimpleFormIterator
                inline
                disableReordering
                disableClear
                addButton={<AddRoleButton />}
                removeButton={<RemoveRoleButton />}
                sx={{
                  "& .RaSimpleFormIterator-line": {
                    marginTop: 2,
                    marginBottom: 2,
                  },
                }}
              >
                <SelectInput
                  source="role_name"
                  choices={ROLE_CHOICES}
                  label="Role"
                  sx={{ minWidth: 150 }}
                />
                <ReferenceInput
                  source="organization_id"
                  reference="organizations"
                >
                  <SelectInput optionText="name" label="Organization" />
                </ReferenceInput>
              </SimpleFormIterator>
            </ArrayInput>
          </Box>
        </Stack>
      </Box>
    </SimpleForm>
  );
};

export const UserCreate = ({ resource = "users" }: ResourceProps) => (
  <Create redirect="list" resource={resource}>
    <UserForm />
  </Create>
);

export const UserEdit = ({ resource = "users" }: ResourceProps) => (
  <Edit title={<UserTitle />} resource={resource}>
    <UserForm />
  </Edit>
);
