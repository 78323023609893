import { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  IconButton,
  Typography,
  alpha,
  Snackbar,
  Alert,
  CircularProgress,
  Button,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { CustomWorkflowEditor } from "../components/CustomWorkflowEditor";
import type { EditorWorkflow } from "../types/workflow";
import { availableActions } from "../constants/availableActions";
import { useDataProvider } from "../dataProviders/dataProvider";

export const CreateWorkflow = () => {
  const navigate = useNavigate();
  const { orgId } = useParams<{ orgId: string }>();
  const [organization, setOrganization] = useState<any>(null);
  const [organizationSlackChannelName, setOrganizationSlackChannelName] =
    useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [notification, setNotification] = useState<{
    message: string;
    severity: "success" | "error";
    open: boolean;
  }>({ message: "", severity: "success", open: false });
  const dataProvider = useDataProvider();

  const loadData = useCallback(async () => {
    if (!orgId) {
      setIsLoading(false);
      return;
    }

    try {
      const organizationResponse = await dataProvider.getOne("organizations", {
        id: orgId,
      });
      const organization = organizationResponse.data;
      setOrganization(organization);
      if (organization?.slack_channel_id) {
        try {
          // As this is cosmetic, we don't want to block the workflow creation if fails.
          const slackChannelResponse = await dataProvider.getOne(
            `organizations/${orgId}/slack/channels/${organization.slack_channel_id}`,
            {
              id: organization.slack_channel_id,
            }
          );
          setOrganizationSlackChannelName(
            slackChannelResponse.data["channel_name"]
          );
        } catch (err) {
          console.error("Failed to load Slack channel:", err);
          // Fallback to using the channel ID as the name
          setOrganizationSlackChannelName(organization.slack_channel_id);
        }
      }
    } catch (err) {
      console.error("Failed to load data:", err);
      setError("Failed to load organization data");
      setNotification({
        message: "Failed to load organization data",
        severity: "error",
        open: true,
      });
    } finally {
      setIsLoading(false);
    }
  }, [orgId, dataProvider]);

  useEffect(() => {
    loadData();
  }, [orgId, loadData]);

  const handleSave = async (workflow: EditorWorkflow) => {
    if (!orgId) return;
    try {
      await dataProvider.create(`organizations/${orgId}/workflows`, {
        data: {
          organization_id: orgId,
          name: workflow.name,
          description: workflow.description,
          trigger_event: workflow.trigger_event,
          workflow: {
            actions: workflow.actions,
          },
          enabled: true,
        },
      });
      setNotification({
        message: "Workflow created successfully",
        severity: "success",
        open: true,
      });
      navigate(`/manager/organizations/${orgId}?tab=1`);
    } catch (error) {
      console.error("Failed to create workflow:", error);
      setNotification({
        message: "Failed to create workflow",
        severity: "error",
        open: true,
      });
    }
  };

  const handleDelete = () => {
    navigate(`/manager/organizations/${orgId}?tab=1`);
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          gap: 2,
        }}
      >
        <Alert severity="error">{error}</Alert>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
        >
          Go Back
        </Button>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        color: "#F8FAFC",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          gap: 2,
          borderBottom: `1px solid ${alpha("#fff", 0.05)}`,
        }}
      >
        <IconButton
          onClick={() => {
            navigate(`/manager/organizations/${orgId}?tab=1`);
          }}
          color="inherit"
          sx={{ color: "white" }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ flexGrow: 1, color: "white" }}
        >
          Create Workflow
        </Typography>
      </Box>

      {/* Main Content Area */}
      <Box sx={{ flex: 1, position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          <CustomWorkflowEditor
            initialWorkflow={{
              organization_id: orgId,
              name: "Untitled workflow",
              description: "",
              trigger_event: {
                type: "custom_event",
                event: { name: "blog-post.updated" },
              },
              actions: [],
            }}
            availableActions={availableActions}
            onSave={handleSave}
            onDelete={handleDelete}
            isCustomerOrg={organization?.customer_of_id !== null}
            organizationSlackChannelName={
              organizationSlackChannelName || undefined
            }
          />
        </Box>
      </Box>

      {/* Notifications */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => {
          setNotification((prev) => ({ ...prev, open: false }));
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setNotification((prev) => ({ ...prev, open: false }));
          }}
          severity={notification.severity}
          sx={{ width: "100%" }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
