import type { ReactNode } from "react";
import { createContext, useState } from "react";

interface Association {
  organization_id: string;
  organization_name: string;
  role: string;
}

interface UserContextType {
  userId: string | null;
  setUserId: (id: string | null) => void;
  associations: Association[];
  setAssociations: (associations: Association[]) => void;
  isAdmin: boolean;
  setIsAdmin: (isAdmin: boolean) => void;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [userId, setUserId] = useState<string | null>(null);
  const [associations, setAssociations] = useState<Association[]>([]);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);

  return (
    <UserContext.Provider
      value={{
        userId,
        setUserId,
        associations,
        setAssociations,
        isAdmin,
        setIsAdmin,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export { UserContext };
