import { Box } from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import { keyframes } from "@mui/system";

export const CoraLogo = () => {
  const theme = useTheme();

  const pulse = keyframes`
    0% { box-shadow: 0 0 20px ${alpha(theme.palette.primary.main, 0.5)}, inset 0 0 10px ${alpha(theme.palette.primary.main, 0.3)}; }
    50% { box-shadow: 0 0 25px ${alpha(theme.palette.primary.main, 0.7)}, inset 0 0 15px ${alpha(theme.palette.primary.main, 0.5)}; }
    100% { box-shadow: 0 0 20px ${alpha(theme.palette.primary.main, 0.5)}, inset 0 0 10px ${alpha(theme.palette.primary.main, 0.3)}; }
  `;

  return (
    <Box
      sx={{
        width: 24,
        height: 24,
        borderRadius: "50%",
        background: `radial-gradient(circle at center, ${alpha(
          theme.palette.primary.main,
          0.9
        )} 0%, ${alpha(theme.palette.primary.main, 0.3)} 60%, transparent 70%)`,
        boxShadow: `0 0 20px ${alpha(theme.palette.primary.main, 0.5)},
                   inset 0 0 10px ${alpha(theme.palette.primary.main, 0.3)}`,
        animation: `${pulse} 2s infinite`,
      }}
    />
  );
};
