import type { AuthProvider } from "ra-core";
import { useAuth0 } from "@auth0/auth0-react";
import { useMemo } from "react";

export const useAuthProvider = (): AuthProvider => {
  const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();

  return useMemo(
    () => ({
      login: async () => {
        await loginWithRedirect();
        return Promise.resolve();
      },

      logout: async () => {
        await logout({ logoutParams: { returnTo: window.location.origin } });
        return Promise.resolve();
      },

      checkError: async (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
          return Promise.reject();
        }
        return Promise.resolve();
      },

      checkAuth: async () => {
        if (!isAuthenticated) {
          return Promise.reject();
        }
        return Promise.resolve();
      },

      getPermissions: async () => {
        // You can implement custom permission logic here
        // For example, you could check user roles from Auth0
        return Promise.resolve();
      },

      getIdentity: async () => {
        if (!user) {
          return Promise.reject();
        }

        return Promise.resolve({
          id: user.sub || "",
          fullName: user.name || "",
          avatar: user.picture || "",
        });
      },

      handleCallback: async () => {
        // This is called after the user is redirected back from Auth0
        // You can use this to handle any post-login tasks
        return Promise.resolve();
      },
    }),
    [loginWithRedirect, logout, isAuthenticated, user]
  );
};
