import { useAuth0 } from "@auth0/auth0-react";
import { Box, Stack, Typography } from "@mui/material";
import { AutoAwesome } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

export const CustomerDashboard = () => {
  const { user } = useAuth0();
  const theme = useTheme();

  return (
    <Box>
      {/* Welcome Section */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <AutoAwesome
            sx={{ color: theme.palette.primary.main, fontSize: 32 }}
          />
          <div>
            <Typography variant="h4" fontWeight="bold" gutterBottom>
              {user?.given_name ? `Welcome, ${user.given_name}!` : "Welcome!"}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              I'm here to help you with your customer success needs.
            </Typography>
          </div>
        </Stack>
      </Stack>

      {/* Additional content can be added here */}
    </Box>
  );
};
