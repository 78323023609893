import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Grid,
  Card,
  CardContent,
  Typography,
  IconButton,
  Box,
  CircularProgress,
  useTheme,
  alpha,
  TextField,
  Button,
  Divider,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";
import {
  workflowTemplates,
  generateWorkflow,
} from "../dataProviders/mockWorkflowApi";
import type { EditorWorkflow } from "../types/workflow";
import { getGlassStyles, getButtonStyles } from "../theme/shared";
import { useNavigate } from "react-router-dom";

const TEMPLATE_ICONS: Record<string, React.ReactElement> = {
  calendar_month: <CalendarMonthIcon />,
  schedule: <ScheduleIcon />,
};

interface WorkflowTemplateModalProps {
  open: boolean;
  onClose: () => void;
  onTemplateSelect: (workflow: EditorWorkflow) => void;
  orgId: string;
}

export function WorkflowTemplateModal({
  open,
  onClose,
  onTemplateSelect,
  orgId,
}: WorkflowTemplateModalProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [loading, setLoading] = useState<string | null>(null);
  const [customPrompt, setCustomPrompt] = useState("");

  const handleTemplateSelect = async (templateId: string) => {
    try {
      setLoading(templateId);
      const workflow = await generateWorkflow(templateId);
      workflow.organization_id = orgId;
      onTemplateSelect(workflow);
    } catch (error) {
      console.error("Failed to generate workflow:", error);
    } finally {
      setLoading(null);
    }
  };

  const handleCustomPrompt = async () => {
    if (!customPrompt.trim()) return;
    try {
      // For now, we'll use the monthly-checkin template as a mock
      // In the real implementation, this would send the custom prompt to the AI
      const workflow = await generateWorkflow("monthly-checkin");
      workflow.organization_id = orgId;
      workflow.name = "Custom Workflow";
      workflow.description = customPrompt;
      onTemplateSelect(workflow);
    } catch (error) {
      console.error("Failed to generate custom workflow:", error);
    }
  };

  const handleStartEmpty = () => {
    navigate(`/manager/organizations/${orgId}/workflows/create`);
    onClose(); // Close the modal after navigation
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          background: theme.palette.background.default,
          backgroundImage: "none",
        },
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h6" component="div">
          Create New Workflow
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Stack spacing={4}>
          {/* Custom Prompt Section */}
          <Box sx={{ position: "relative" }}>
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                bgcolor: alpha(theme.palette.background.paper, 0.3),
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  px: 2,
                  py: 0.5,
                  bgcolor: alpha(theme.palette.background.paper, 0.8),
                  borderRadius: 1,
                  border: `1px solid ${alpha("#fff", 0.1)}`,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.secondary",
                    fontWeight: "medium",
                  }}
                >
                  Coming Soon
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography
                variant="subtitle1"
                gutterBottom
                sx={{ display: "flex", alignItems: "center", gap: 1 }}
              >
                <AutoAwesomeIcon color="primary" />
                Describe Your Workflow
              </Typography>
              <Box sx={{ display: "flex", gap: 1 }}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  placeholder="Describe what you want your workflow to do..."
                  value={customPrompt}
                  onChange={(e) => setCustomPrompt(e.target.value)}
                  disabled
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: alpha(
                        theme.palette.background.paper,
                        0.1
                      ),
                    },
                  }}
                />
                <Button
                  variant="contained"
                  onClick={handleCustomPrompt}
                  disabled
                  sx={{ ...getButtonStyles(theme), minWidth: 120 }}
                >
                  Generate
                </Button>
              </Box>
            </Box>
          </Box>

          <Divider>
            <Typography variant="body2" color="text.secondary">
              or choose a template
            </Typography>
          </Divider>

          {/* Templates Grid */}
          <Grid container spacing={1.5}>
            {/* Build from Scratch Card */}
            <Grid item xs={4}>
              <Card
                onClick={handleStartEmpty}
                sx={{
                  ...getGlassStyles(theme),
                  cursor: "pointer",
                  height: "100%",
                  "&:hover": {
                    transform: "translateY(-4px)",
                    transition: "transform 0.2s ease-in-out",
                  },
                }}
              >
                <CardContent sx={{ p: 2 }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 1,
                      height: 32,
                    }}
                  >
                    <Box
                      sx={{
                        fontSize: 24,
                        mr: 1,
                        color: theme.palette.primary.main,
                        display: "flex",
                        flexShrink: 1,
                        "& > svg": {
                          fontSize: "inherit",
                        },
                      }}
                    >
                      <AddIcon />
                    </Box>
                    <Typography
                      variant="subtitle1"
                      component="div"
                      sx={{
                        fontSize: {
                          xs: "0.9rem",
                          sm: "1rem",
                        },
                        lineHeight: 1.2,
                      }}
                    >
                      Build from Scratch
                    </Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      minHeight: 40,
                    }}
                  >
                    Start with a blank workflow and build it your way
                  </Typography>
                </CardContent>
              </Card>
            </Grid>

            {/* Template Cards */}
            {workflowTemplates.map((template) => (
              <Grid item xs={4} key={template.id}>
                <Card
                  sx={{
                    ...getGlassStyles(theme),
                    cursor: "pointer",
                    height: "100%",
                    "&:hover": {
                      transform: "translateY(-4px)",
                      transition: "transform 0.2s ease-in-out",
                    },
                  }}
                  onClick={() => handleTemplateSelect(template.id)}
                >
                  <CardContent sx={{ p: 2 }}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 1,
                        height: 32,
                        position: "relative",
                      }}
                    >
                      <Box
                        sx={{
                          fontSize: 24,
                          mr: 1,
                          color: theme.palette.primary.main,
                          display: "flex",
                          flexShrink: 0,
                          "& > svg": {
                            fontSize: "inherit",
                          },
                        }}
                      >
                        {TEMPLATE_ICONS[template.icon]}
                      </Box>
                      <Typography
                        variant="subtitle1"
                        component="div"
                        sx={{
                          fontSize: {
                            xs: "0.9rem",
                            sm: "1rem",
                          },
                          lineHeight: 1.2,
                        }}
                      >
                        {template.name}
                      </Typography>
                      {loading === template.id && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            backgroundColor: alpha(
                              theme.palette.background.paper,
                              0.7
                            ),
                          }}
                        >
                          <CircularProgress size={24} />
                        </Box>
                      )}
                    </Box>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        minHeight: 40,
                      }}
                    >
                      {template.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
