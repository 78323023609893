/// <reference types="vite/client" />

import { useEffect, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { formatDistanceToNow } from "date-fns";
import {
  Box,
  Button,
  Typography,
  Paper,
  Grid,
  Stack,
  Avatar,
  Chip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";
import {
  Message,
  Notifications,
  AutoAwesome,
  People,
  TrendingUp,
  Chat as ChatIcon,
  SwitchAccount,
} from "@mui/icons-material";
import { alpha, useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { useUser } from "../hooks/useUser";

interface Organization {
  id: string;
  name: string;
  slug: string;
  platform: string;
  email_domain: string | null;
  customer_of_id: string | null;
  slack_app_id: string | null;
  slack_team_id: string | null;
  slack_channel_id: string | null;
  ms_teams_tenant_id: string | null;
  platform_data: Record<string, any>;
  custom_prompt: string | null;
  created_at: string;
  updated_at: string;
  last_activity: string | null;
  total_users: number;
  total_conversations: number;
}

interface MetricCardProps {
  title: string;
  value: number;
  icon: React.ReactNode;
  color: string;
  isComingSoon?: boolean;
}

const LoadingState = () => {
  const theme = useTheme();
  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
        sx={{ opacity: 0.7 }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <Box
            sx={{ width: 32, height: 32, borderRadius: 1, bgcolor: "grey.300" }}
          />
          <div>
            <Box
              sx={{
                width: 200,
                height: 24,
                borderRadius: 1,
                bgcolor: "grey.300",
                mb: 1,
              }}
            />
            <Box
              sx={{
                width: 300,
                height: 20,
                borderRadius: 1,
                bgcolor: "grey.300",
              }}
            />
          </div>
        </Stack>
      </Stack>
      <Grid container spacing={3} mb={4}>
        {[1, 2, 3, 4].map((i) => (
          <Grid item xs={12} sm={6} md={3} key={i}>
            <Paper
              sx={{
                p: 3,
                height: "100%",
                backgroundColor: alpha(theme.palette.background.paper, 0.8),
                borderRadius: 3,
              }}
            >
              <Stack spacing={2}>
                <Box
                  sx={{
                    width: 40,
                    height: 40,
                    borderRadius: 1,
                    bgcolor: "grey.300",
                  }}
                />
                <Box
                  sx={{
                    width: "60%",
                    height: 32,
                    borderRadius: 1,
                    bgcolor: "grey.300",
                  }}
                />
                <Box
                  sx={{
                    width: "40%",
                    height: 20,
                    borderRadius: 1,
                    bgcolor: "grey.300",
                  }}
                />
              </Stack>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const MetricCard = ({
  title,
  value,
  icon,
  color,
  isComingSoon = false,
}: MetricCardProps) => {
  const theme = useTheme();

  return (
    <Paper
      sx={{
        p: 3,
        height: "100%",
        backgroundColor: alpha(theme.palette.background.paper, 0.8),
        backdropFilter: "blur(10px)",
        border: `1px solid ${alpha("#fff", 0.05)}`,
        borderRadius: 3,
        transition: "all 0.2s ease-in-out",
        position: "relative",
        opacity: isComingSoon ? 0.85 : 1,
        "&:hover": {
          transform: "translateY(-2px)",
          boxShadow: `0 8px 24px ${alpha("#000", 0.15)}`,
        },
      }}
    >
      {isComingSoon && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: alpha(theme.palette.background.paper, 0.4),
            borderRadius: 3,
            zIndex: 1,
          }}
        >
          <Chip
            label="Coming Soon"
            sx={{
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
              color: theme.palette.primary.main,
              fontWeight: "bold",
            }}
          />
        </Box>
      )}
      <Stack spacing={2} sx={{ filter: isComingSoon ? "blur(0.5px)" : "none" }}>
        <Box
          sx={{
            p: 1,
            borderRadius: 2,
            backgroundColor: alpha(color, 0.1),
            color: color,
            width: "fit-content",
          }}
        >
          {icon}
        </Box>
        <div>
          <Typography variant="h3" fontWeight="bold" gutterBottom>
            {value}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {title}
          </Typography>
        </div>
      </Stack>
    </Paper>
  );
};

const OrganizationCard = ({ org }: { org: Organization }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { associations: userAssociations } = useUser();
  const isUserOrg = userAssociations.some(
    (userAssociation) => userAssociation.organization_id === org.id
  );

  const getLastActiveText = (lastActivity: string | null) => {
    if (!lastActivity) return "Never active";
    try {
      return `Active ${formatDistanceToNow(new Date(lastActivity))} ago`;
    } catch (error) {
      return "Activity status unknown";
    }
  };

  // Generate consistent avatar color based on org name
  const getAvatarColor = (name: string) => {
    const colors = [
      theme.palette.primary.main,
      theme.palette.secondary.main,
      theme.palette.success.main,
      theme.palette.info.main,
    ];
    const index =
      name.split("").reduce((acc, char) => acc + char.charCodeAt(0), 0) %
      colors.length;
    return colors[index];
  };

  return (
    <Paper
      sx={{
        p: 2,
        mb: 2,
        backgroundColor: isUserOrg
          ? alpha(theme.palette.primary.main, 0.05)
          : alpha(theme.palette.background.paper, 0.8),
        backdropFilter: "blur(10px)",
        border: `1px solid ${alpha("#fff", 0.05)}`,
        borderRadius: 2,
        transition: "all 0.2s ease-in-out",
        "&:hover": {
          transform: "translateY(-2px)",
          boxShadow: `0 8px 24px ${alpha("#000", 0.15)}`,
        },
      }}
    >
      <Stack spacing={2}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar sx={{ bgcolor: getAvatarColor(org.name) }}>
              {org.name.charAt(0)}
            </Avatar>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="subtitle1" fontWeight="bold">
                {org.name}
              </Typography>
              {isUserOrg && (
                <Chip
                  label="Your Org"
                  size="small"
                  sx={{
                    backgroundColor: alpha(theme.palette.primary.main, 0.1),
                    color: theme.palette.primary.main,
                    fontWeight: "medium",
                    height: "20px",
                  }}
                />
              )}
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <ChatIcon fontSize="small" color="action" />
            <Typography variant="caption" color="text.secondary">
              {getLastActiveText(org.last_activity)}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="row" spacing={3} alignItems="center">
          <div>
            <Typography variant="caption" color="text.secondary">
              Total Users
            </Typography>
            <Typography variant="subtitle2" fontWeight="bold">
              {org.total_users}
            </Typography>
          </div>
          <div>
            <Typography variant="caption" color="text.secondary">
              Conversations
            </Typography>
            <Typography variant="subtitle2" fontWeight="bold">
              {org.total_conversations}
            </Typography>
          </div>
          <Box flex={1} />
          <Button
            variant="outlined"
            size="small"
            sx={{ borderRadius: 2 }}
            onClick={() => {
              navigate(`/manager/organizations/${org.id}`);
            }}
          >
            View Details
          </Button>
        </Stack>
      </Stack>
    </Paper>
  );
};

const TenantOrganizationCard = ({
  org,
  customerCount,
}: {
  org: Organization;
  customerCount: number;
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isAdmin, isManagerOfOrganization } = useUser();

  const hasAccessToViewDetails = isAdmin || isManagerOfOrganization(org.id);

  const getLastActiveText = (lastActivity: string | null) => {
    if (!lastActivity) return "Never active";
    try {
      return `Active ${formatDistanceToNow(new Date(lastActivity))} ago`;
    } catch (error) {
      return "Activity status unknown";
    }
  };

  return (
    <Paper
      sx={{
        p: 3,
        backgroundColor: alpha(theme.palette.primary.main, 0.05),
        backdropFilter: "blur(10px)",
        border: `1px solid ${alpha("#fff", 0.05)}`,
        borderRadius: 3,
        mb: 4,
      }}
    >
      <Stack spacing={3}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={2} alignItems="center">
            <Avatar
              sx={{
                bgcolor: theme.palette.primary.main,
                width: 48,
                height: 48,
              }}
            >
              {org.name.charAt(0)}
            </Avatar>
            <div>
              <Typography variant="h6" fontWeight="bold" gutterBottom>
                {org.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Your Organization
              </Typography>
            </div>
          </Stack>
          <Stack direction="row" spacing={1} alignItems="center">
            <ChatIcon fontSize="small" color="action" />
            <Typography variant="caption" color="text.secondary">
              {getLastActiveText(org.last_activity)}
            </Typography>
          </Stack>
        </Stack>

        <Stack direction="row" spacing={3} alignItems="center">
          <div>
            <Typography variant="caption" color="text.secondary">
              Total Users
            </Typography>
            <Typography variant="h6" fontWeight="bold">
              {org.total_users}
            </Typography>
          </div>
          <div>
            <Typography variant="caption" color="text.secondary">
              Total Customers
            </Typography>
            <Typography variant="h6" fontWeight="bold">
              {customerCount}
            </Typography>
          </div>
          <div>
            <Typography variant="caption" color="text.secondary">
              Total Conversations
            </Typography>
            <Typography variant="h6" fontWeight="bold">
              {org.total_conversations}
            </Typography>
          </div>
          <Box flex={1} />
          {hasAccessToViewDetails && (
            <Button
              variant="outlined"
              size="small"
              sx={{ borderRadius: 2 }}
              onClick={() => {
                navigate(`/manager/organizations/${org.id}`);
              }}
            >
              View Details
            </Button>
          )}
        </Stack>
      </Stack>
    </Paper>
  );
};

export const ManagerDashboard = () => {
  const { user } = useAuth0();
  const { getAccessTokenSilently } = useAuth0();
  const theme = useTheme();
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentTenantId, setCurrentTenantId] = useState<string | null>(null);
  const [tenantOrgs, setTenantOrgs] = useState<Organization[]>([]);
  const [filteredCustomerOrgs, setFilteredCustomerOrgs] = useState<
    Organization[]
  >([]);
  const { isAdmin } = useUser();

  const fetchOrganizations = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${import.meta.env.VITE_API_URL}/api/organizations`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) throw new Error("Failed to fetch organizations");
      const data = await response.json();
      setOrganizations(data.data || []);

      // Separate tenant and customer orgs
      const tenants = (data.data || []).filter(
        (org: Organization) => org.customer_of_id === null
      );
      setTenantOrgs(tenants);

      // Check for saved tenant id in localStorage
      const savedTenantId = localStorage.getItem("selectedTenantId");

      // Set tenant - use saved one if it exists and is valid
      if (
        savedTenantId &&
        tenants.some((org: Organization) => org.id === savedTenantId)
      ) {
        setCurrentTenantId(savedTenantId);
      }
      // Otherwise set default tenant if we have one and no tenant is currently selected
      else if (tenants.length > 0 && !currentTenantId) {
        setCurrentTenantId(tenants[0].id);
      }
    } catch (error) {
      console.error("Error fetching organizations:", error);
    } finally {
      setIsLoading(false);
    }
  }, [getAccessTokenSilently, currentTenantId]);

  // Filter customer orgs based on selected tenant
  useEffect(() => {
    if (organizations.length > 0 && currentTenantId) {
      const customerOrgs = organizations.filter(
        (org) => org.customer_of_id === currentTenantId
      );
      setFilteredCustomerOrgs(customerOrgs);
    }
  }, [organizations, currentTenantId]);

  useEffect(() => {
    fetchOrganizations();
  }, [fetchOrganizations]);

  const handleTenantChange = (event: SelectChangeEvent) => {
    const newTenantId = event.target.value;
    setCurrentTenantId(newTenantId);

    // Save selected tenant ID to localStorage
    if (newTenantId) {
      localStorage.setItem("selectedTenantId", newTenantId);
    } else {
      localStorage.removeItem("selectedTenantId");
    }
  };

  if (isLoading) {
    return <LoadingState />;
  }

  const currentTenantOrg =
    tenantOrgs.find((org) => org.id === currentTenantId) || null;

  return (
    <Box>
      {/* Welcome Section */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        mb={4}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <AutoAwesome
            sx={{ color: theme.palette.primary.main, fontSize: 32 }}
          />
          <div>
            <Typography variant="h4" fontWeight="bold" gutterBottom>
              {user?.given_name ? `Welcome, ${user.given_name}!` : "Welcome!"}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              I'm helping your team deliver customer success. Here's an overview
              of {filteredCustomerOrgs.length} customer organizations.
            </Typography>
          </div>
        </Stack>
        {isAdmin && (
          <FormControl variant="outlined" size="small" sx={{ minWidth: 220 }}>
            <InputLabel id="tenant-org-select-label">
              Switch Organization
            </InputLabel>
            <Select
              labelId="tenant-org-select-label"
              value={currentTenantId || ""}
              onChange={handleTenantChange}
              label="Switch Organization"
              startAdornment={
                <SwitchAccount sx={{ color: "action.active", mr: 1 }} />
              }
            >
              {tenantOrgs.map((org) => (
                <MenuItem key={org.id} value={org.id}>
                  {org.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Stack>

      {/* Metrics Grid */}
      <Grid container spacing={3} mb={4}>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            title="Customer Organizations"
            value={filteredCustomerOrgs.length}
            icon={<People />}
            color={theme.palette.primary.main}
            isComingSoon={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            title="Total Users"
            value={
              filteredCustomerOrgs.reduce(
                (sum: number, org: Organization) => sum + org.total_users,
                0
              ) + (currentTenantOrg?.total_users || 0)
            }
            icon={<Message />}
            color={theme.palette.success.main}
            isComingSoon={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            title="Total Conversations"
            value={
              filteredCustomerOrgs.reduce(
                (sum: number, org: Organization) =>
                  sum + org.total_conversations,
                0
              ) + (currentTenantOrg?.total_conversations || 0)
            }
            icon={<TrendingUp />}
            color={theme.palette.info.main}
            isComingSoon={false}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <MetricCard
            title="Active Organizations"
            value={
              filteredCustomerOrgs.filter(
                (org: Organization) => org.last_activity
              ).length + (currentTenantOrg?.last_activity ? 1 : 0)
            }
            icon={<Notifications />}
            color={theme.palette.warning.main}
            isComingSoon={false}
          />
        </Grid>
      </Grid>

      {/* Main Content */}
      <Box>
        {/* Tenant Organization Section */}
        {currentTenantOrg && (
          <TenantOrganizationCard
            org={currentTenantOrg}
            customerCount={filteredCustomerOrgs.length}
          />
        )}

        {/* Customer Organizations Section */}
        <Paper
          sx={{
            p: 3,
            backgroundColor: alpha(theme.palette.background.paper, 0.8),
            backdropFilter: "blur(10px)",
            border: `1px solid ${alpha("#fff", 0.05)}`,
            borderRadius: 3,
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={3}
          >
            <Typography variant="h6" fontWeight="bold">
              Customer Organizations
            </Typography>
          </Stack>
          <Stack spacing={2}>
            {filteredCustomerOrgs.length === 0 ? (
              <Box
                sx={{
                  py: 6,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 2,
                  color: "text.secondary",
                  textAlign: "center",
                }}
              >
                <People sx={{ fontSize: 48, opacity: 0.5 }} />
                <div>
                  <Typography variant="h6" gutterBottom>
                    No Customer Organizations Yet
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {tenantOrgs.length > 1
                      ? "This tenant organization doesn't have any customers yet."
                      : "Start by adding your first customer organization."}
                  </Typography>
                </div>
              </Box>
            ) : (
              filteredCustomerOrgs.map((org) => (
                <OrganizationCard key={org.id} org={org} />
              ))
            )}
          </Stack>
        </Paper>
      </Box>
    </Box>
  );
};
