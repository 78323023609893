import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LoginPage } from "./pages/LoginPage";
import { ManagerDashboard } from "./pages/ManagerDashboard";
import { UnauthorizedPage } from "./pages/UnauthorizedPage";
import { OrganizationDetails } from "./pages/OrganizationDetails";
import AdminLayout from "./layouts/AdminLayout";
import { MainLayout } from "./layouts/MainLayout";
import { RootLayout } from "./layouts/RootLayout";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { CreateWorkflow } from "./pages/CreateWorkflow";
import { EditWorkflow } from "./pages/EditWorkflow";
import { EditTaskList } from "./pages/EditTaskList";
import { CustomerLayout } from "./layouts/CustomerLayout";
import { CustomerDashboard } from "./pages/CustomerDashboard";
import { CustomerProtectedRoute } from "./components/CustomerProtectedRoute";
import { TaskListRedirect } from "./components/TaskListRedirect";

const router = createBrowserRouter([
  {
    element: <RootLayout />,
    children: [
      {
        path: "/login",
        element: <LoginPage />,
      },
      {
        path: "/admin/*",
        element: (
          <ProtectedRoute>
            <AdminLayout />
          </ProtectedRoute>
        ),
      },
      {
        path: "/customer",
        element: (
          <ProtectedRoute>
            <CustomerLayout>
              <CustomerDashboard />
            </CustomerLayout>
          </ProtectedRoute>
        ),
      },
      {
        path: "/customer/organizations/:orgId/task-lists/:taskListId/edit",
        element: (
          <CustomerProtectedRoute>
            <CustomerLayout>
              <EditTaskList />
            </CustomerLayout>
          </CustomerProtectedRoute>
        ),
      },
      {
        path: "/manager",
        element: (
          <ProtectedRoute>
            <MainLayout>
              <ManagerDashboard />
            </MainLayout>
          </ProtectedRoute>
        ),
      },
      {
        path: "/manager/organizations/:orgId",
        element: (
          <ProtectedRoute>
            <MainLayout>
              <OrganizationDetails />
            </MainLayout>
          </ProtectedRoute>
        ),
      },
      {
        path: "/manager/organizations/:orgId/workflows/create",
        element: (
          <ProtectedRoute>
            <MainLayout>
              <CreateWorkflow />
            </MainLayout>
          </ProtectedRoute>
        ),
      },
      {
        path: "/manager/organizations/:orgId/workflows/:workflowId/edit",
        element: (
          <ProtectedRoute>
            <MainLayout>
              <EditWorkflow />
            </MainLayout>
          </ProtectedRoute>
        ),
      },
      {
        path: "/manager/organizations/:orgId/task-lists/create",
        element: (
          <ProtectedRoute>
            <MainLayout>
              <EditTaskList />
            </MainLayout>
          </ProtectedRoute>
        ),
      },
      {
        path: "/manager/organizations/:orgId/task-lists/:taskListId/edit",
        element: (
          <ProtectedRoute>
            <MainLayout>
              <EditTaskList />
            </MainLayout>
          </ProtectedRoute>
        ),
      },
      {
        path: "/unauthorized",
        element: (
          <ProtectedRoute>
            <MainLayout>
              <UnauthorizedPage />
            </MainLayout>
          </ProtectedRoute>
        ),
      },
      {
        path: "/open/organizations/:orgId/task-lists/:taskListId/edit",
        element: (
          <ProtectedRoute>
            <TaskListRedirect />
          </ProtectedRoute>
        ),
      },
      {
        path: "/",
        element: <LoginPage />,
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
