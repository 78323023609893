import { useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";

// Helper function to normalize URLs and prevent double slashes
const normalizeApiUrl = (path: string) => {
  const baseUrl = import.meta.env.VITE_API_URL.replace(/\/$/, "");
  const normalizedPath = path.replace(/^\//, "");
  return `${baseUrl}/${normalizedPath}`;
};

export const useWorkflowActions = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useMemo(
    () => ({
      toggleWorkflow: async (
        orgId: string,
        workflowId: string
      ): Promise<{ enabled: boolean }> => {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          normalizeApiUrl(
            `api/organizations/${orgId}/workflows/${workflowId}/toggle`
          ),
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.json();
      },

      runWorkflow: async (
        orgId: string,
        workflowId: string
      ): Promise<{ status: string }> => {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          normalizeApiUrl(
            `api/organizations/${orgId}/workflows/${workflowId}/run`
          ),
          {
            method: "POST",
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return response.json();
      },

      previewAction: async (
        orgId: string,
        workflowId: string,
        actionId: string
      ): Promise<any> => {
        const token = await getAccessTokenSilently();
        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 45000); // 45 second timeout

        try {
          const response = await fetch(
            normalizeApiUrl(
              `api/organizations/${orgId}/workflows/${workflowId}/actions/${actionId}/preview`
            ),
            {
              method: "GET",
              headers: {
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
              },
              signal: controller.signal,
            }
          );

          if (!response.ok) {
            const errorData = await response.json().catch(() => null);
            throw new Error(
              errorData?.message ||
                `Preview request failed: ${response.status} ${response.statusText}`
            );
          }

          return response.json();
        } catch (error) {
          if (error instanceof Error && error.name === "AbortError") {
            throw new Error(
              "Preview request timed out after 45 seconds. Please try again."
            );
          }
          throw error;
        } finally {
          clearTimeout(timeoutId);
        }
      },
    }),
    [getAccessTokenSilently]
  );
};
