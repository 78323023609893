export function validateEnvironmentVariables() {
  const requiredEnvVars = {
    VITE_API_URL: import.meta.env.VITE_API_URL,
    VITE_AUTH0_SPA_DOMAIN: import.meta.env.VITE_AUTH0_SPA_DOMAIN,
    VITE_AUTH0_SPA_CLIENT_ID: import.meta.env.VITE_AUTH0_SPA_CLIENT_ID,
    VITE_AUTH0_AUDIENCE: import.meta.env.VITE_AUTH0_AUDIENCE,
  };

  const missingVars = Object.entries(requiredEnvVars)
    .filter(([_, value]) => !value)
    .map(([key]) => key);

  if (missingVars.length > 0) {
    throw new Error(
      `Missing required environment variables: ${missingVars.join(", ")}`
    );
  }
}
