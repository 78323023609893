import {
  List,
  Datagrid,
  TextField,
  DateField,
  Show,
  SimpleShowLayout,
  useRecordContext,
  Labeled,
  ReferenceField,
} from "react-admin";
import ReactJson from "@microlink/react-json-view";

// Helper component to display JSON data
const JsonField = ({ source, label }: { source: string; label?: string }) => {
  const record = useRecordContext();
  if (!record || !record[source]) return null;

  const content = (
    <div style={{ maxHeight: "400px", overflow: "auto" }}>
      <ReactJson
        src={record[source]}
        theme="twilight"
        displayDataTypes={false}
        enableClipboard={false}
        collapsed={1}
        style={{
          backgroundColor: "#1E293B",
          padding: "16px",
          borderRadius: "4px",
          fontSize: "14px",
        }}
      />
    </div>
  );

  return label ? (
    <Labeled label={label}>
      <div style={{ marginBottom: "24px" }}>{content}</div>
    </Labeled>
  ) : (
    content
  );
};

// Convert ISO timestamp to Date string
const TimestampField = ({
  source,
  label,
}: {
  source: string;
  label?: string;
}) => {
  const record = useRecordContext();
  if (!record || !record[source]) return null;

  try {
    return <DateField source={source} showTime label={label} />;
  } catch (e) {
    console.error("Error displaying timestamp:", e, "Record:", record);
    return null;
  }
};

// Agent Sessions List
export const AgentSessionList = () => (
  <List resource="agent/sessions">
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="session_id" />
      <TextField source="agent_id" />
      <ReferenceField source="user_id" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <TimestampField source="created_at" label="Created" />
      <TimestampField source="updated_at" label="Updated" />
    </Datagrid>
  </List>
);

// Agent Session Show
export const AgentSessionShow = () => (
  <Show resource="agent/sessions">
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="session_id" />
      <TextField source="agent_id" />
      <ReferenceField source="user_id" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <JsonField source="memory" label="Memory" />
      <JsonField source="agent_data" label="Agent Data" />
      <JsonField source="session_data" label="Session Data" />
      <JsonField source="user_data" label="User Data" />
      <TimestampField source="created_at" label="Created" />
      <TimestampField source="updated_at" label="Updated" />
    </SimpleShowLayout>
  </Show>
);

// Agent Memory List
export const AgentMemoryList = () => (
  <List resource="agent/memory">
    <Datagrid>
      <TextField source="id" />
      <ReferenceField source="user_id" reference="users">
        <TextField source="name" />
      </ReferenceField>
      <JsonField source="memory" label="Memory" />
      <TimestampField source="created_at" label="Created" />
      <TimestampField source="updated_at" label="Updated" />
    </Datagrid>
  </List>
);
