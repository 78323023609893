import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton,
  DeleteButton,
  CreateButton,
  TopToolbar,
  SelectField,
  useRecordContext,
} from "react-admin";

const ListActions = () => (
  <TopToolbar>
    <CreateButton />
  </TopToolbar>
);

const statusChoices = [
  { id: "active", name: "Active" },
  { id: "inactive", name: "Inactive" },
  { id: "pending", name: "Pending" },
];

const InstallUrlField = ({ label = "Install URL" }) => {
  const record = useRecordContext();
  if (!record || !record.install_url) return null;

  return (
    <a
      href={record.install_url}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: "none" }}
    >
      {label}
    </a>
  );
};

export const SlackAppList = () => (
  <List actions={<ListActions />}>
    <Datagrid rowClick="show">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="client_id" />
      <SelectField source="status" choices={statusChoices} />
      <InstallUrlField />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);
