import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import * as Sentry from "@sentry/react";
import "./index.css";
import App from "./App";
import { validateEnvironmentVariables } from "./utils/env";

// Initialize Sentry only if DSN is provided
if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Set tracePropagationTargets to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/cora\.ai\/api/],
    // Session Replay
    replaysSessionSampleRate: process.env.NODE_ENV === "production" ? 0.1 : 1.0, // 100% in development, 10% in production
    replaysOnErrorSampleRate: 1.0, // 100% of sessions with errors are recorded
  });
}

async function startApp() {
  // Validate environment variables before starting the app
  validateEnvironmentVariables();

  // Render the app
  createRoot(document.getElementById("root")!).render(
    <StrictMode>
      <App />
    </StrictMode>
  );
}

startApp();
