import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  SelectField,
  useRecordContext,
  Labeled,
} from "react-admin";
import { Link } from "@mui/material";

const SlackAppTitle = () => {
  const record = useRecordContext();
  return <span>Slack App: {record ? record.name : ""}</span>;
};

const statusChoices = [
  { id: "active", name: "Active" },
  { id: "inactive", name: "Inactive" },
  { id: "pending", name: "Pending" },
];

const InstallUrlField = () => {
  const record = useRecordContext();
  if (!record || !record.install_url) return null;

  return (
    <Labeled label="Install URL">
      <Link
        href={record.install_url}
        target="_blank"
        rel="noopener"
        sx={{ wordBreak: "break-all" }}
      >
        {record.install_url}
      </Link>
    </Labeled>
  );
};

export const SlackAppShow = () => (
  <Show title={<SlackAppTitle />}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="client_id" />
      <SelectField source="status" choices={statusChoices} />
      <InstallUrlField />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
    </SimpleShowLayout>
  </Show>
);
