import type { DataProvider } from "react-admin";
import { fetchUtils } from "react-admin";
import type { SortPayload, PaginationPayload } from "react-admin";
import { useAuth0 } from "@auth0/auth0-react";
import { useMemo } from "react";

// Helper function to normalize URLs and prevent double slashes
const normalizeApiUrl = (path: string) => {
  const baseUrl = import.meta.env.VITE_API_URL.replace(/\/$/, "");
  const normalizedPath = path.replace(/^\//, "");
  return `${baseUrl}/${normalizedPath}`;
};

export const useDataProvider = (): DataProvider => {
  const { getAccessTokenSilently } = useAuth0();

  return useMemo(() => {
    const httpClient = async (url: string, options: any = {}) => {
      const token = await getAccessTokenSilently();

      // Initialize headers with defaults
      const headers = new Headers({
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      });

      // Merge with provided headers if any
      if (options.headers) {
        if (options.headers instanceof Headers) {
          for (const [key, value] of options.headers.entries()) {
            headers.set(key, value);
          }
        } else {
          Object.entries(options.headers).forEach(([key, value]) => {
            headers.set(key, value as string);
          });
        }
      }

      return fetchUtils.fetchJson(url, {
        ...options,
        headers,
      });
    };

    return {
      getList: async (resource, params) => {
        const {
          sort = { field: "id", order: "ASC" } as SortPayload,
          pagination = { page: 1, perPage: 10 } as PaginationPayload,
        } = params;
        const { field, order } = sort;

        // Handle nested resources
        let url;
        if (resource.includes("/")) {
          const [parentResource, childResource] = resource.split("/");
          // For knowledge sources, we need to get the organization_id from the filter
          if (
            childResource === "knowledge_sources" &&
            params.filter?.organization_id
          ) {
            url = new URL(
              normalizeApiUrl(
                `api/${parentResource}/${params.filter.organization_id}/${childResource}`
              )
            );
            // Remove organization_id from filter since it's in the URL
            const { organization_id, ...restFilter } = params.filter;
            params.filter = restFilter;
          } else {
            url = new URL(normalizeApiUrl(`api/${resource}`));
          }
        } else {
          url = new URL(normalizeApiUrl(`api/${resource}`));
        }

        // Convert React Admin pagination to API pagination
        const { page, perPage } = pagination;
        url.searchParams.append("skip", String((page - 1) * perPage));
        url.searchParams.append("limit", String(perPage));

        if (field) {
          url.searchParams.append("sort", field);
          url.searchParams.append("order", order.toLowerCase());
        }

        if (Object.keys(params.filter).length > 0) {
          url.searchParams.append("filter", JSON.stringify(params.filter));
        }

        const { json } = await httpClient(url.toString());

        // API returns standardized format: { data: [], total: number }
        return {
          data:
            resource === "agent/sessions"
              ? (json.data || []).map((session: any) => ({
                  ...session,
                  id: session.session_id,
                }))
              : resource === "agent/memory"
                ? (json.data || []).map((memory: any) => ({
                    ...memory,
                    id: memory.memory_id,
                  }))
                : json.data || [],
          total: json.total || 0,
        };
      },

      getOne: async (resource, params) => {
        // Handle nested resources. agent/sessions is not a nested resource.
        let url;
        if (resource.includes("/") && resource !== "agent/sessions") {
          const [parentResource, childResource] = resource.split("/");
          // For knowledge sources and task lists, we need to get the organization_id from the resource path
          if (
            (childResource === "knowledge_sources" &&
              params.meta?.organization_id) ||
            (childResource === "task-lists" &&
              resource.startsWith("organizations/"))
          ) {
            const orgId =
              params.meta?.organization_id || parentResource.split("/")[1];
            url = normalizeApiUrl(
              `api/${parentResource}/${orgId}/${childResource}/${params.id}`
            );
          } else {
            url = normalizeApiUrl(`api/${resource}`);
          }
        } else {
          url = normalizeApiUrl(`api/${resource}/${params.id}`);
        }

        const { json } = await httpClient(url);

        // For sessions, use session_id as the id
        if (resource === "agent/sessions") {
          return {
            data: {
              ...json,
              id: json.session_id,
            },
          };
        }

        // For memories, use memory_id as the id
        if (resource === "agent/memory") {
          return {
            data: {
              ...json,
              id: json.memory_id,
            },
          };
        }

        // For organizations, handle the data wrapper if it exists
        if (resource === "organizations") {
          return {
            data: json.data || json,
          };
        }

        return { data: json };
      },

      create: async (resource, params) => {
        // Handle nested resources
        let url;
        if (resource.includes("/")) {
          const [parentResource, childResource] = resource.split("/");
          // For knowledge sources, we need to get the organization_id from the data
          if (
            childResource === "knowledge_sources" &&
            params.data.organization_id
          ) {
            url = normalizeApiUrl(
              `api/${parentResource}/${params.data.organization_id}/${childResource}`
            );
          } else {
            url = normalizeApiUrl(`api/${resource}`);
          }
        } else {
          url = normalizeApiUrl(`api/${resource}`);
        }

        const { json } = await httpClient(url, {
          method: params.meta?.method || "POST",
          body: JSON.stringify(params.data),
        });
        return { data: json };
      },

      update: async (resource, params) => {
        // Handle nested resources
        let url;
        if (resource.includes("/")) {
          // For knowledge sources, handle the special case
          if (
            resource.includes("knowledge_sources") &&
            params.data.organization_id
          ) {
            const orgId = params.data.organization_id;
            const parts = resource.split("/");
            const resourceWithoutId = parts.slice(0, -1).join("/");
            url = normalizeApiUrl(
              `api/${resourceWithoutId}/${orgId}/knowledge_sources/${params.id}`
            );
          } else {
            // For all other nested resources, use the full resource path
            url = normalizeApiUrl(`api/${resource}`);
          }
        } else {
          url = normalizeApiUrl(`api/${resource}/${params.id}`);
        }

        const { json } = await httpClient(url, {
          method: "PUT",
          body: JSON.stringify(params.data),
        });
        return { data: json };
      },

      delete: async (resource, params) => {
        // Handle nested resources
        let url;
        if (resource.includes("/")) {
          const [parentResource, childResource] = resource.split("/");
          // For knowledge sources and task lists, we need to get the organization_id from the resource path
          if (
            (childResource === "knowledge_sources" &&
              params.previousData?.organization_id) ||
            (childResource === "task-lists" &&
              resource.startsWith("organizations/"))
          ) {
            const orgId =
              params.previousData?.organization_id ||
              parentResource.split("/")[1];
            url = normalizeApiUrl(
              `api/${parentResource}/${orgId}/${childResource}/${params.id}`
            );
          } else {
            url = normalizeApiUrl(`api/${resource}`);
          }
        } else {
          url = normalizeApiUrl(`api/${resource}/${params.id}`);
        }

        await httpClient(url, {
          method: "DELETE",
        });
        return { data: params.previousData as any };
      },

      deleteMany: async (resource, params) => {
        await Promise.all(
          params.ids.map((id) =>
            httpClient(normalizeApiUrl(`api/${resource}/${id}`), {
              method: "DELETE",
            })
          )
        );
        return { data: params.ids };
      },

      getMany: async (resource, params) => {
        const responses = await Promise.all(
          params.ids.map((id) =>
            httpClient(normalizeApiUrl(`api/${resource}/${id}`))
          )
        );
        return { data: responses.map(({ json }) => json) };
      },

      getManyReference: async (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;

        const url = new URL(normalizeApiUrl(`api/${resource}`));

        url.searchParams.append("skip", String((page - 1) * perPage));
        url.searchParams.append("limit", String(perPage));

        if (field) {
          url.searchParams.append("sort", field);
          url.searchParams.append("order", order.toLowerCase());
        }

        // Add the reference filter
        const filter = {
          ...params.filter,
          [params.target]: params.id,
        };
        url.searchParams.append("filter", JSON.stringify(filter));

        const { json } = await httpClient(url.toString());
        return {
          data: json.data || [],
          total: json.total || 0,
        };
      },

      updateMany: async (resource, params) => {
        await Promise.all(
          params.ids.map((id) =>
            httpClient(normalizeApiUrl(`api/${resource}/${id}`), {
              method: "PUT",
              body: JSON.stringify(params.data),
            })
          )
        );
        return { data: params.ids };
      },
    };
  }, [getAccessTokenSilently]);
};
