import { useAuth0 } from "@auth0/auth0-react";
import {
  Typography,
  Divider,
  Paper,
  CircularProgress,
  Box,
} from "@mui/material";
import { useState, useEffect } from "react";

export const UnauthorizedPage = () => {
  const { user } = useAuth0();
  const [slackAuthUrl, setSlackAuthUrl] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`${import.meta.env.VITE_API_URL}/api/slack/install`)
      .then((response) => response.json())
      .then((data) => {
        setSlackAuthUrl(data.authorization_url);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Failed to fetch Slack auth URL:", error);
        setIsLoading(false);
      });
  }, []);

  return (
    <Paper
      elevation={3}
      sx={{
        p: 6,
        maxWidth: "600px",
        width: "90%",
        bgcolor: "background.paper",
        borderRadius: 2,
        textAlign: "center",
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Typography
        variant="h3"
        component="h1"
        gutterBottom
        sx={{
          fontWeight: 600,
          mb: 3,
        }}
      >
        Welcome, {user?.name} 👋
      </Typography>

      <Typography variant="body1" sx={{ mb: 4, color: "text.secondary" }}>
        You don't currently have access to the web interface. Please contact
        your administrator for access.
      </Typography>

      <Divider sx={{ my: 4 }} />

      <Typography variant="h6" sx={{ mb: 3 }}>
        Get Started with Slack
      </Typography>

      <Typography variant="body1" sx={{ mb: 4, color: "text.secondary" }}>
        Meanwhile, you can install Cora AI in your Slack workspace:
      </Typography>

      {isLoading ? (
        <CircularProgress size={24} />
      ) : slackAuthUrl ? (
        <Box
          sx={{
            transition: "transform 0.2s",
            "&:hover": { transform: "scale(1.02)" },
          }}
        >
          <a href={slackAuthUrl}>
            <img
              alt="Add to Slack"
              height="40"
              width="139"
              src="https://platform.slack-edge.com/img/add_to_slack.png"
              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
            />
          </a>
        </Box>
      ) : (
        <Typography color="error">
          Unable to load Slack installation. Please try again later.
        </Typography>
      )}
    </Paper>
  );
};
