import {
  Box,
  Typography,
  IconButton,
  Tooltip,
  SvgIconProps,
} from "@mui/material";
import { alpha, useTheme } from "@mui/material/styles";
import { ReactNode } from "react";

interface EmptyStateProps {
  icon: ReactNode;
  title: string;
  description: string;
  action?: ReactNode;
}

export const EmptyState = ({
  icon,
  title,
  description,
  action,
}: EmptyStateProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        py: 8,
        px: 3,
        textAlign: "center",
        backgroundColor: alpha(theme.palette.background.paper, 0.05),
        borderRadius: 2,
      }}
    >
      <Box
        sx={{
          color: theme.palette.primary.main,
          opacity: 0.7,
          fontSize: 48,
          mb: 2,
          "& > *": { fontSize: "inherit" },
        }}
      >
        {icon}
      </Box>
      <Typography variant="h6" color="primary" gutterBottom>
        {title}
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ mb: 3, maxWidth: 400 }}
      >
        {description}
      </Typography>
      {action}
    </Box>
  );
};

interface ActionButtonProps {
  icon: React.ReactElement<SvgIconProps>;
  onClick: () => void;
  tooltip: string;
  color?: string;
  disabled?: boolean;
}

export const ActionButton = ({
  icon,
  onClick,
  tooltip,
  color,
  disabled = false,
}: ActionButtonProps) => {
  const theme = useTheme();
  return (
    <Tooltip title={tooltip}>
      <span>
        <IconButton
          onClick={onClick}
          disabled={disabled}
          sx={{
            color: color || "inherit",
            "&:hover": {
              backgroundColor: color
                ? alpha(color, 0.1)
                : alpha(theme.palette.primary.main, 0.1),
            },
          }}
        >
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  );
};

interface StatusChipProps {
  status: string;
  size?: "small" | "medium";
}

export const StatusChip = ({ status, size = "medium" }: StatusChipProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "inline-flex",
        alignItems: "center",
        px: 1,
        py: 0.5,
        borderRadius: 1,
        backgroundColor: alpha(theme.palette.primary.main, 0.1),
        color: theme.palette.primary.main,
        fontSize: size === "small" ? "0.75rem" : "0.875rem",
      }}
    >
      {status}
    </Box>
  );
};

interface ComingSoonWrapperProps {
  children: ReactNode;
  isComingSoon?: boolean;
}

export const ComingSoonWrapper = ({
  children,
  isComingSoon = true,
}: ComingSoonWrapperProps) => {
  if (!isComingSoon) {
    return <>{children}</>;
  }

  return (
    <Box
      sx={{
        position: "relative",
        opacity: 0.5,
        pointerEvents: "none",
        "&::after": {
          content: '"Coming Soon"',
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "rgba(0, 0, 0, 0.7)",
          color: "white",
          padding: "4px 8px",
          borderRadius: "4px",
          fontSize: "0.875rem",
        },
      }}
    >
      {children}
    </Box>
  );
};
