import { useState, useEffect, useCallback } from "react";
import {
  Dialog,
  DialogContent,
  Alert,
  Box,
  CircularProgress,
} from "@mui/material";
import { CustomWorkflowEditor } from "./CustomWorkflowEditor";
import type { EditorWorkflow } from "../types/workflow";
import { availableActions } from "../constants/availableActions";
import { useDataProvider } from "../dataProviders/dataProvider";
import { WorkflowTemplateModal } from "./WorkflowTemplateModal";

interface CreateWorkflowProps {
  open: boolean;
  onClose: () => void;
  orgId: string;
}

export function CreateWorkflow({ open, onClose, orgId }: CreateWorkflowProps) {
  const [error, setError] = useState<string | null>(null);
  const [showTemplateModal, setShowTemplateModal] = useState(true);
  const [showEditor, setShowEditor] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [organization, setOrganization] = useState<any>(null);
  const [organizationSlackChannelName, setOrganizationSlackChannelName] =
    useState<string | null>(null);
  const [initialWorkflow, setInitialWorkflow] = useState<EditorWorkflow>({
    organization_id: orgId,
    name: "Untitled workflow",
    description: "",
    trigger_event: {
      type: "custom_event",
      event: { name: "blog-post.updated" },
    },
    actions: [],
  });
  const dataProvider = useDataProvider();

  useEffect(() => {
    if (!open) {
      setShowTemplateModal(true);
      setShowEditor(false);
      setError(null);
    }
  }, [open]);

  const loadOrganization = useCallback(async () => {
    if (!orgId) {
      setIsLoading(false);
      return;
    }

    try {
      const { data } = await dataProvider.getOne("organizations", {
        id: orgId,
      });
      setOrganization(data);

      // Add Slack channel name fetching
      if (data?.slack_channel_id) {
        try {
          const slackChannelResponse = await dataProvider.getOne(
            `organizations/${orgId}/slack/channels/${data.slack_channel_id}`,
            {
              id: data.slack_channel_id,
            }
          );
          setOrganizationSlackChannelName(
            slackChannelResponse.data["channel_name"]
          );
        } catch (err) {
          console.error("Failed to load Slack channel:", err);
          // Fallback to using the channel ID as the name
          setOrganizationSlackChannelName(data.slack_channel_id);
        }
      }
    } catch (err) {
      console.error("Failed to load organization:", err);
      setError("Failed to load organization data");
    } finally {
      setIsLoading(false);
    }
  }, [orgId, dataProvider]);

  useEffect(() => {
    if (showEditor) {
      loadOrganization();
    }
  }, [showEditor, loadOrganization]);

  const handleSave = async (workflow: EditorWorkflow) => {
    if (!orgId || orgId.trim() === "") {
      const errorMsg = "No organization ID provided";
      console.error(errorMsg);
      setError(errorMsg);
      return;
    }

    try {
      // First create the workflow without actions
      const { data: newWorkflow } = await dataProvider.create(
        `organizations/${orgId}/workflows`,
        {
          data: {
            organization_id: orgId,
            name: workflow.name,
            description: workflow.description,
            trigger_event: workflow.trigger_event,
            enabled: true,
          },
        }
      );

      // Then create each action for the workflow using the actions endpoint
      await Promise.all(
        workflow.actions.map((action, index) =>
          dataProvider.create(
            `organizations/${orgId}/workflows/${newWorkflow.id}/actions`,
            {
              data: {
                name: action.name,
                kind: action.kind,
                description: action.description || "",
                sequence: index,
                inputs: action.inputs,
              },
            }
          )
        )
      );

      setShowTemplateModal(false);
      setShowEditor(false);
      onClose();
    } catch (err) {
      const errorMsg =
        err instanceof Error
          ? err.message
          : "Failed to create workflow. Please try again.";
      console.error("Save failed:", err);
      setError(errorMsg);
    }
  };

  const handleTemplateSelect = (workflow: EditorWorkflow) => {
    setInitialWorkflow(workflow);
    setShowTemplateModal(false);
    setShowEditor(true);
  };

  return (
    <>
      <WorkflowTemplateModal
        open={open && showTemplateModal}
        onClose={onClose}
        onTemplateSelect={handleTemplateSelect}
        orgId={orgId}
      />

      {showEditor && (
        <Dialog
          open={true}
          onClose={() => {
            setShowEditor(false);
            onClose();
          }}
          maxWidth="lg"
          fullWidth
          PaperProps={{
            sx: {
              height: "90vh",
              maxHeight: "90vh",
              bgcolor: "background.default",
              backgroundImage: "none",
            },
          }}
        >
          <DialogContent sx={{ p: 0 }}>
            {isLoading ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </Box>
            ) : (
              <CustomWorkflowEditor
                initialWorkflow={initialWorkflow}
                availableActions={availableActions}
                onSave={handleSave}
                onDelete={() => {
                  setShowEditor(false);
                  onClose();
                }}
                isCustomerOrg={organization?.customer_of_id !== null}
                organizationSlackChannelName={
                  organizationSlackChannelName || undefined
                }
              />
            )}
          </DialogContent>
        </Dialog>
      )}

      {error && (
        <Alert
          severity="error"
          sx={{
            position: "fixed",
            bottom: 16,
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          {error}
        </Alert>
      )}
    </>
  );
}
