import { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  IconButton,
  Typography,
  alpha,
  Snackbar,
  Alert,
  Button,
  CircularProgress,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { CustomWorkflowEditor } from "../components/CustomWorkflowEditor";
import {
  type EditorWorkflow,
  type Workflow,
  type WorkflowAction,
} from "../types/workflow";
import { availableActions } from "../constants/availableActions";
import { useDataProvider } from "../dataProviders/dataProvider";

export const EditWorkflow = () => {
  const navigate = useNavigate();
  const { orgId, workflowId } = useParams<{
    orgId: string;
    workflowId: string;
  }>();
  const [workflow, setWorkflow] = useState<Workflow | null>(null);
  const [organization, setOrganization] = useState<any>(null);
  const [organizationSlackChannelName, setOrganizationSlackChannelName] =
    useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [actions, setActions] = useState<WorkflowAction[]>([]);
  const [notification, setNotification] = useState<{
    message: string;
    severity: "success" | "error";
    open: boolean;
  }>({ message: "", severity: "success", open: false });
  const dataProvider = useDataProvider();

  const loadData = useCallback(async () => {
    if (!orgId || !workflowId) {
      setIsLoading(false);
      return;
    }

    try {
      const [organizationResponse, workflowResponse, actionsResponse] =
        await Promise.all([
          dataProvider.getOne("organizations", {
            id: orgId,
          }),
          dataProvider.getOne(
            `organizations/${orgId}/workflows/${workflowId}`,
            {
              id: workflowId,
            }
          ),
          dataProvider.getList(
            `organizations/${orgId}/workflows/${workflowId}/actions`,
            {
              pagination: { page: 1, perPage: 100 },
              sort: { field: "sequence", order: "ASC" },
              filter: {},
            }
          ),
        ]);

      const organization = organizationResponse.data;
      setOrganization(organization);
      if (organization?.slack_channel_id) {
        try {
          // As this is cosmetic, we don't want to block the workflow creation if fails.
          const slackChannelResponse = await dataProvider.getOne(
            `organizations/${orgId}/slack/channels/${organization.slack_channel_id}`,
            {
              id: organization.slack_channel_id,
            }
          );
          setOrganizationSlackChannelName(
            slackChannelResponse.data["channel_name"]
          );
        } catch (err) {
          console.error("Failed to load Slack channel:", err);
          // Fallback to using the channel ID as the name
          setOrganizationSlackChannelName(organization.slack_channel_id);
        }
      }
      setWorkflow(workflowResponse.data);
      setActions(actionsResponse.data);
    } catch (err) {
      console.error("Failed to load data:", err);
      setError("Failed to load workflow");
      setNotification({
        message: "Failed to load workflow",
        severity: "error",
        open: true,
      });
      navigate(`/manager/organizations/${orgId}`);
    } finally {
      setIsLoading(false);
    }
  }, [orgId, workflowId, dataProvider, navigate]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const handleSave = async (editorWorkflow: EditorWorkflow) => {
    if (!orgId || !workflowId) return;
    try {
      const triggerEvent =
        editorWorkflow.trigger_event.type === "schedule"
          ? {
              type: "schedule" as const,
              schedule: editorWorkflow.trigger_event.schedule || "0 12 * * 1-5",
              timezone: editorWorkflow.trigger_event.timezone,
            }
          : {
              type: "custom_event" as const,
              event: editorWorkflow.trigger_event.event || {
                name: "custom.event",
              },
            };

      // Update workflow details
      await dataProvider.update(
        `organizations/${orgId}/workflows/${workflowId}`,
        {
          id: workflowId,
          data: {
            name: editorWorkflow.name,
            description: editorWorkflow.description,
            trigger_event: triggerEvent,
          },
          previousData: workflow!,
        }
      );

      // Handle action changes
      const actionIds = actions.map((a) => a.id);
      const newActions = editorWorkflow.actions.filter(
        (a) => !a.id || a.id.startsWith("temp-")
      );
      const updatedActions = editorWorkflow.actions.filter(
        (a) => a.id && !a.id.startsWith("temp-")
      );
      const deletedActionIds = actionIds.filter(
        (id) => !updatedActions.find((a) => a.id === id)
      );

      // Delete removed actions using the actions endpoint
      await Promise.all(
        deletedActionIds.map((actionId) =>
          dataProvider.delete(
            `organizations/${orgId}/workflows/${workflowId}/actions/${actionId}`,
            { id: actionId }
          )
        )
      );

      // Create new actions using the actions endpoint
      const createdActions = await Promise.all(
        newActions.map((action) =>
          dataProvider.create(
            `organizations/${orgId}/workflows/${workflowId}/actions`,
            {
              data: {
                name: action.name,
                kind: action.kind,
                description: action.description || "",
                sequence: action.sequence,
                inputs: action.inputs,
              },
            }
          )
        )
      );

      // Update existing actions using the actions endpoint
      await Promise.all(
        updatedActions.map((action) =>
          dataProvider.update(
            `organizations/${orgId}/workflows/${workflowId}/actions/${action.id}`,
            {
              id: action.id,
              data: {
                name: action.name,
                kind: action.kind,
                description: action.description || "",
                sequence: action.sequence,
                inputs: action.inputs,
              },
              previousData: actions.find((a) => a.id === action.id)!,
            }
          )
        )
      );

      // Create a mapping of temporary IDs to real IDs
      const idMapping = new Map(
        newActions.map((action, index) => [
          action.id,
          createdActions[index].data.id,
        ])
      );

      // Replace temporary IDs with real IDs in the actions array
      const finalActions = editorWorkflow.actions.map((action) => {
        if (action.id && action.id.toString().startsWith("temp-")) {
          return {
            ...action,
            id: idMapping.get(action.id),
          };
        }
        return action;
      });

      // Check if order has changed by comparing sequences
      const hasOrderChanged = actions.some((originalAction, index) => {
        const newAction = finalActions.find(
          (a) =>
            a.id === originalAction.id ||
            idMapping.get(originalAction.id) === a.id
        );
        return newAction && newAction.sequence !== index;
      });

      // Reorder actions if needed using the reorder endpoint
      if (hasOrderChanged) {
        await dataProvider.create(
          `organizations/${orgId}/workflows/${workflowId}/actions/reorder`,
          {
            data: finalActions.map((a) => a.id),
          }
        );
      }

      setNotification({
        message: "Workflow updated successfully",
        severity: "success",
        open: true,
      });
      navigate(`/manager/organizations/${orgId}?tab=1`);
    } catch (error) {
      console.error("Failed to update workflow:", error);
      setNotification({
        message: "Failed to update workflow",
        severity: "error",
        open: true,
      });
    }
  };

  const handleDelete = async () => {
    if (!orgId || !workflowId) return;
    try {
      await dataProvider.delete(
        `organizations/${orgId}/workflows/${workflowId}`,
        {
          id: workflowId,
        }
      );
      setNotification({
        message: "Workflow deleted successfully",
        severity: "success",
        open: true,
      });
      navigate(`/manager/organizations/${orgId}`);
    } catch (error) {
      console.error("Failed to delete workflow:", error);
      setNotification({
        message: "Failed to delete workflow",
        severity: "error",
        open: true,
      });
    }
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          gap: 2,
        }}
      >
        <Alert severity="error">{error}</Alert>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
        >
          Go Back
        </Button>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        color: "#F8FAFC",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          gap: 2,
          borderBottom: `1px solid ${alpha("#fff", 0.05)}`,
        }}
      >
        <IconButton
          onClick={() => {
            navigate(`/manager/organizations/${orgId}`);
          }}
          color="inherit"
          sx={{ color: "white" }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h5"
          fontWeight="bold"
          sx={{ flexGrow: 1, color: "white" }}
        >
          Edit Workflow
        </Typography>
      </Box>

      {/* Main Content Area */}
      <Box sx={{ flex: 1, position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
        >
          {workflow && (
            <CustomWorkflowEditor
              initialWorkflow={{
                id: workflow.id,
                organization_id: workflow.organization_id,
                name: workflow.name,
                description: workflow.description,
                trigger_event: workflow.trigger_event,
                actions: actions,
              }}
              availableActions={availableActions}
              onSave={handleSave}
              onDelete={handleDelete}
              isCustomerOrg={organization?.customer_of_id !== null}
              organizationSlackChannelName={
                organizationSlackChannelName || undefined
              }
            />
          )}
        </Box>
      </Box>

      {/* Notifications */}
      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={() => {
          setNotification((prev) => ({ ...prev, open: false }));
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => {
            setNotification((prev) => ({ ...prev, open: false }));
          }}
          severity={notification.severity}
          sx={{ width: "100%" }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
