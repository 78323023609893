import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
  Switch,
  useTheme,
  Collapse,
  Tooltip,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  InputAdornment,
  TextField,
} from "@mui/material";
import {
  PlayArrow as PlayArrowIcon,
  Edit as EditIcon,
  Add as AddIcon,
  AutoAwesome as AutoAwesomeIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  History as HistoryIcon,
  Delete as DeleteIcon,
  Output as OutputIcon,
} from "@mui/icons-material";
import { EmptyState, StatusChip, ActionButton } from "./shared";
import { getGlassStyles, getButtonStyles } from "../theme/shared";
import type { Workflow, WorkflowAction } from "../types/workflow";
import { WorkflowRunLogs, WorkflowRunLogsRef } from "./WorkflowRunLogs";
import { useState, useEffect, useCallback, useRef } from "react";
import { useDataProvider } from "../dataProviders/dataProvider";
import { alpha } from "@mui/material/styles";
import { CreateWorkflow } from "./CreateWorkflow";
import cronstrue from "cronstrue";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(timezone);

interface WorkflowListProps {
  workflows: Workflow[];
  onToggleWorkflow: (id: string) => void;
  onRunWorkflow: (id: string) => void;
  onCreateWorkflow: () => void;
  onEditWorkflow: (id: string) => void;
  onDeleteWorkflow: (id: string) => void;
  orgId: string;
}

const formatTrigger = (triggerEvent: any): string => {
  if (triggerEvent.type === "custom_event") {
    return triggerEvent.event?.name || "Unnamed Event";
  }

  if (triggerEvent.type === "schedule") {
    const schedule = triggerEvent.schedule || "";

    // Check if it's an ISO 8601 datetime string (for one-time schedule)
    // Format: YYYY-MM-DDTHH:MM:SS
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/;

    if (isoDateRegex.test(schedule)) {
      try {
        const date = new Date(schedule);

        // Verify the date is valid
        if (!isNaN(date.getTime())) {
          const timeString = date.toLocaleTimeString("en-US", {
            hour: "numeric",
            minute: "2-digit",
            hour12: true,
          });
          const dateString = date.toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          });

          return `Once on ${dateString} at ${timeString} ${triggerEvent.timezone ? `(${triggerEvent.timezone})` : ""}`;
        }
      } catch (error) {
        console.error("Error parsing ISO date string:", error);
      }
    }

    // For non-specific date cron expressions, use existing logic
    try {
      const humanReadable = cronstrue.toString(schedule);
      return `${humanReadable} ${triggerEvent.timezone ? `(${triggerEvent.timezone})` : ""}`;
    } catch (error) {
      // Fallback to raw schedule if parsing fails
      return `${triggerEvent.schedule || ""} ${triggerEvent.timezone ? `(${triggerEvent.timezone})` : ""}`;
    }
  }

  return "Unknown trigger";
};

export function WorkflowList({
  workflows,
  onToggleWorkflow,
  onRunWorkflow,
  onCreateWorkflow,
  onEditWorkflow,
  onDeleteWorkflow,
  orgId,
}: WorkflowListProps) {
  const theme = useTheme();
  const [expandedWorkflow, setExpandedWorkflow] = useState<string | null>(null);
  const [workflowsWithRuns, setWorkflowsWithRuns] = useState<Set<string>>(
    new Set()
  );
  const [runningWorkflows, setRunningWorkflows] = useState<Set<string>>(
    new Set()
  );
  const [workflowToDelete, setWorkflowToDelete] = useState<string | null>(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const dataProvider = useDataProvider();

  // Create a single ref to hold all workflow refs using a Map
  const runLogsRefsMap = useRef(new Map<string, WorkflowRunLogsRef>());

  const checkWorkflowRuns = useCallback(
    async (workflow: Workflow) => {
      try {
        const { data } = await dataProvider.getList(
          `organizations/${workflow.organization_id}/workflows/${workflow.id}/runs`,
          {
            pagination: { page: 1, perPage: 1 },
            sort: { field: "created_at", order: "DESC" as const },
            filter: {},
          }
        );
        if (data.length > 0) {
          setWorkflowsWithRuns((prev) => new Set([...prev, workflow.id]));
          if (data[0].status.toLowerCase() === "running") {
            setRunningWorkflows((prev) => new Set([...prev, workflow.id]));
            setExpandedWorkflow(workflow.id);
          } else {
            setRunningWorkflows((prev) => {
              const newSet = new Set(prev);
              newSet.delete(workflow.id);
              return newSet;
            });
          }
        }
      } catch (error) {
        console.error("Error checking workflow runs:", error);
      }
    },
    [dataProvider]
  );

  useEffect(() => {
    workflows.forEach(checkWorkflowRuns);
  }, [workflows, checkWorkflowRuns]);

  // Set up polling for running workflows
  useEffect(() => {
    if (runningWorkflows.size > 0) {
      const interval = setInterval(() => {
        workflows.forEach(checkWorkflowRuns);
      }, 3000); // Poll every 3 seconds
      return () => clearInterval(interval);
    }
  }, [runningWorkflows, workflows, checkWorkflowRuns]);

  const handleRunClick = async (workflowId: string) => {
    // Add to running set immediately for UI feedback
    setRunningWorkflows((prev) => new Set([...prev, workflowId]));
    setExpandedWorkflow(workflowId); // Auto-expand when running

    try {
      // Call the provided run handler
      await onRunWorkflow(workflowId);

      // Immediately check for runs to show the new run in history
      const workflow = workflows.find((w) => w.id === workflowId);
      if (workflow) {
        await checkWorkflowRuns(workflow);
        // Use the Map to get the ref and call fetchRuns
        runLogsRefsMap.current.get(workflowId)?.fetchRuns();
      }
    } catch (error) {
      // Remove from running workflows on error
      setRunningWorkflows((prev) => {
        const newSet = new Set(prev);
        newSet.delete(workflowId);
        return newSet;
      });
      console.error("Error running workflow:", error);
    }
  };

  const handleCreateModalClose = () => {
    setShowCreateModal(false);
    onCreateWorkflow();
  };

  const fallbackDisplayTimezone = dayjs.tz.guess() || "UTC";

  return (
    <Box>
      <Stack direction="row" justifyContent="flex-end" mb={3}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setShowCreateModal(true)}
          startIcon={<AddIcon />}
          sx={getButtonStyles(theme)}
        >
          Create Workflow
        </Button>
      </Stack>

      <Stack spacing={2}>
        {workflows.length === 0 ? (
          <EmptyState
            icon={<AutoAwesomeIcon />}
            title="No Workflows Yet"
            description="Create your first workflow to automate your organization's tasks. Workflows help streamline operations and improve efficiency."
            action={
              <Button
                variant="contained"
                color="primary"
                onClick={() => setShowCreateModal(true)}
                startIcon={<AddIcon />}
                sx={getButtonStyles(theme)}
              >
                Create Your First Workflow
              </Button>
            }
          />
        ) : (
          workflows.map((workflow) => (
            <Card
              key={workflow.id}
              sx={getGlassStyles(theme, { hoverEffect: true })}
            >
              <CardContent>
                <Stack spacing={2}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box>
                      <Typography
                        variant="subtitle1"
                        fontWeight="bold"
                        gutterBottom
                      >
                        {workflow.name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {workflow.description}
                      </Typography>
                    </Box>
                    <Stack direction="row" spacing={1.5} alignItems="center">
                      <Switch
                        checked={workflow.enabled}
                        onChange={() => onToggleWorkflow(workflow.id)}
                        sx={{
                          "& .MuiSwitch-switchBase.Mui-checked": {
                            color: theme.palette.primary.main,
                          },
                          "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track":
                            {
                              backgroundColor: theme.palette.primary.main,
                              opacity: 0.5,
                            },
                        }}
                      />
                      <ActionButton
                        icon={
                          runningWorkflows.has(workflow.id) ? (
                            <CircularProgress size={20} />
                          ) : (
                            <PlayArrowIcon />
                          )
                        }
                        onClick={() => handleRunClick(workflow.id)}
                        disabled={
                          !workflow.enabled || runningWorkflows.has(workflow.id)
                        }
                        tooltip={
                          runningWorkflows.has(workflow.id)
                            ? "Workflow is running"
                            : "Run workflow"
                        }
                      />
                      <ActionButton
                        icon={<EditIcon />}
                        onClick={() => {
                          onEditWorkflow(workflow.id);
                        }}
                        tooltip="Edit workflow"
                      />
                      <ActionButton
                        icon={<DeleteIcon />}
                        onClick={() => setWorkflowToDelete(workflow.id)}
                        tooltip="Delete workflow"
                        color={theme.palette.error.main}
                      />
                      <Tooltip
                        title={
                          workflowsWithRuns.has(workflow.id)
                            ? "View run history"
                            : "No runs available"
                        }
                      >
                        <span>
                          <IconButton
                            onClick={() =>
                              setExpandedWorkflow(
                                expandedWorkflow === workflow.id
                                  ? null
                                  : workflow.id
                              )
                            }
                            disabled={!workflowsWithRuns.has(workflow.id)}
                          >
                            {expandedWorkflow === workflow.id ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <HistoryIcon />
                            )}
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Stack>
                  </Stack>

                  <Box>
                    <Box sx={{ mb: 1 }}>
                      <Typography
                        variant="subtitle2"
                        color="text.secondary"
                        sx={{ display: "flex", alignItems: "center", gap: 0.5 }}
                      >
                        Trigger:{" "}
                        <Typography
                          component="span"
                          variant="subtitle2"
                          color="text.primary"
                          sx={{ fontFamily: "monospace" }}
                        >
                          {formatTrigger(workflow.trigger_event)}
                        </Typography>
                      </Typography>
                    </Box>
                    <Stack direction="row" spacing={1}>
                      {workflow.actions?.map?.((action: WorkflowAction) => (
                        <StatusChip
                          key={action.id}
                          status={action.name || "Unnamed Action"}
                          size="small"
                        />
                      )) || null}
                    </Stack>
                  </Box>

                  <Collapse in={expandedWorkflow === workflow.id}>
                    <Box
                      sx={{
                        mt: 2,
                        borderTop: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
                        pt: 2,
                      }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={1}
                        mb={2}
                      >
                        <HistoryIcon color="primary" />
                        <Typography variant="subtitle1" color="primary">
                          Run History
                        </Typography>
                      </Stack>
                      <WorkflowRunLogs
                        ref={(ref) => {
                          if (ref) {
                            runLogsRefsMap.current.set(workflow.id, ref);
                          } else {
                            runLogsRefsMap.current.delete(workflow.id);
                          }
                        }}
                        organizationId={workflow.organization_id}
                        workflowId={workflow.id}
                        displayTimezone={
                          workflow.trigger_event?.timezone ||
                          fallbackDisplayTimezone
                        }
                        limit={5}
                        compact
                      />
                    </Box>
                  </Collapse>
                </Stack>
              </CardContent>
            </Card>
          ))
        )}
      </Stack>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={!!workflowToDelete}
        onClose={() => setWorkflowToDelete(null)}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
            backgroundImage: "none",
          },
        }}
      >
        <DialogTitle>Delete Workflow</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this workflow? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setWorkflowToDelete(null)}>Cancel</Button>
          <Button
            onClick={async () => {
              if (workflowToDelete) {
                await onDeleteWorkflow(workflowToDelete);
                setWorkflowToDelete(null);
              }
            }}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Create Workflow Modal */}
      <CreateWorkflow
        open={showCreateModal}
        onClose={handleCreateModalClose}
        orgId={orgId}
      />
    </Box>
  );
}

// Add this new component
interface ActionOutputReferenceProps {
  actionId: string;
  outputs: Record<string, any>;
  onSelect: (reference: string) => void;
}

function ActionOutputReference({
  actionId,
  outputs,
  onSelect,
}: ActionOutputReferenceProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderOutputItems = (obj: any, path: string[] = []) => {
    return Object.entries(obj).map(([key, value]) => {
      const currentPath = [...path, key];
      const reference = `$output.${actionId}.${currentPath.join(".")}`;

      if (typeof value === "object" && value !== null) {
        return (
          <List key={key} sx={{ pl: 2 }}>
            <ListItem>
              <ListItemText
                primary={key}
                sx={{
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                }}
              />
            </ListItem>
            {renderOutputItems(value, currentPath)}
          </List>
        );
      }

      return (
        <ListItem
          key={key}
          button
          onClick={() => {
            onSelect(reference);
            handleClose();
          }}
          sx={{
            pl: 4,
            "&:hover": {
              backgroundColor: alpha(theme.palette.primary.main, 0.1),
            },
          }}
        >
          <ListItemIcon>
            <OutputIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={key}
            secondary={reference}
            primaryTypographyProps={{
              variant: "body2",
            }}
            secondaryTypographyProps={{
              variant: "caption",
              sx: { fontFamily: "monospace" },
            }}
          />
        </ListItem>
      );
    });
  };

  return (
    <>
      <IconButton onClick={handleClick} size="small">
        <OutputIcon />
      </IconButton>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            maxHeight: 400,
            width: 400,
            overflow: "auto",
            backgroundColor: alpha(theme.palette.background.paper, 0.9),
            backdropFilter: "blur(10px)",
            border: `1px solid ${alpha("#fff", 0.1)}`,
          },
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle2" color="text.secondary" gutterBottom>
            Available Outputs
          </Typography>
          {renderOutputItems(outputs)}
        </Box>
      </Popover>
    </>
  );
}

// Add this new component for input fields that can reference outputs
interface ActionInputFieldProps {
  label: string;
  value: string;
  onChange: (value: string) => void;
  previousActionOutputs: Record<string, any>;
  previousActionId: string;
}

export function ActionInputField({
  label,
  value,
  onChange,
  previousActionOutputs,
  previousActionId,
}: ActionInputFieldProps) {
  return (
    <TextField
      fullWidth
      label={label}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      InputProps={{
        endAdornment: previousActionOutputs && (
          <InputAdornment position="end">
            <ActionOutputReference
              actionId={previousActionId}
              outputs={previousActionOutputs}
              onSelect={onChange}
            />
          </InputAdornment>
        ),
      }}
      sx={{
        "& .MuiInputBase-root": {
          fontFamily: value.startsWith("$output") ? "monospace" : "inherit",
        },
      }}
    />
  );
}
