import React, { useState, useEffect, useCallback } from "react";
import {
  Box,
  Button,
  Card,
  Stack,
  TextField,
  Typography,
  alpha,
  useTheme,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  ToggleButton,
  ToggleButtonGroup,
  FormHelperText,
  Tooltip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Chip,
  CardContent,
  CircularProgress,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  DragHandle as DragHandleIcon,
  ExpandMore as ExpandMoreIcon,
  Link as LinkIcon,
  AutoAwesome as AutoAwesomeIcon,
  Save as SaveIcon,
  Visibility as VisibilityIcon,
} from "@mui/icons-material";
import type { DragEndEvent, UniqueIdentifier } from "@dnd-kit/core";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { getGlassStyles, getButtonStyles } from "../theme/shared";
import type {
  WorkflowAction,
  EditorWorkflow,
  ActionDefinition,
} from "../types/workflow";
import { timezones } from "../constants/timezones";
import { useWorkflowActions } from "../dataProviders/workflowApi";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import { useDataProvider } from "../dataProviders/dataProvider";

interface CustomWorkflowEditorProps {
  initialWorkflow?: EditorWorkflow;
  availableActions: ActionDefinition[];
  onSave: (workflow: EditorWorkflow) => void;
  onDelete: () => void;
  isCustomerOrg?: boolean;
  organizationSlackChannelName?: string; // used in channel input fields for customer orgs
}

interface SortableActionItemWorkflow
  extends Omit<EditorWorkflow, "id" | "organization_id"> {
  id: string;
  organization_id: string;
}

interface SortableActionItemProps {
  action: WorkflowAction;
  actionIndex: number;
  onUpdate: (index: number, updates: Partial<WorkflowAction>) => void;
  onRemove: (index: number) => void;
  availableActions: CustomWorkflowEditorProps["availableActions"];
  workflow: SortableActionItemWorkflow;
  isNew?: boolean;
  isCustomerOrg?: boolean;
  organizationSlackChannelName?: string; // used in channel input fields for customer orgs
}

function SortableActionItem({
  action,
  actionIndex,
  onUpdate,
  onRemove,
  availableActions,
  workflow,
  isNew,
  isCustomerOrg,
  organizationSlackChannelName,
}: SortableActionItemProps) {
  const theme = useTheme();
  const workflowActions = useWorkflowActions();
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: action.id });
  const [expanded, setExpanded] = useState(isNew);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [previewResult, setPreviewResult] = useState<any>(null);
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [previewError, setPreviewError] = useState<string | null>(null);
  const [previewStartTime, setPreviewStartTime] = useState<number | null>(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isLocalDirty, setIsLocalDirty] = useState(
    action.id.startsWith("temp-")
  );

  const handleLocalUpdate = (updates: Partial<WorkflowAction>) => {
    setIsLocalDirty(true);
    onUpdate(actionIndex, updates);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isPreviewLoading && previewStartTime) {
      timer = setInterval(() => {
        setElapsedTime(Math.floor((Date.now() - previewStartTime) / 1000));
      }, 1000);
    } else {
      setElapsedTime(0);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [isPreviewLoading, previewStartTime]);

  const handlePreview = async () => {
    setIsPreviewLoading(true);
    setPreviewError(null);
    setPreviewResult(null);
    setPreviewStartTime(Date.now());
    setShowPreview(true);

    try {
      const data = await workflowActions.previewAction(
        workflow.organization_id,
        workflow.id,
        action.id
      );
      setPreviewResult(data);
    } catch (error) {
      console.error("Error during preview:", error);
      setPreviewError(
        error instanceof Error
          ? error.message
          : "An error occurred during preview"
      );
    } finally {
      setIsPreviewLoading(false);
      setPreviewStartTime(null);
    }
  };

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const selectedActionType = availableActions.find(
    (type) => type.kind === action.kind
  );

  const getPreviewTooltip = () => {
    if (isLocalDirty) {
      return "Please save your changes before previewing";
    }

    const isSlackAction =
      action.kind === "send_slack_message" ||
      action.kind === "send_slack_direct_message";

    return isSlackAction
      ? "Preview action"
      : "Preview is only available for Slack message actions";
  };

  const isPreviewDisabled = () => {
    const isSlackAction =
      action.kind === "send_slack_message" ||
      action.kind === "send_slack_direct_message";

    return isLocalDirty || !isSlackAction;
  };

  return (
    <>
      <Card
        ref={setNodeRef}
        style={style}
        sx={{
          ...getGlassStyles(theme, { opacity: 0.1 }),
          cursor: isDragging ? "grabbing" : undefined,
        }}
      >
        <Accordion
          expanded={expanded}
          onChange={() => {
            setExpanded(!expanded);
          }}
          disableGutters
          elevation={0}
          sx={{
            backgroundColor: "transparent",
            "&:before": {
              display: "none",
            },
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              padding: 2,
              "& .MuiAccordionSummary-content": {
                margin: 0,
              },
            }}
          >
            <Stack direction="row" spacing={2} alignItems="center" width="100%">
              <Box {...attributes} {...listeners}>
                <ActionButton
                  icon={<DragHandleIcon />}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.stopPropagation();
                  }}
                />
              </Box>
              <Box flex={1}>
                <TextField
                  fullWidth
                  size="small"
                  label="Action Name"
                  value={action.name}
                  onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                    e.stopPropagation();
                  }}
                  onChange={(e) => {
                    handleLocalUpdate({
                      name: e.target.value,
                    });
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: alpha(
                        theme.palette.background.paper,
                        0.1
                      ),
                    },
                  }}
                />
              </Box>
              <div onClick={(e) => e.stopPropagation()}>
                <ActionButton
                  icon={<VisibilityIcon />}
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handlePreview();
                  }}
                  tooltip={getPreviewTooltip()}
                  disabled={isPreviewDisabled()}
                  testId="preview-action-button"
                />
              </div>
              <ActionButton
                icon={<DeleteIcon />}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.stopPropagation();
                  setShowDeleteConfirm(true);
                }}
                color={theme.palette.error.main}
                tooltip="Remove action"
                testId="remove-action-button"
              />
            </Stack>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: 2, pt: 0 }}>
            <Stack spacing={2}>
              <FormControl fullWidth size="small">
                <InputLabel id={`action-type-label-${actionIndex}`}>
                  Action Type
                </InputLabel>
                <Select
                  labelId={`action-type-label-${actionIndex}`}
                  value={action.kind}
                  onChange={(e) => {
                    handleLocalUpdate({
                      kind: e.target.value,
                    });
                  }}
                  label="Action Type"
                  sx={{
                    backgroundColor: alpha(theme.palette.background.paper, 0.1),
                  }}
                >
                  {availableActions.map((type) => (
                    <MenuItem
                      key={type.kind}
                      value={type.kind}
                      disabled={type.disabled}
                    >
                      {type.name} {type.disabled ? "(Coming Soon)" : ""}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {selectedActionType && (
                <ActionInputFields
                  action={action}
                  actionType={selectedActionType}
                  onUpdate={(updates) => {
                    handleLocalUpdate(updates);
                  }}
                  workflow={workflow}
                  actionIndex={actionIndex}
                  availableActions={availableActions}
                  isCustomerOrg={isCustomerOrg}
                  organizationSlackChannelName={organizationSlackChannelName}
                />
              )}
            </Stack>
          </AccordionDetails>
        </Accordion>
      </Card>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={showDeleteConfirm}
        onClose={() => setShowDeleteConfirm(false)}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
            backgroundImage: "none",
          },
        }}
      >
        <DialogTitle>Remove Action</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to remove this action? This cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDeleteConfirm(false)}>Cancel</Button>
          <Button
            onClick={() => {
              onRemove(actionIndex);
              setShowDeleteConfirm(false);
            }}
            color="error"
            variant="contained"
          >
            Remove
          </Button>
        </DialogActions>
      </Dialog>

      {/* Preview Dialog */}
      <Dialog
        open={showPreview}
        onClose={(e: React.SyntheticEvent, reason: string) => {
          if (reason === "backdropClick" || reason === "escapeKeyDown") {
            e.preventDefault();
            e.stopPropagation();
          }
          if (!isPreviewLoading) {
            setShowPreview(false);
            setPreviewResult(null);
            setPreviewError(null);
          }
        }}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
            backgroundImage: "none",
          },
          onClick: (e: React.MouseEvent) => {
            e.preventDefault();
            e.stopPropagation();
          },
        }}
      >
        <DialogTitle>
          <Stack direction="row" alignItems="center" spacing={1}>
            Action Preview
            {isPreviewLoading && (
              <Typography variant="body2" color="text.secondary">
                ({elapsedTime}s)
              </Typography>
            )}
          </Stack>
        </DialogTitle>
        <DialogContent>
          {isPreviewLoading ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                p: 3,
                gap: 2,
              }}
            >
              <CircularProgress />
              <Typography variant="body2" color="text.secondary" align="center">
                Generating preview... This may take up to 30 seconds.
                {elapsedTime > 15 && (
                  <Box component="span" display="block" mt={1}>
                    Still working on it, thanks for your patience!
                  </Box>
                )}
              </Typography>
            </Box>
          ) : previewResult ? (
            <Box sx={{ mt: 2 }}>
              {(action.kind === "send_slack_message" ||
                action.kind === "send_slack_direct_message") && (
                <Card
                  sx={{ bgcolor: alpha(theme.palette.background.paper, 0.1) }}
                >
                  <CardContent>
                    <Stack spacing={2}>
                      <Typography variant="subtitle2" color="primary">
                        Message Preview
                      </Typography>
                      <Box sx={{ whiteSpace: "pre-wrap" }}>
                        {previewResult.preview.message}
                      </Box>
                      {previewResult.preview.blocks && (
                        <Box sx={{ mt: 2 }}>
                          <Typography
                            variant="subtitle2"
                            color="primary"
                            gutterBottom
                          >
                            Message Blocks
                          </Typography>
                          <pre
                            style={{
                              overflow: "auto",
                              backgroundColor: alpha(
                                theme.palette.background.paper,
                                0.05
                              ),
                              padding: theme.spacing(2),
                              borderRadius: theme.shape.borderRadius,
                            }}
                          >
                            {JSON.stringify(
                              previewResult.preview.blocks,
                              null,
                              2
                            )}
                          </pre>
                        </Box>
                      )}
                    </Stack>
                  </CardContent>
                </Card>
              )}
            </Box>
          ) : (
            <Box sx={{ p: 2 }}>
              <Typography color="error" align="center">
                {previewError || "Failed to load preview. Please try again."}
              </Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowPreview(false);
              setPreviewResult(null);
              setPreviewError(null);
            }}
            disabled={isPreviewLoading}
          >
            Close
          </Button>
          {!isPreviewLoading && previewError && (
            <Button
              onClick={() => handlePreview()}
              variant="contained"
              color="primary"
            >
              Try Again
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

interface ActionInputFieldsProps {
  action: WorkflowAction;
  actionType: CustomWorkflowEditorProps["availableActions"][0];
  onUpdate: (updates: Partial<WorkflowAction>) => void;
  workflow: EditorWorkflow;
  actionIndex: number;
  availableActions: CustomWorkflowEditorProps["availableActions"];
  isCustomerOrg?: boolean;
  organizationSlackChannelName?: string; // used in channel input fields for customer orgs
}

function ActionInputFields({
  action,
  actionType,
  onUpdate,
  workflow,
  actionIndex,
  availableActions,
  isCustomerOrg,
  organizationSlackChannelName,
}: ActionInputFieldsProps) {
  const theme = useTheme();
  const inputs = actionType.inputs;
  const [selectingOutputForKey, setSelectingOutputForKey] = useState<
    string | null
  >(null);

  const handleInputChange = (key: string, value: any) => {
    const existingValue = (action.inputs || {})[key];
    const isStepOutput = existingValue?.type === "step_output";

    // If it's a step output, preserve the connection while updating the additional content
    if (isStepOutput) {
      onUpdate({
        inputs: {
          ...(action.inputs || {}),
          [key]: {
            ...existingValue,
            additionalContent: value,
          },
        },
      });
    } else {
      onUpdate({
        inputs: {
          ...(action.inputs || {}),
          [key]: value,
        },
      });
    }
  };

  interface OutputOption {
    label: string;
    value: string;
    type: "string" | "number" | "boolean" | "object" | "array";
    description: string;
  }

  const getAvailableOutputs = (currentActionIndex: number): OutputOption[] => {
    const previousActions = workflow.actions.slice(0, currentActionIndex);
    return previousActions.flatMap((prevAction) => {
      const actionDef = availableActions.find(
        (a: { kind: string }) => a.kind === prevAction.kind
      );
      if (!actionDef?.outputs) return [];

      return Object.entries(actionDef.outputs).map(([outputKey, output]) => ({
        label: `${prevAction.name} → ${outputKey}`,
        value: `${prevAction.id}.${outputKey}`,
        type: output.type,
        description: output.description,
      }));
    });
  };

  const availableOutputs = getAvailableOutputs(actionIndex);

  const renderStepOutputSelector = (inputKey: string, currentValue: string) => {
    if (!availableOutputs.length) return null;

    return (
      <Box sx={{ mt: 1 }}>
        {selectingOutputForKey === inputKey ? (
          <FormControl fullWidth size="small">
            <InputLabel>Use Output From Step</InputLabel>
            <Select
              value={currentValue || ""}
              onChange={(e) => {
                handleInputChange(
                  inputKey,
                  e.target.value
                    ? {
                        type: "step_output",
                        value: e.target.value,
                      }
                    : ""
                );
                setSelectingOutputForKey(null);
              }}
              label="Use Output From Step"
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {availableOutputs.map((output: OutputOption) => (
                <MenuItem key={output.value} value={output.value}>
                  <Stack>
                    <Typography variant="body2">{output.label}</Typography>
                    <Typography variant="caption" color="text.secondary">
                      {output.description}
                    </Typography>
                  </Stack>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <Button
            startIcon={<LinkIcon />}
            size="small"
            onClick={() => setSelectingOutputForKey(inputKey)}
          >
            Use Output From Previous Step
          </Button>
        )}
      </Box>
    );
  };

  return (
    <Stack spacing={2}>
      {Object.entries(inputs).map(([key, input]) => {
        // Instead of skipping rendering, we'll disable the inputs for customer orgs
        const isDisabled = isCustomerOrg && input.disabledForCustomer;

        const value = (action.inputs || {})[key];
        const isStepOutput = value?.type === "step_output";
        const rawValue = isStepOutput
          ? value.additionalContent || ""
          : (value ?? input.default ?? (input.type === "array" ? [] : ""));

        const inputField = (() => {
          switch (input.type) {
            case "string":
            case "text":
              return (
                <TextField
                  key={key}
                  fullWidth
                  size="small"
                  label={
                    isStepOutput
                      ? `Additional Content (${input.label})`
                      : input.label
                  }
                  value={
                    isDisabled && !rawValue && input.disabledPlaceholder
                      ? input.disabledPlaceholder.replace(
                          "{{channel_name}}", // Simple mustache placeholder replacement
                          organizationSlackChannelName || ""
                        )
                      : rawValue
                  }
                  multiline={input.type === "text"}
                  rows={input.type === "text" ? 3 : undefined}
                  onChange={(e) => {
                    handleInputChange(key, e.target.value);
                  }}
                  disabled={isDisabled}
                  required={input.required}
                  helperText={
                    isDisabled
                      ? ""
                      : isStepOutput
                        ? "Add content to combine with the step output"
                        : input.description
                  }
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: alpha(
                        theme.palette.background.paper,
                        0.1
                      ),
                    },
                  }}
                />
              );

            case "enum":
              return (
                <FormControl
                  key={key}
                  fullWidth
                  size="small"
                  required={input.required}
                >
                  <InputLabel>{input.label}</InputLabel>
                  <Select
                    value={value || ""}
                    label={input.label}
                    onChange={(e) => {
                      handleInputChange(key, e.target.value);
                    }}
                    sx={{
                      backgroundColor: alpha(
                        theme.palette.background.paper,
                        0.1
                      ),
                    }}
                  >
                    {input.options?.map((option) => (
                      <MenuItem key={option} value={option}>
                        {toTitleCase(String(option))}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{input.description}</FormHelperText>
                </FormControl>
              );

            case "select":
              return (
                <DynamicSelect
                  key={key}
                  input={{ ...input, organizationId: workflow.organization_id }}
                  value={value}
                  onChange={(newValue) => handleInputChange(key, newValue)}
                  required={input.required}
                />
              );

            case "number":
              return (
                <TextField
                  key={key}
                  fullWidth
                  size="small"
                  type="number"
                  label={input.label}
                  value={value}
                  onChange={(e) => {
                    handleInputChange(key, parseFloat(e.target.value));
                  }}
                  required={input.required}
                  helperText={input.description}
                  inputProps={{
                    min: input.min,
                    max: input.max,
                    step: input.step,
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      backgroundColor: alpha(
                        theme.palette.background.paper,
                        0.1
                      ),
                    },
                  }}
                />
              );

            case "array":
              const arrayValue = Array.isArray(value) ? value : [];
              return (
                <Box key={key}>
                  <Typography variant="subtitle2" gutterBottom>
                    {input.label}
                  </Typography>
                  <Stack spacing={1}>
                    {arrayValue.map((item, index) => (
                      <Stack key={index} direction="row" spacing={1}>
                        <TextField
                          fullWidth
                          size="small"
                          value={item}
                          onChange={(e) => {
                            const newArray = [...arrayValue];
                            newArray[index] = e.target.value;
                            handleInputChange(key, newArray);
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              backgroundColor: alpha(
                                theme.palette.background.paper,
                                0.1
                              ),
                            },
                          }}
                        />
                        <Button
                          size="small"
                          color="error"
                          onClick={() => {
                            const newArray = arrayValue.filter(
                              (_, i) => i !== index
                            );
                            handleInputChange(key, newArray);
                          }}
                        >
                          <DeleteIcon />
                        </Button>
                      </Stack>
                    ))}
                    <Button
                      size="small"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        handleInputChange(key, [...arrayValue, ""]);
                      }}
                    >
                      Add {input.label}
                    </Button>
                  </Stack>
                  <FormHelperText>{input.description}</FormHelperText>
                </Box>
              );

            default:
              return null;
          }
        })();

        return (
          <Box key={key}>
            {isStepOutput && (
              <Chip
                size="small"
                icon={<LinkIcon />}
                label={`Using output: ${availableOutputs.find((o) => o.value === value.value)?.label || value.value}`}
                onDelete={() => {
                  onUpdate({
                    inputs: {
                      ...(action.inputs || {}),
                      [key]: "",
                    },
                  });
                }}
                sx={{ mb: 1 }}
              />
            )}
            {inputField}
            {input.supportsStepOutputs &&
              !isStepOutput &&
              renderStepOutputSelector(key, "")}
          </Box>
        );
      })}
    </Stack>
  );
}

interface CronSchedule {
  type: "daily" | "weekly" | "custom" | "once";
  time: string;
  days: string[];
  date?: string;
}

interface CronSchedulePickerProps {
  value: string;
  onChange: (schedule: string) => void;
}

function CronSchedulePicker({ value, onChange }: CronSchedulePickerProps) {
  const theme = useTheme();

  // Parse initial cron expression
  const parseCronExpression = (cronExp: string): CronSchedule | null => {
    // Check if it's an ISO 8601 datetime string (once schedule)
    if (cronExp.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/)) {
      const date = cronExp.split("T")[0];
      const time = cronExp.split("T")[1];
      return {
        type: "once",
        date: date,
        time: time,
        days: [],
      };
    }

    const parts = cronExp.split(" ");
    if (parts.length !== 5) return null;

    const [minutes, hours, dayOfMonth, month, dayOfWeek] = parts;

    // Check if it's a daily schedule (e.g., "0 12 * * *")
    if (dayOfMonth === "*" && month === "*" && dayOfWeek === "*") {
      return {
        type: "daily",
        time: `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`,
        days: [],
      };
    }

    // Check if it's a weekly schedule (e.g., "0 12 * * 1,2,3,4,5")
    if (dayOfMonth === "*" && month === "*" && dayOfWeek !== "*") {
      return {
        type: "weekly",
        time: `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`,
        days: dayOfWeek.split(","),
      };
    }

    return {
      type: "custom",
      time: "",
      days: [],
    };
  };

  // Initialize state based on the provided cron expression
  const initialSchedule = parseCronExpression(value);
  const [scheduleType, setScheduleType] = useState<
    "daily" | "weekly" | "custom" | "once"
  >(initialSchedule?.type ?? "daily");
  const [time, setTime] = useState(
    initialSchedule ? initialSchedule.time : "12:00"
  );
  const [selectedDays, setSelectedDays] = useState<string[]>(
    initialSchedule?.type === "weekly"
      ? initialSchedule.days
      : ["1", "2", "3", "4", "5"]
  );
  const [onceDateTime, setOnceDateTime] = useState<Dayjs | null>(
    scheduleType === "once" && initialSchedule?.date && initialSchedule?.time
      ? dayjs(`${initialSchedule.date}T${initialSchedule.time}`)
      : null
  );

  const handleTimeChange = (newTime: string) => {
    setTime(newTime);
    updateCronExpression(newTime, scheduleType, selectedDays);
  };

  const handleOnceDateTimeChange = (newDateTime: Dayjs | null) => {
    setOnceDateTime(newDateTime);
    if (newDateTime) {
      // Format as ISO 8601 string
      const isoString = newDateTime.format("YYYY-MM-DDTHH:mm:ss");
      onChange(isoString);
    }
  };

  const handleDaysChange = (
    _: React.MouseEvent<HTMLElement>,
    newDays: string | null
  ) => {
    if (newDays) {
      const updatedDays = selectedDays.includes(newDays)
        ? selectedDays.filter((d) => d !== newDays)
        : [...selectedDays, newDays];

      if (updatedDays.length > 0) {
        setSelectedDays(updatedDays);
        updateCronExpression(time, scheduleType, updatedDays);
      }
    }
  };

  const handleScheduleTypeChange = (
    _: React.MouseEvent<HTMLElement>,
    newType: "daily" | "weekly" | "custom" | "once"
  ) => {
    if (newType !== null) {
      setScheduleType(newType);
      if (newType === "daily") {
        updateCronExpression(time, newType, ["*"]);
      } else if (newType === "weekly") {
        updateCronExpression(time, newType, selectedDays);
      } else if (newType === "once") {
        const now = dayjs();
        setOnceDateTime(now);
        onChange(now.format("YYYY-MM-DDTHH:mm:ss"));
      } else {
        onChange(value); // Keep existing value for custom
      }
    }
  };

  const updateCronExpression = (
    timeStr: string,
    type: string,
    days: string[]
  ) => {
    const [hours, minutes] = timeStr.split(":");
    let cronExp = "";

    if (type === "daily") {
      cronExp = `${minutes} ${hours} * * *`;
    } else if (type === "weekly") {
      cronExp = `${minutes} ${hours} * * ${days.join(",")}`;
    }

    onChange(cronExp);
  };

  return (
    <Stack spacing={2}>
      <ToggleButtonGroup
        value={scheduleType}
        exclusive
        onChange={handleScheduleTypeChange}
        aria-label="schedule type"
        fullWidth
        sx={{ mb: 2 }}
      >
        <ToggleButton value="once" aria-label="once">
          Once
        </ToggleButton>
        <ToggleButton value="daily" aria-label="daily">
          Daily
        </ToggleButton>
        <ToggleButton value="weekly" aria-label="weekly">
          Weekly
        </ToggleButton>
        <ToggleButton value="custom" aria-label="custom">
          Custom
        </ToggleButton>
      </ToggleButtonGroup>

      {scheduleType === "once" ? (
        <DateTimePicker
          label="Schedule Date & Time"
          value={onceDateTime}
          onChange={(newDate: Dayjs | null) => {
            handleOnceDateTimeChange(newDate);
          }}
          slotProps={{
            textField: {
              fullWidth: true,
              sx: {
                "& .MuiOutlinedInput-root": {
                  backgroundColor: alpha(theme.palette.background.paper, 0.1),
                },
              },
            },
          }}
        />
      ) : (
        scheduleType !== "custom" && (
          <>
            <TextField
              label="Time"
              type="time"
              value={time}
              onChange={(e) => {
                handleTimeChange(e.target.value);
              }}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: alpha(theme.palette.background.paper, 0.1),
                },
              }}
            />

            {scheduleType === "weekly" && (
              <>
                <Typography variant="subtitle2" color="primary" sx={{ mt: 2 }}>
                  Select Days
                </Typography>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ flexWrap: "wrap", gap: 1 }}
                >
                  {[
                    { value: "1", label: "Mon" },
                    { value: "2", label: "Tue" },
                    { value: "3", label: "Wed" },
                    { value: "4", label: "Thu" },
                    { value: "5", label: "Fri" },
                    { value: "6", label: "Sat" },
                    { value: "0", label: "Sun" },
                  ].map((day) => (
                    <ToggleButton
                      key={day.value}
                      value={day.value}
                      selected={selectedDays.includes(day.value)}
                      onChange={handleDaysChange}
                      aria-label={day.label.toLowerCase()}
                    >
                      {day.label}
                    </ToggleButton>
                  ))}
                </Stack>
              </>
            )}
          </>
        )
      )}

      {scheduleType === "custom" && (
        <TextField
          fullWidth
          label="Custom Cron Schedule"
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          helperText="Example: 0 12 * * 1-5 (runs at noon on weekdays)"
          sx={{
            "& .MuiOutlinedInput-root": {
              backgroundColor: alpha(theme.palette.background.paper, 0.1),
            },
          }}
        />
      )}

      <FormHelperText>
        {scheduleType === "daily"
          ? "Runs every day at the specified time"
          : scheduleType === "weekly"
            ? "Runs on selected days at the specified time"
            : scheduleType === "once"
              ? "Runs once at the specified time"
              : "Use custom cron expression for more complex schedules"}
      </FormHelperText>
    </Stack>
  );
}

interface ActionButtonProps {
  icon: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  color?: string;
  tooltip?: string;
  disabled?: boolean;
  testId?: string;
}

const ActionButton = ({
  icon,
  onClick,
  color,
  tooltip,
  disabled,
  testId,
}: ActionButtonProps) => (
  <Tooltip title={tooltip || ""}>
    <span>
      <IconButton
        onClick={onClick}
        disabled={disabled}
        aria-label={tooltip || ""}
        data-testid={testId}
        sx={{
          color: disabled ? "text.disabled" : color || "inherit",
          "&:hover": {
            backgroundColor: disabled
              ? "transparent"
              : alpha(color || "#fff", 0.1),
          },
        }}
      >
        {icon}
      </IconButton>
    </span>
  </Tooltip>
);

const toTitleCase = (str: string): string => {
  if (!str.trim()) return str;
  return str
    .split(/[_\s]/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

// Export for testing
export { CronSchedulePicker };

function useDataSource(source: string | undefined, orgId: string | undefined) {
  const [data, setData] = useState<Array<any>>([]);
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();

  const fetchData = useCallback(async () => {
    if (!source || !orgId) return;

    setLoading(true);
    try {
      const response = await dataProvider.getList(
        `organizations/${orgId}/task-lists`,
        {
          pagination: { page: 1, perPage: 100 },
          sort: { field: "created_at", order: "DESC" },
          filter: {},
        }
      );
      setData(response.data);
    } catch (error) {
      console.error(`Error fetching ${source}:`, error);
    } finally {
      setLoading(false);
    }
  }, [source, orgId, dataProvider]);

  useEffect(() => {
    if (source === "task_lists") {
      fetchData();
    } else {
      setData([]);
    }
  }, [source, fetchData]);

  return { data, loading };
}

// Create a new component for the select input
function DynamicSelect({
  input,
  value,
  onChange,
  required,
}: {
  input: any;
  value: any;
  onChange: (value: any) => void;
  required?: boolean;
}) {
  const theme = useTheme();
  const { data, loading } = useDataSource(
    input.dataSource,
    input.organizationId
  );

  const valueExists = data.some(
    (item: any) => item[input.valueField] === value
  );

  return (
    <FormControl fullWidth size="small" required={required}>
      <InputLabel>{input.label}</InputLabel>
      <Select
        value={valueExists ? value : ""}
        label={input.label}
        onChange={(e) => onChange(e.target.value)}
        sx={{
          backgroundColor: alpha(theme.palette.background.paper, 0.1),
        }}
      >
        {loading ? (
          <MenuItem disabled>Loading...</MenuItem>
        ) : (
          data.map((item: any) => (
            <MenuItem
              key={item[input.valueField]}
              value={item[input.valueField]}
            >
              {item[input.labelField]}
            </MenuItem>
          ))
        )}
      </Select>
      <FormHelperText>{input.description}</FormHelperText>
    </FormControl>
  );
}

export function CustomWorkflowEditor({
  initialWorkflow,
  availableActions,
  onSave,
  onDelete,
  isCustomerOrg,
  organizationSlackChannelName,
}: CustomWorkflowEditorProps) {
  const theme = useTheme();

  // Define supported events
  const supportedEvents = [
    {
      name: "customer.created",
      label: "New Customer Onboarded",
      description: "Triggered when a new customer is created in the system",
      disabled: false,
    },
    {
      name: "knowledge_gap",
      label: "Knowledge Gap Detected",
      description:
        "Triggered when AI detects a gap in knowledge that needs to be addressed",
      disabled: true,
    },
    {
      name: "escalation_needed",
      label: "Escalation Required",
      description: "Triggered when an issue requires human escalation",
      disabled: true,
    },
    {
      name: "negative_sentiment",
      label: "Negative Sentiment Detected",
      description:
        "Triggered when negative sentiment is detected in customer interactions",
      disabled: true,
    },
  ];

  const [workflow, setWorkflow] = useState<EditorWorkflow>({
    id: initialWorkflow?.id,
    organization_id: initialWorkflow?.organization_id,
    name: initialWorkflow?.name || "",
    description: initialWorkflow?.description || "",
    trigger_event:
      initialWorkflow?.trigger_event?.type === "schedule"
        ? {
            type: "schedule",
            schedule: initialWorkflow.trigger_event.schedule || "0 12 * * 1-5",
            timezone: initialWorkflow.trigger_event.timezone || "UTC",
          }
        : {
            type: "custom_event",
            event: {
              name: supportedEvents.some(
                (e) => e.name === initialWorkflow?.trigger_event?.event?.name
              )
                ? initialWorkflow?.trigger_event?.event?.name || ""
                : "",
            },
          },
    actions: initialWorkflow?.actions || [],
  });

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      setWorkflow((prev) => {
        const oldIndex = prev.actions.findIndex(
          (action) => action.id === active.id
        );
        const newIndex = prev.actions.findIndex(
          (action) => action.id === over?.id
        );

        const reorderedActions = arrayMove(
          prev.actions,
          oldIndex,
          newIndex
        ).map((action, index) => ({
          ...action,
          sequence: index,
        }));

        return {
          ...prev,
          actions: reorderedActions,
        };
      });
    }
  };

  const handleAddAction = () => {
    const newAction: WorkflowAction = {
      id: `temp-${Date.now()}`,
      name: "New Action",
      kind: availableActions[0].kind,
      description: "",
      sequence: workflow.actions.length,
      inputs: {},
    };
    setWorkflow((prev) => ({
      ...prev,
      actions: [...prev.actions, newAction],
    }));
  };

  const handleRemoveAction = (index: number) => {
    const newActions = workflow.actions.filter((_, i) => i !== index);
    setWorkflow({ ...workflow, actions: newActions });
  };

  const handleUpdateAction = (
    index: number,
    updates: Partial<WorkflowAction>
  ) => {
    const newActions = [...workflow.actions];
    newActions[index] = { ...newActions[index], ...updates };
    setWorkflow({ ...workflow, actions: newActions });
  };

  const handleTriggerTypeChange = (type: "schedule" | "custom_event") => {
    setWorkflow({
      ...workflow,
      trigger_event:
        type === "schedule"
          ? {
              type: "schedule",
              schedule: "0 12 * * 1-5",
              timezone: workflow.trigger_event.timezone || "UTC",
            }
          : {
              type: "custom_event",
              event: { name: "" },
            },
    });
  };

  const handleScheduleChange = (schedule: string) => {
    if (workflow.trigger_event.type === "schedule") {
      setWorkflow({
        ...workflow,
        trigger_event: {
          ...workflow.trigger_event,
          schedule,
        },
      });
    }
  };

  const handleSave = () => {
    onSave(workflow);
  };

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const renderEmptyState = () => (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        py: 8,
        px: 3,
        textAlign: "center",
        backgroundColor: alpha(theme.palette.background.paper, 0.05),
        borderRadius: 2,
      }}
    >
      <AutoAwesomeIcon
        sx={{ fontSize: 48, color: "primary.main", mb: 2, opacity: 0.7 }}
      />
      <Typography variant="h6" color="primary" gutterBottom>
        No Actions Added Yet
      </Typography>
      <Typography
        variant="body2"
        color="text.secondary"
        sx={{ mb: 3, maxWidth: 400 }}
      >
        Actions are the building blocks of your workflow. Each action performs a
        specific task, and they can be chained together to create powerful
        automations.
      </Typography>
      <Button
        variant="contained"
        startIcon={<AddIcon />}
        onClick={handleAddAction}
        sx={getButtonStyles(theme)}
      >
        Add Your First Action
      </Button>
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 3,
        minHeight: 0,
        flex: 1,
        overflow: "auto",
        pb: 2,
      }}
    >
      {/* Workflow Details Section */}
      <Card
        sx={{
          ...getGlassStyles(theme),
          display: "flex",
          flexDirection: "column",
          minHeight: 0,
        }}
      >
        <Box sx={{ p: 3, flex: 1, overflow: "auto" }}>
          <Stack spacing={3}>
            <Typography variant="h6" color="primary">
              Workflow Details
            </Typography>
            <TextField
              fullWidth
              label="Name"
              value={workflow.name}
              onChange={(e) => {
                setWorkflow({ ...workflow, name: e.target.value });
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: alpha(theme.palette.background.paper, 0.1),
                },
              }}
            />
            <TextField
              fullWidth
              multiline
              rows={3}
              label="Description"
              value={workflow.description}
              onChange={(e) => {
                setWorkflow({ ...workflow, description: e.target.value });
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  backgroundColor: alpha(theme.palette.background.paper, 0.1),
                },
              }}
            />

            <Box>
              <Typography variant="subtitle2" color="primary" gutterBottom>
                Trigger
              </Typography>
              <Stack spacing={2}>
                <ToggleButtonGroup
                  value={workflow.trigger_event.type}
                  exclusive
                  onChange={(_, value) =>
                    value && handleTriggerTypeChange(value)
                  }
                  fullWidth
                >
                  <ToggleButton value="custom_event">Custom Event</ToggleButton>
                  <ToggleButton value="schedule">Schedule</ToggleButton>
                </ToggleButtonGroup>

                {workflow.trigger_event.type === "schedule" ? (
                  <Stack spacing={2}>
                    <CronSchedulePicker
                      value={workflow.trigger_event.schedule || ""}
                      onChange={handleScheduleChange}
                    />
                    <FormControl fullWidth>
                      <InputLabel id="timezone-select-label">
                        Timezone
                      </InputLabel>
                      <Select
                        labelId="timezone-select-label"
                        id="timezone-select"
                        value={
                          workflow.trigger_event.type === "schedule"
                            ? workflow.trigger_event.timezone || "UTC"
                            : "UTC"
                        }
                        onChange={(e) => {
                          if (workflow.trigger_event.type === "schedule") {
                            setWorkflow({
                              ...workflow,
                              trigger_event: {
                                ...workflow.trigger_event,
                                timezone: e.target.value,
                              },
                            });
                          }
                        }}
                        label="Timezone"
                      >
                        {timezones.map((tz) => (
                          <MenuItem key={tz.value} value={tz.value}>
                            {tz.label}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>
                        All times will be converted to this timezone
                      </FormHelperText>
                    </FormControl>
                  </Stack>
                ) : (
                  <FormControl fullWidth>
                    <InputLabel>Event Type</InputLabel>
                    <Select
                      value={workflow.trigger_event.event?.name || ""}
                      onChange={(e) => {
                        setWorkflow({
                          ...workflow,
                          trigger_event: {
                            ...workflow.trigger_event,
                            event: { name: e.target.value },
                          },
                        });
                      }}
                      label="Event Type"
                    >
                      {supportedEvents.map((event) => (
                        <MenuItem
                          key={event.name}
                          value={event.name}
                          disabled={event.disabled}
                        >
                          <Stack spacing={1}>
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <Typography variant="body2">
                                {event.label}
                              </Typography>
                              {event.disabled && (
                                <Chip
                                  label="Coming Soon"
                                  size="small"
                                  color="primary"
                                  variant="outlined"
                                  sx={{
                                    height: 20,
                                    "& .MuiChip-label": {
                                      px: 1,
                                      fontSize: "0.625rem",
                                    },
                                  }}
                                />
                              )}
                            </Stack>
                            <Typography
                              variant="caption"
                              color="text.secondary"
                            >
                              {event.description}
                            </Typography>
                          </Stack>
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText>
                      Select the event that will trigger this workflow
                    </FormHelperText>
                  </FormControl>
                )}
              </Stack>
            </Box>
          </Stack>
        </Box>
      </Card>

      {/* Actions Section */}
      <Card
        sx={{
          ...getGlassStyles(theme),
          display: "flex",
          flexDirection: "column",
          minHeight: 0,
          flex: 1,
        }}
      >
        <Box sx={{ p: 3, flex: 1, overflow: "auto" }}>
          <Stack spacing={3}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6" color="primary">
                Actions
              </Typography>
              {workflow.actions.length > 0 && (
                <Button
                  startIcon={<AddIcon />}
                  onClick={handleAddAction}
                  variant="contained"
                  color="primary"
                  sx={getButtonStyles(theme)}
                >
                  Add Action
                </Button>
              )}
            </Stack>

            {workflow.actions.length === 0 ? (
              renderEmptyState()
            ) : (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext
                  items={workflow.actions.map((a) => a.id as UniqueIdentifier)}
                  strategy={verticalListSortingStrategy}
                >
                  <Stack spacing={2}>
                    {workflow.actions.map((action, index) => {
                      const workflowWithRequiredFields: SortableActionItemWorkflow =
                        {
                          ...workflow,
                          id: workflow.id || `temp-${Date.now()}`,
                          organization_id:
                            workflow.organization_id ||
                            `temp-org-${Date.now()}`,
                        };
                      return (
                        <SortableActionItem
                          key={action.id}
                          action={action}
                          actionIndex={index}
                          onUpdate={handleUpdateAction}
                          onRemove={handleRemoveAction}
                          availableActions={availableActions}
                          workflow={workflowWithRequiredFields}
                          isNew={index === workflow.actions.length - 1}
                          isCustomerOrg={isCustomerOrg}
                          organizationSlackChannelName={
                            organizationSlackChannelName
                          }
                        />
                      );
                    })}
                  </Stack>
                </SortableContext>
              </DndContext>
            )}
          </Stack>
        </Box>
      </Card>

      {/* Save and Delete Buttons */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 2,
          mt: "auto",
          pt: 2,
        }}
      >
        <Button
          variant="contained"
          color="error"
          onClick={() => {
            setIsDeleteDialogOpen(true);
          }}
          disabled={!workflow.name}
          startIcon={<DeleteIcon />}
          sx={getButtonStyles(theme)}
        >
          Delete Workflow
        </Button>
        <Button
          variant="contained"
          onClick={handleSave}
          disabled={!workflow.name || workflow.actions.length === 0}
          startIcon={<SaveIcon />}
          sx={getButtonStyles(theme)}
        >
          Save
        </Button>
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={isDeleteDialogOpen}
        onClose={() => {
          setIsDeleteDialogOpen(false);
        }}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.background.paper,
            backgroundImage: "none",
          },
        }}
      >
        <DialogTitle>Delete Workflow</DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this workflow? This action cannot be
            undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setIsDeleteDialogOpen(false);
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              onDelete();
              setIsDeleteDialogOpen(false);
            }}
            color="error"
            variant="contained"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
