import {
  Create,
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  required,
  useRecordContext,
  useNotify,
  useRedirect,
  useResourceContext,
} from "react-admin";
import {
  Box,
  Typography,
  Link,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ReactJson from "@microlink/react-json-view";
import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const SlackAppTitle = () => {
  const record = useRecordContext();
  return <span>Slack App: {record ? record.name : ""}</span>;
};

const statusChoices = [
  { id: "active", name: "Active" },
  { id: "inactive", name: "Inactive" },
  { id: "pending", name: "Pending" },
];

export const SlackAppCreate = () => {
  const notify = useNotify();
  const redirect = useRedirect();
  const resource = useResourceContext();
  const { getAccessTokenSilently } = useAuth0();
  const [manifest, setManifest] = useState<Record<string, any> | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadManifest = async () => {
      try {
        // Get auth token using Auth0
        let token;
        try {
          token = await getAccessTokenSilently();
        } catch (authError) {
          console.warn("Failed to get auth token:", authError);
          throw new Error("Authentication failed. Please log in again.");
        }

        // Fetch the manifest template directly with Auth0 token
        const response = await fetch(
          `${import.meta.env.VITE_API_URL}/api/slack_manifest_template`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            credentials: "include",
          }
        );

        if (!response.ok) {
          throw new Error(
            `HTTP error ${response.status}: ${response.statusText}`
          );
        }

        const data = await response.json();
        setManifest(data);
      } catch (err) {
        console.error("Error loading manifest template:", err);
        setError(
          err instanceof Error
            ? err.message
            : "Failed to load manifest template"
        );
      } finally {
        setLoading(false);
      }
    };

    loadManifest();
  }, [getAccessTokenSilently]);

  return (
    <Create
      mutationOptions={{
        onSuccess: () => {
          notify("Slack app created successfully");
          redirect("list", resource);
        },
        onError: (error: unknown) => {
          console.error("Error creating Slack app:", error);
          notify("Error creating Slack app", { type: "error" });
        },
      }}
    >
      <SlackAppForm
        manifestTemplate={manifest}
        loading={loading}
        error={error}
      />
    </Create>
  );
};

export const SlackAppEdit = () => (
  <Edit title={<SlackAppTitle />}>
    <SlackAppForm />
  </Edit>
);

const SlackAppHelpText = ({
  manifestTemplate,
  loading,
  error,
}: {
  manifestTemplate?: Record<string, any> | null;
  loading?: boolean;
  error?: string | null;
}) => (
  <Box
    sx={{
      mb: 3,
      p: 2,
      bgcolor: "#1E293B",
      borderRadius: 1,
      border: "1px solid #2D3748",
    }}
  >
    <Typography
      variant="body2"
      paragraph
      color="text.secondary"
      sx={{ color: "#CBD5E0" }}
    >
      View our{" "}
      <Link
        href="https://www.notion.so/Creating-Slack-Apps-1b3ffd7e24958048afd9de2553e5da5e"
        target="_blank"
        rel="noopener"
        sx={{ color: "#90CDF4" }}
      >
        Slack App Setup SOP
      </Link>{" "}
      for detailed instructions.
    </Typography>

    {manifestTemplate && (
      <Accordion
        sx={{
          bgcolor: "#1E293B",
          color: "#CBD5E0",
          "&.MuiPaper-root": {
            bgcolor: "#1E293B",
          },
          "&:before": {
            display: "none",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ color: "#90CDF4" }} />}
          sx={{
            padding: 0,
            "& .MuiAccordionSummary-content": { margin: "0 !important" },
          }}
        >
          <Typography variant="body2" sx={{ color: "#90CDF4" }}>
            View Sample Slack App Manifest
          </Typography>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: 0, mt: 1 }}>
          <div style={{ maxHeight: "400px", overflow: "auto" }}>
            <ReactJson
              src={manifestTemplate}
              theme="twilight"
              displayDataTypes={false}
              enableClipboard={true}
              collapsed={1}
              style={{
                backgroundColor: "#1E293B",
                padding: "16px",
                borderRadius: "4px",
                fontSize: "14px",
              }}
            />
          </div>
        </AccordionDetails>
      </Accordion>
    )}

    {loading && (
      <Box display="flex" alignItems="center" mt={2}>
        <CircularProgress size={24} sx={{ color: "#90CDF4" }} />
        <Typography variant="body2" sx={{ ml: 2, color: "#CBD5E0" }}>
          Loading manifest template...
        </Typography>
      </Box>
    )}

    {error && (
      <Typography variant="body2" sx={{ mt: 2, color: "#FC8181" }}>
        Error loading manifest template: {error}
      </Typography>
    )}
  </Box>
);

const SlackAppForm = ({
  manifestTemplate,
  loading,
  error,
}: {
  manifestTemplate?: Record<string, any> | null;
  loading?: boolean;
  error?: string | null;
}) => (
  <SimpleForm>
    <SlackAppHelpText
      manifestTemplate={manifestTemplate}
      loading={loading}
      error={error}
    />
    <TextInput
      source="name"
      validate={[required()]}
      fullWidth
      helperText="The name of your Slack app"
    />
    <TextInput
      source="client_id"
      validate={[required()]}
      fullWidth
      helperText="Client ID from your Slack app configuration"
    />
    <TextInput
      source="client_secret"
      validate={[required()]}
      fullWidth
      type="password"
      helperText="Client Secret from your Slack app configuration"
    />
    <TextInput
      source="signing_secret"
      validate={[required()]}
      fullWidth
      type="password"
      helperText="Signing Secret from your Slack app configuration"
    />
    <SelectInput
      source="status"
      choices={statusChoices}
      validate={[required()]}
      fullWidth
      helperText="Current status of the Slack app"
    />
  </SimpleForm>
);
