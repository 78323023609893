import type { RaThemeOptions } from "react-admin";
import { Admin, Resource, defaultTheme } from "react-admin";
import { UserCreate, UserEdit, UserList } from "../resources/users";
import {
  OrganizationList,
  OrganizationEdit,
  OrganizationCreate,
  OrganizationShow,
} from "../resources/organizations";
import {
  AgentSessionList,
  AgentSessionShow,
  AgentMemoryList,
} from "../resources/agent";
import { SlackAppList } from "../pages/SlackAppList";
import { SlackAppCreate, SlackAppEdit } from "../pages/SlackAppForm";
import { SlackAppShow } from "../pages/SlackAppShow";
import { People, Business, Memory, Chat, Apps } from "@mui/icons-material";
import { useDataProvider } from "../dataProviders/dataProvider";
import { useAuthProvider } from "../auth/authProvider";
import { theme as muiTheme } from "../theme";
import type { PaletteMode } from "@mui/material";
import { alpha } from "@mui/material/styles";

// Create base theme styles that are shared between light and dark modes
const baseThemeStyles: Partial<RaThemeOptions> = {
  typography: muiTheme.typography,
  shape: muiTheme.shape,
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: `radial-gradient(circle at top center, ${alpha(
            "#7C3AED",
            0.15
          )} 0%, transparent 70%)`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        },
      },
    },
    // React Admin specific components
    RaLayout: {
      styleOverrides: {
        root: {
          "& .RaLayout-content": {
            padding: "20px",
          },
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          position: "relative",
          backgroundColor: alpha("#111827", 0.8),
          backdropFilter: "blur(10px)",
          border: `1px solid ${alpha("#fff", 0.05)}`,
          backgroundImage: `linear-gradient(to bottom right, ${alpha(
            "#7C3AED",
            0.05
          )}, ${alpha("#3B82F6", 0.05)})`,
          borderRadius: "12px",
          overflow: "hidden",
          "& .RaDatagrid-table": {
            borderCollapse: "separate",
            borderSpacing: 0,
          },
          "& .RaBulkActionsToolbar-toolbar": {
            minHeight: 0,
            paddingTop: 0,
            paddingBottom: 0,
          },
          "& .RaDatagrid-thead": {
            "& .RaDatagrid-headerCell": {
              backgroundColor: alpha("#111827", 0.95),
              padding: "16px",
              fontWeight: 600,
              fontSize: "0.875rem",
              letterSpacing: "0.01em",
              color: "#F8FAFC",
              borderBottom: `1px solid ${alpha("#fff", 0.1)}`,
              "&:first-of-type": {
                borderTopLeftRadius: "12px",
              },
              "&:last-child": {
                borderTopRightRadius: "12px",
              },
            },
          },
          "& .RaDatagrid-tbody": {
            "& .RaDatagrid-row": {
              transition: "all 0.2s ease-in-out",
              "&:hover": {
                backgroundColor: alpha("#111827", 0.95),
              },
              "& .RaDatagrid-rowCell": {
                padding: "16px",
                fontSize: "0.875rem",
                letterSpacing: "0.01em",
                color: "#F8FAFC",
                borderBottom: `1px solid ${alpha("#fff", 0.05)}`,
              },
              "&:last-child .RaDatagrid-rowCell": {
                borderBottom: "none",
              },
            },
          },
          // Style for checkbox column
          "& .RaDatagrid-checkbox": {
            padding: "16px",
            color: "#94A3B8",
            "&.Mui-checked": {
              color: muiTheme.palette.primary.main,
            },
          },
          // Style for selected rows
          "& .RaDatagrid-row.RaDatagrid-rowSelected": {
            backgroundColor: alpha(muiTheme.palette.primary.main, 0.08),
            "&:hover": {
              backgroundColor: alpha(muiTheme.palette.primary.main, 0.12),
            },
          },
        },
      },
    },
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          margin: "4px 8px",
          padding: "8px 12px",
          transition: "all 0.2s ease-in-out",
          color: "#94A3B8",
          "&:hover": {
            backgroundColor: alpha("#7C3AED", 0.08),
          },
          "&.RaMenuItemLink-active": {
            backgroundColor: alpha("#7C3AED", 0.15),
            backgroundImage: `linear-gradient(to right, ${alpha(
              "#7C3AED",
              0.1
            )}, ${alpha("#3B82F6", 0.1)})`,
            color: "#F8FAFC",
          },
        },
      },
    },
    RaList: {
      styleOverrides: {
        root: {
          position: "relative",
          "& .RaList-main": {
            padding: "16px",
          },
          "& .MuiPaper-root": {
            borderRadius: "16px",
            backgroundColor: alpha("#111827", 0.8),
            backdropFilter: "blur(10px)",
            border: `1px solid ${alpha("#fff", 0.05)}`,
            backgroundImage: `linear-gradient(to bottom right, ${alpha(
              "#7C3AED",
              0.05
            )}, ${alpha("#3B82F6", 0.05)})`,
          },
          // Style the bulk actions toolbar container
          "& .RaBulkActionsToolbar-toolbar": {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1200, // Above AppBar which is typically 1100
            backgroundColor: alpha("#111827", 0.95),
            backdropFilter: "blur(20px)",
            borderBottom: `1px solid ${alpha("#fff", 0.1)}`,
            padding: "12px 24px",
            display: "flex",
            alignItems: "center",
            gap: "12px",
            transform: "translateY(0)",
            transition: "transform 0.3s ease",
            "&.RaBulkActionsToolbar-collapsed": {
              transform: "translateY(-100%)",
            },
          },
          // Style for the selected count text
          "& .RaBulkActionsToolbar-title": {
            color: "#F8FAFC",
            fontSize: "0.875rem",
            fontWeight: 500,
          },
          // Style for the actions container
          "& .RaBulkActionsToolbar-actions": {
            display: "flex",
            gap: "8px",
            "& .MuiButton-root": {
              margin: 0,
              height: "36px",
              minWidth: "auto",
              padding: "0 12px",
            },
          },
        },
      },
    },
    RaFilterForm: {
      styleOverrides: {
        root: {
          padding: "16px",
          marginBottom: "16px",
          borderRadius: "16px",
          backgroundColor: alpha("#111827", 0.8),
          backdropFilter: "blur(10px)",
          border: `1px solid ${alpha("#fff", 0.05)}`,
          backgroundImage: `linear-gradient(to bottom right, ${alpha(
            "#7C3AED",
            0.05
          )}, ${alpha("#3B82F6", 0.05)})`,
        },
      },
    },
    // Material UI components used by React Admin
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "8px",
          transition: "all 0.2s ease-in-out",
          "&:hover": {
            transform: "translateY(-1px)",
          },
        },
        contained: {
          backgroundImage: `linear-gradient(to right, #7C3AED, #3B82F6)`,
          boxShadow: `0 2px 10px ${alpha("#7C3AED", 0.3)}`,
          "&:hover": {
            boxShadow: `0 4px 20px ${alpha("#7C3AED", 0.4)}`,
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: `linear-gradient(to bottom right, ${alpha(
            "#7C3AED",
            0.05
          )}, ${alpha("#3B82F6", 0.05)})`,
          backdropFilter: "blur(10px)",
          backgroundColor: alpha("#111827", 0.8),
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: alpha("#111827", 0.8),
          backdropFilter: "blur(10px)",
          border: `1px solid ${alpha("#fff", 0.05)}`,
          backgroundImage: `linear-gradient(to bottom right, ${alpha(
            "#7C3AED",
            0.05
          )}, ${alpha("#3B82F6", 0.05)})`,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: alpha("#111827", 0.9),
          backdropFilter: "blur(10px)",
          border: `1px solid ${alpha("#fff", 0.05)}`,
          backgroundImage: `linear-gradient(to bottom right, ${alpha(
            "#7C3AED",
            0.05
          )}, ${alpha("#3B82F6", 0.05)})`,
        },
      },
    },
    RaBulkActionsToolbar: {
      styleOverrides: {
        root: {
          position: "absolute",
          left: 0,
          right: 0,
          top: 0,
          zIndex: 2,
          backgroundColor: alpha("#111827", 0.8),
          backdropFilter: "blur(10px)",
          border: `1px solid ${alpha("#fff", 0.05)}`,
          backgroundImage: `linear-gradient(to bottom right, ${alpha(
            "#7C3AED",
            0.05
          )}, ${alpha("#3B82F6", 0.05)})`,
          borderRadius: "12px",
        },
      },
    },
  },
};

// Dark theme
const theme: RaThemeOptions = {
  ...defaultTheme,
  ...baseThemeStyles,
  palette: {
    mode: "dark" as PaletteMode,
    primary: muiTheme.palette.primary,
    secondary: muiTheme.palette.secondary,
    background: {
      default: "#030712",
      paper: alpha("#111827", 0.8),
    },
    text: {
      primary: "#F8FAFC",
      secondary: "#94A3B8",
    },
    error: muiTheme.palette.error,
    warning: muiTheme.palette.warning,
    info: muiTheme.palette.info,
    success: muiTheme.palette.success,
  },
};

const AdminLayout = () => {
  const dataProvider = useDataProvider();
  const authProvider = useAuthProvider();

  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      theme={theme}
      requireAuth
      disableTelemetry
      basename="/admin"
    >
      <Resource
        name="users"
        list={UserList}
        edit={UserEdit}
        create={UserCreate}
        icon={People}
      />
      <Resource
        name="organizations"
        list={OrganizationList}
        show={OrganizationShow}
        create={OrganizationCreate}
        edit={OrganizationEdit}
        icon={Business}
      />
      <Resource
        name="agent/sessions"
        list={AgentSessionList}
        show={AgentSessionShow}
        icon={Chat}
        options={{ label: "Agent Sessions" }}
      />
      <Resource
        name="agent/memory"
        list={AgentMemoryList}
        icon={Memory}
        options={{ label: "Agent Memory" }}
      />
      <Resource
        name="slack_apps"
        list={SlackAppList}
        create={SlackAppCreate}
        edit={SlackAppEdit}
        show={SlackAppShow}
        icon={Apps}
        options={{ label: "Slack Apps" }}
      />
    </Admin>
  );
};

export default AdminLayout;
