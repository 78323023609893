import { useParams, Navigate } from "react-router-dom";
import { useUser } from "../hooks/useUser";
import { useAuth0 } from "@auth0/auth0-react";
import { CircularProgress, Box } from "@mui/material";

export const TaskListRedirect = () => {
  const { orgId, taskListId } = useParams<{
    orgId: string;
    taskListId: string;
  }>();
  const {
    isAdmin,
    isMemberOfOrganization,
    isManagerOfOrganization,
    isAccountOwnerOfOrganization,
    isLoading: isUserLoading,
    userId,
  } = useUser();
  const { isAuthenticated } = useAuth0();

  if (isUserLoading || !isAuthenticated || !userId || !orgId) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  // If user is admin, redirect to manager view
  if (
    isAdmin ||
    isManagerOfOrganization(orgId) ||
    isAccountOwnerOfOrganization(orgId)
  ) {
    return (
      <Navigate
        to={`/manager/organizations/${orgId}/task-lists/${taskListId}/edit`}
        replace
      />
    );
  }

  if (isMemberOfOrganization(orgId)) {
    return (
      <Navigate
        to={`/customer/organizations/${orgId}/task-lists/${taskListId}/edit`}
        replace
      />
    );
  }

  // If no access, redirect to unauthorized page
  return <Navigate to="/unauthorized" replace />;
};
