import { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Box,
  IconButton,
  Stack,
  Typography,
  CircularProgress,
} from "@mui/material";
import { ArrowBack as ArrowBackIcon } from "@mui/icons-material";
import { TaskListEditor } from "../components/TaskListEditor";
import { useDataProvider } from "../dataProviders/dataProvider";
import dayjs from "dayjs";

// Define the same task status type as in TaskListEditor
const TASK_STATUSES = ["not-started", "in-progress", "completed"] as const;
type TaskStatus = (typeof TASK_STATUSES)[number];

interface Task {
  id: string;
  task_list_id?: string;
  name: string;
  description?: string;
  resource_link?: string;
  status: TaskStatus;
  depends_on?: string;
  due_date?: string;
  timezone?: string;
  completed_at?: string;
}

interface TaskList {
  id?: string;
  organization_id: string;
  name: string;
  launch_details?: string;
  target_launch_date?: string;
  timezone?: string;
}

/**
 * Creates a due date calculator function for a specific launch date
 * @param launch_date - ISO date string representing the target launch date
 * @returns A function that calculates due dates based on relative offsets or pinned dates
 */
const getDueDateCalculator = (launch_date?: string) => {
  return (
    relative_due_date?: string,
    pinned_due_date?: string
  ): dayjs.Dayjs | null => {
    // If a pinned due date exists, validate and use it directly
    if (pinned_due_date) {
      const pinnedDate = dayjs(pinned_due_date);
      return pinnedDate.isValid() ? pinnedDate : null;
    }

    // If no launch date or relative due date, return null
    if (!launch_date || relative_due_date === undefined) {
      return null;
    }

    // Validate launch date
    const launchDateObj = dayjs(launch_date);
    if (!launchDateObj.isValid()) {
      return null;
    }

    // Parse the relative_due_date string to an integer
    const daysOffset = parseInt(relative_due_date, 10);

    // Check if parsing was successful
    if (isNaN(daysOffset)) {
      return null;
    }

    // Use Day.js to handle the date calculation
    return launchDateObj.add(daysOffset, "day");
  };
};

export const EditTaskList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orgId, taskListId } = useParams<{
    orgId: string;
    taskListId: string;
  }>();
  const isCustomerView = location.pathname.includes("/customer/");

  const [taskList, setTaskList] = useState<TaskList | null>(null);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [isLoading, setIsLoading] = useState(taskListId !== "create");
  const dataProvider = useDataProvider();

  const loadTaskList = useCallback(async () => {
    if (!orgId || !taskListId || taskListId === "create") {
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      // Load task list
      const { data: taskListData } = await dataProvider.getOne(
        `organizations/${orgId}/task-lists/${taskListId}`,
        {
          id: taskListId,
        }
      );
      console.log("Loaded task list:", taskListData);
      setTaskList(taskListData);

      // Load tasks for this task list
      const { data: tasksData } = await dataProvider.getList(
        `organizations/${orgId}/task-lists/${taskListId}/tasks`,
        {
          pagination: { page: 1, perPage: 100 },
          sort: { field: "created_at", order: "DESC" as const },
          filter: {},
        }
      );

      // Sort tasks by due date (ascending - nulls at the end)
      const dueDateCalculator = getDueDateCalculator(
        taskListData?.target_launch_date
      );

      const sortedTasks = tasksData.sort((a, b) => {
        // Calculate due dates for both tasks
        const dueDateA = dueDateCalculator(a.relative_due_date, a.due_date);
        const dueDateB = dueDateCalculator(b.relative_due_date, b.due_date);
        // Handle cases with null due dates first
        if (!dueDateA && !dueDateB) return 0;
        if (dueDateA && !dueDateB) return -1;
        if (!dueDateA && dueDateB) return 1;
        return dueDateA!.diff(dueDateB!);
      });

      console.log("Loaded tasks (sorted):", sortedTasks);

      setTasks(sortedTasks);
    } catch (err) {
      console.error("Failed to load task list:", err);
      navigate(`/manager/organizations/${orgId}?tab=2`);
    } finally {
      setIsLoading(false);
    }
  }, [orgId, taskListId, dataProvider, navigate]);

  useEffect(() => {
    loadTaskList();
  }, [orgId, taskListId, loadTaskList]);

  const handleSave = async (
    updatedTaskList: TaskList,
    updatedTasks: Task[]
  ) => {
    if (!orgId) {
      console.error("No organization ID provided");
      return;
    }

    console.log("Saving task list:", {
      taskListId,
      orgId,
      updatedTaskList,
      updatedTasks,
    });

    try {
      // Determine if this is a create or update operation
      const isCreate = !taskList || taskListId === "create";
      let savedTaskList: TaskList;

      // Step 1: Create or update the task list
      if (isCreate) {
        console.log("Creating new task list...");
        const createResponse = await dataProvider.create(
          `organizations/${orgId}/task-lists`,
          {
            data: {
              name: updatedTaskList.name,
              launch_details: updatedTaskList.launch_details,
              organization_id: orgId,
              target_launch_date: updatedTaskList.target_launch_date,
              timezone: updatedTaskList.timezone,
            },
          }
        );
        console.log("Create response:", createResponse);
        savedTaskList = createResponse.data;
      } else {
        console.log("Updating existing task list...");
        const updateResponse = await dataProvider.update(
          `organizations/${orgId}/task-lists/${taskListId}`,
          {
            id: taskListId,
            data: {
              name: updatedTaskList.name,
              launch_details: updatedTaskList.launch_details,
              organization_id: orgId,
              target_launch_date: updatedTaskList.target_launch_date,
              timezone: updatedTaskList.timezone,
            },
            previousData: taskList || {},
          }
        );
        console.log("Update response:", updateResponse);
        savedTaskList = updateResponse.data;
      }

      // Step 2: Handle tasks - create, update, or delete as needed
      const savedTaskListId = savedTaskList.id;

      if (!savedTaskListId) {
        throw new Error("No task list ID returned from server");
      }

      // Categorize tasks as new, updated, or deleted
      const existingTaskIds = tasks.map((task) => task.id);
      const updatedTaskIds = updatedTasks.map((task) => task.id);

      // New tasks have temporary IDs (typically starting with "task-")
      const newTasks = updatedTasks.filter(
        (task) =>
          task.id.startsWith("task-") || !existingTaskIds.includes(task.id)
      );

      // Updated tasks have IDs that exist in both current and updated lists
      const modifiedTasks = updatedTasks.filter(
        (task) =>
          existingTaskIds.includes(task.id) && !task.id.startsWith("task-")
      );

      // Deleted tasks have IDs that exist in current list but not in updated list
      const deletedTaskIds = existingTaskIds.filter(
        (id) => !updatedTaskIds.includes(id) && !id.startsWith("task-")
      );

      // Create new tasks
      await Promise.all(
        newTasks.map((task) => {
          const taskData = {
            ...task,
            task_list_id: savedTaskListId,
          };
          // Create a new object without the id property instead of using delete
          const { id, ...taskDataWithoutId } = taskData;

          return dataProvider.create(
            `organizations/${orgId}/task-lists/${savedTaskListId}/tasks`,
            {
              data: taskDataWithoutId,
            }
          );
        })
      );

      // Update existing tasks
      await Promise.all(
        modifiedTasks.map((task) =>
          dataProvider.update(
            `organizations/${orgId}/task-lists/${savedTaskListId}/tasks/${task.id}`,
            {
              id: task.id,
              data: task,
              previousData: tasks.find((t) => t.id === task.id) || {},
            }
          )
        )
      );

      // Delete removed tasks
      await Promise.all(
        deletedTaskIds.map((id) =>
          dataProvider.delete(
            `organizations/${orgId}/task-lists/${savedTaskListId}/tasks/${id}`,
            {
              id,
            }
          )
        )
      );

      if (isCustomerView) {
        navigate(0); // Refresh the page
      } else {
        navigate(`/manager/organizations/${orgId}?tab=2`);
      }
    } catch (err) {
      console.error("Failed to save task list:", err);
      // Don't navigate away on error
      return;
    }
  };

  const handleDelete = async () => {
    if (!orgId || !taskListId || taskListId === "create") return;
    try {
      await dataProvider.delete(
        `organizations/${orgId}/task-lists/${taskListId}`,
        {
          id: taskListId,
        }
      );
      navigate(`/manager/organizations/${orgId}?tab=2`);
    } catch (err) {
      console.error("Failed to delete task list:", err);
    }
  };

  return (
    <Box>
      <Stack
        direction="row"
        spacing={2}
        alignItems="center"
        mb={4}
        sx={{
          transition: "opacity 0.2s ease-in-out",
          "&:hover button": { opacity: 1 },
        }}
      >
        {!isCustomerView && (
          <IconButton
            onClick={() => navigate(`/manager/organizations/${orgId}?tab=2`)}
            color="inherit"
            sx={{
              opacity: 0.7,
              transition: "opacity 0.2s ease-in-out",
            }}
          >
            <ArrowBackIcon />
          </IconButton>
        )}
        <Typography variant="h4" fontWeight="bold">
          {isCustomerView
            ? "Task List"
            : taskListId === "create"
              ? "Create Task List"
              : "Edit Task List"}
        </Typography>
      </Stack>

      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <TaskListEditor
          initialTaskList={
            taskListId === "create"
              ? {
                  organization_id: orgId || "",
                  name: "",
                  launch_details: "",
                }
              : taskList || {
                  organization_id: orgId || "",
                  name: "",
                  launch_details: "",
                }
          }
          initialTasks={tasks}
          onSave={handleSave}
          onDelete={handleDelete}
          isCustomerView={isCustomerView}
        />
      )}
    </Box>
  );
};
