import { List, Datagrid, TextField, EmailField, DateField } from "react-admin";

interface ResourceProps {
  resource?: string;
}

export const UserList = ({ resource = "users" }: ResourceProps) => (
  <List resource={resource}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <EmailField source="email" />
      <TextField source="auth0_user_id" />
      <TextField source="slack_user_id" />
      <TextField source="ms_teams_user_id" />
      <DateField source="created_at" showTime />
      <DateField source="updated_at" showTime />
    </Datagrid>
  </List>
);
