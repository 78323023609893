import { Navigate, useLocation, useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useUser } from "../hooks/useUser";
import { useEffect, useState } from "react";

interface CustomerProtectedRouteProps {
  children: React.ReactNode;
}

export const CustomerProtectedRoute = ({
  children,
}: CustomerProtectedRouteProps) => {
  const { isAuthenticated, isLoading } = useAuth0();
  const {
    isLoading: isUserLoading,
    isAdmin,
    isMemberOfOrganization,
  } = useUser();
  const location = useLocation();
  const params = useParams();
  const [isAuthorized, setIsAuthorized] = useState<boolean | null>(null);

  useEffect(() => {
    // If user is admin, authorize regardless of organizations
    if (!isUserLoading && !isAdmin) {
      setIsAuthorized(true);
      return;
    }

    if (!isUserLoading && params.orgId) {
      setIsAuthorized(isMemberOfOrganization(params.orgId));
      return;
    }
  }, [params.orgId, isAdmin, isMemberOfOrganization, isUserLoading]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Allow admins to proceed even with no organizations
  if (isAdmin && params.orgId) {
    return <>{children}</>;
  }

  // Handle organization-specific authorization for non-admins
  if (isUserLoading) {
    // Still loading organizations data
    return <div>Loading organization data...</div>;
  }

  // Organization check failed
  if (isAuthorized === false) {
    return <Navigate to="/customer" replace />;
  }

  return <>{children}</>;
};
