import { useContext, useEffect, useState, useMemo } from "react";
import { UserContext } from "../contexts/UserContext";
import { useAuth0 } from "@auth0/auth0-react";

interface UserAssociation {
  organization_id: string | null;
  organization_name: string | null;
  role_name: string;
}

interface SyncResponse {
  id: string;
  associations: UserAssociation[];
}

export const useUser = () => {
  const context = useContext(UserContext);
  const {
    isAuthenticated,
    getAccessTokenSilently,
    isLoading: isAuth0Loading,
    logout,
  } = useAuth0();
  const [isSyncing, setIsSyncing] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const isLoading = useMemo(
    () => isAuth0Loading || isSyncing || !isInitialized,
    [isAuth0Loading, isSyncing, isInitialized]
  );

  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }

  const {
    userId,
    associations,
    isAdmin,
    setUserId,
    setAssociations,
    setIsAdmin,
  } = context;

  useEffect(() => {
    if (!isAuthenticated) {
      // If not authenticated, we can mark as initialized
      setIsInitialized(true);
    } else if (!userId || associations.length === 0 || isAdmin === undefined) {
      // Only fetch if authenticated and we're missing user data
      const syncUserData = async () => {
        setIsSyncing(true);
        setError(null);

        try {
          const accessToken = await getAccessTokenSilently();
          const response = await fetch(
            `${import.meta.env.VITE_API_URL}/api/auth/sync`,
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
            }
          );

          if (!response.ok) {
            if (response.status === 409) {
              // user registration conflict.
              // i.e. registered with Google, but tried to login with Slack account
              // we need to logout and redirect to login page so user can try again with appropriate account
              const data = await response.json();
              const encodedDetail = encodeURIComponent(data.detail);
              logout({
                logoutParams: {
                  returnTo: `${window.location.origin}/login?alert=${encodedDetail}`,
                },
              });
              return;
            } else {
              throw new Error("Failed to sync user data");
            }
          }
          const syncData: SyncResponse = await response.json();
          setUserId(syncData.id);

          const syncAssociations = syncData.associations || [];

          const associations = syncAssociations
            .filter((assoc) => assoc.organization_id)
            .map((assoc) => ({
              organization_id: assoc.organization_id!,
              organization_name: assoc.organization_name!,
              role: assoc.role_name,
            }));

          setAssociations(associations);

          const userIsAdmin = syncAssociations.some(
            (assoc) => assoc.role_name === "admin" && !assoc.organization_id
          );

          setIsAdmin(userIsAdmin);
        } catch (error) {
          console.error("Failed to reload user data:", error);
          setError(
            error instanceof Error ? error.message : "Failed to sync user data"
          );
        } finally {
          setIsSyncing(false);
          setIsInitialized(true);
        }
      };

      syncUserData();
    } else {
      // If authenticated, and we have user data, we can mark as initialized
      setIsInitialized(true);
    }
  }, [
    isAuthenticated,
    userId,
    associations.length,
    isAdmin,
    getAccessTokenSilently,
    setUserId,
    setAssociations,
    setIsAdmin,
    logout,
  ]);

  const hasRole = (organizationId: string | null, role: string) => {
    return associations.some(
      (assoc) => assoc.organization_id === organizationId && assoc.role === role
    );
  };

  const isMemberOfOrganization = (organizationId: string) => {
    return hasRole(organizationId, "member");
  };

  const isManagerOfOrganization = (organizationId: string) => {
    return hasRole(organizationId, "manager");
  };

  const isAccountOwnerOfOrganization = (organizationId: string) => {
    return hasRole(organizationId, "account_owner");
  };

  return {
    userId,
    associations,
    isAdmin,
    isSyncing,
    isLoading,
    error,
    isMemberOfOrganization,
    isAccountOwnerOfOrganization,
    isManagerOfOrganization,
  };
};
