import { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  Button,
  Stack,
  Typography,
  Box,
  Grid,
} from "@mui/material";
import {
  Description as PdfIcon,
  Language as WebsiteIcon,
  Add as AddIcon,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { useAuth0 } from "@auth0/auth0-react";
import { ComingSoonWrapper } from "./shared";
import { getGlassStyles, getButtonStyles } from "../theme/shared";

interface AddKnowledgeSourceProps {
  orgId: string;
  onSourceAdded?: () => void;
}

interface ConnectorCard {
  type: string;
  icon: React.ElementType;
  label: string;
  disabled: boolean;
  comingSoon?: boolean;
}

export const AddKnowledgeSource = ({
  orgId,
  onSourceAdded,
}: AddKnowledgeSourceProps) => {
  const { getAccessTokenSilently } = useAuth0();
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const [newSource, setNewSource] = useState({ source: "", type: "website" });

  const handleAddSource = async () => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${
          import.meta.env.VITE_API_URL
        }/api/organizations/${orgId}/knowledge_sources`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            organization_id: orgId,
            source: newSource.source,
            type: newSource.type,
          }),
        }
      );
      if (!response.ok) throw new Error("Failed to add knowledge source");
      setIsOpen(false);
      setNewSource({ source: "", type: "website" });
      if (onSourceAdded) {
        onSourceAdded();
      }
    } catch (error) {
      console.error("Error adding knowledge source:", error);
    }
  };

  const connectorCards: ConnectorCard[] = [
    {
      type: "website",
      icon: WebsiteIcon,
      label: "Website",
      disabled: false,
    },
    {
      type: "pdf",
      icon: PdfIcon,
      label: "PDF",
      disabled: false,
    },
  ];

  const renderConnectorCard = (connector: ConnectorCard) => {
    const cardContent = (
      <Box
        onClick={() => {
          if (!connector.disabled) {
            setNewSource({
              ...newSource,
              type: connector.type,
            });
          }
        }}
        sx={{
          p: 2,
          ...getGlassStyles(theme, {
            opacity: 0.7,
            borderRadius: 1,
            hoverEffect: !connector.disabled,
          }),
          border: 1,
          borderColor:
            newSource.type === connector.type ? "primary.main" : "divider",
          cursor: connector.disabled ? "default" : "pointer",
          position: "relative",
          opacity: connector.disabled ? 0.6 : 1,
        }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <connector.icon color={connector.disabled ? "disabled" : "primary"} />
          <Box>
            <Typography variant="body1">{connector.label}</Typography>
          </Box>
        </Stack>
      </Box>
    );

    return connector.comingSoon ? (
      <ComingSoonWrapper isComingSoon={true}>{cardContent}</ComingSoonWrapper>
    ) : (
      cardContent
    );
  };

  return (
    <>
      <Button
        variant="contained"
        onClick={() => {
          setIsOpen(true);
        }}
        startIcon={<AddIcon />}
        sx={getButtonStyles(theme)}
      >
        Add Knowledge Source
      </Button>

      <Dialog
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: getGlassStyles(theme, { opacity: 0.9, borderRadius: 2 }),
        }}
      >
        <DialogTitle>Add Knowledge Source</DialogTitle>
        <DialogContent>
          <Stack spacing={4} sx={{ mt: 2 }}>
            {/* Source Type Selection */}
            <Box>
              <Typography variant="subtitle2" sx={{ mb: 2 }}>
                Select Source Type
              </Typography>
              <Grid container spacing={2}>
                {connectorCards.map((connector) => (
                  <Grid item xs={6} key={connector.type}>
                    {renderConnectorCard(connector)}
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* Source URL Input */}
            {!newSource.type.includes("_") && (
              <TextField
                label={newSource.type === "website" ? "Website URL" : "PDF URL"}
                value={newSource.source}
                onChange={(e) => {
                  setNewSource({ ...newSource, source: e.target.value });
                }}
                fullWidth
                required
                placeholder={
                  newSource.type === "website"
                    ? "https://example.com/docs"
                    : "https://example.com/document.pdf"
                }
                sx={{
                  "& .MuiOutlinedInput-root": {
                    backgroundColor: theme.palette.background.paper,
                  },
                }}
              />
            )}

            {/* Action Buttons */}
            <Stack direction="row" spacing={2} justifyContent="flex-end">
              <Button
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleAddSource}
                disabled={!newSource.source && !newSource.type.includes("_")}
                sx={getButtonStyles(theme)}
              >
                Add Source
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
};
